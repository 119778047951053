import React, {useEffect, useState} from 'react';
import { InputAdornment, TextField } from "@material-ui/core";
import LicencePlateMask from "./LicencePlateMask";

export default function LicencePlateInput(props) {
    const [createdLicencePlate, setCreatedLicencePlate] = useState('');

    useEffect(()=>{
        if (props.value !== createdLicencePlate) props.onChange(createdLicencePlate)
    }, [createdLicencePlate]);

    useEffect(()=>{
        if (props.value){
            if (getLicencePlateCountry(props.value) !== undefined){
                setCreatedLicencePlate(props.value)
            } else {
                setCreatedLicencePlate('')
            }
        }
    }, [props.value])

    function getLicencePlateCountry(licencePlate){
        if (licencePlate.match(/(^[ABEKMHOPCTYX]{1}[0-9]{3}[[ABEKMHOPCTYX]{2}[0-9]{2,3}$)/i)) return 'Rus';
        if (licencePlate.match(/(^[ABEKMHOPCTYX]{1}[0-9]{3}[[ABEKMHOPCTYX]{2}DPR)/i)) return 'DPR';
        if (licencePlate.match(/(^[ABEKMHOPCTYX]{1}[0-9]{3}[[ABEKMHOPCTYX]{2}LPR)/i)) return 'LPR';
        if (licencePlate.match(/(^[0-9]{4}[ABEKMHOPCTYX]{2}[0-9]{2,3}$)/i)) return 'Rus';
        if (licencePlate.match(/(^[ABEKMHOPCTYX]{2}[0-9]{4}[0-9]{2,3}$)/i)) return 'Rus';
        if (licencePlate.match(/(^[A-Z]{2}[-][0-9]{1,4})/i)) return 'Lv';
        if (licencePlate.match(/(^[A-Z]{2} [0-9]{4,5})/i)) return 'Nor';
        if (licencePlate.match(/(^[0-9]{3}[ ][A-Z]{3})/i)) return 'Est';
        if (licencePlate.match(/(^[A-Z]{2,3}[-][0-9]{2,3})/i)) return 'Fin';
        if (licencePlate.match(/(^[A-HJ-NPR-TV-Z]{2}[0-9]{3}[A-HJ-NPR-TV-Z]{2})/i)) return 'Italy';
        if (licencePlate.match(/(^[A-Z]{2}[0-9]{4}[A-Z]{2})/i)) return 'Ua';
        if (licencePlate.match(/(^[0-9]{4}[ABEIKMNOPCTX]{2}[-][0-9])/i)) return 'By';
        if (licencePlate.match(/(^[ABEIKMNOPCTX]{2}[0-9]{4}[-][0-9])/i)) return 'By';
        return undefined;
    }

    return (
        <React.Fragment>
            <TextField
                autoFocus={props.autofocus}
                fullWidth
                label="Номерной знак"
                variant="outlined"
                InputProps={{
                    inputComponent: LicencePlateMask,
                    value: createdLicencePlate,
                    onChange: (e, m)=>{
                        console.log(m);
                        setCreatedLicencePlate(e);
                    },
                    endAdornment: createdLicencePlate ? <InputAdornment position="start">{getLicencePlateCountry(createdLicencePlate)}</InputAdornment> : null,
                    disabled: props.disabled,
                    autoFocus: props.autofocus
                }}
            />
        </React.Fragment>
    )
}
