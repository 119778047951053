import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Menu from '../../Header/Menu';

export default class PortalSettings extends Component {
 constructor() {
   super();
   this.state = {
     list: [],
     area: [],
     showEditDialog: false,
     showEditDialogModeEdit: false,
     editId: 0,
     editFrom: 0,
     editTo: 0,
     editError: ''
   }
   this.handleLoad = this.handleLoad.bind(this);
   this.handleLoad = this.handleLoad.bind(this);
   this.loadArea = this.loadArea.bind(this);
 }

 handleSubmitArea(action) {
   console.log(action);
   let reqMethod;
   let reqBody;
   switch(action) {
    case 'delete':
      reqMethod='DELETE';
      reqBody=JSON.stringify({"id" :this.state.editId});
      break;
    case 'create':
      reqMethod='POST';
      reqBody=JSON.stringify({"from_area" :this.state.editFrom, "to_area" :this.state.editTo});
      break;
    case 'update':
      reqMethod='PUT';
      reqBody=JSON.stringify({"id" :this.state.editId, "from_area" :this.state.editFrom, "to_area" :this.state.editTo});
      break;
  }
   fetch(process.env.REACT_APP_API_URL+'/parking/portal', {
     method: reqMethod,
     credentials: 'include',
     body: reqBody,
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => {
     console.log(res);
     switch (res.status) {
        case 401:

          break;
        case 403:
          this.setState({editError: "Запрещено!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
        case 409:
          switch (res.json().code) {
            case 1:
              this.setState({editError: "Направление не может указывать само на себя!"},()=>{
                window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
                });
              break;
            case 2:
              this.setState({editError: "Такое уже есть!"},()=>{
                window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
                });
              break;
          }


          break;
       default:
       this.setState({showEditDialog: false});
       this.handleLoad();
     }
   })
   .catch(err => {
     console.log(err);
   });
 }

 handleLoad(){
   fetch(process.env.REACT_APP_API_URL+'/parking/portal',{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => this.setState({list: res.portal}));
 }

loadArea(){
  fetch(process.env.REACT_APP_API_URL+'/parking/area',{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .then(res => this.setState({area: res.area}));
}

 componentDidMount() {
   this.handleLoad()
 }

 render() {
   return (
    <>
      <Container>
        <h3>Порталы</h3>
        <ButtonToolbar className="mb-3">
          <Button variant="outline-primary" onClick={ (e) => {
            this.loadArea();
            this.setState({
              showEditDialog: true,
              showEditDialogModeEdit: false,
              editId: 0,
              editFrom: 0,
              editTo: 0
            });
            e.target.blur();
          }}>Новый портал</Button>
        </ButtonToolbar>
        <div>
          <ListGroup className="mb-3">
          {
          this.state.list.map((data, i) => (
            <ListGroup.Item
            key={i}
            action onClick={ (e) => {
              this.loadArea();

              this.setState({
                showEditDialog: true,
                showEditDialogModeEdit: true,
                editId: data.id,
                editFrom: data.from_area,
                editTo: data.to_area
              });
              e.target.blur();
            }}>
            <>
            <Badge variant={data.from_area_g && data.from_area != 0 ? "warning" : (data.from_area != 0 ? "success" : "danger")}>{(data.from_areaT ? data.from_areaT: "Улица")}</Badge>
              {"  =>  "}
              <Badge variant={data.to_area_g && data.from_area != 0 ? "warning" : (data.to_area != 0 ? "success" : "danger")}>{(data.to_areaT ? data.to_areaT: "Улица")}</Badge>

              </>
            </ListGroup.Item>
          ))
          }
          </ListGroup>

          <Modal show={this.state.showEditDialog} onHide={ () => this.setState({showEditDialog : false, showEditDialogModeEdit: false}) } size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{!this.state.showEditDialogModeEdit ? <>Новая территория</> : <>Редактировать территорию</> }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="areaFrom">
                 <Form.Label>Из:</Form.Label>
                  <Form.Control as="select" onChange={(e) => { this.setState({editFrom : e.target.value}) }} value={this.state.editFrom}>
                    <option key={0} name={"Улица"} value={0}>Улица</option>
                     {
                       this.state.area.map((list, j) => (
                         <option key={j} name={list.name} value={list.id}>{list.name}</option>
                       ))
                     }
                 </Form.Control>
                </Form.Group>
                <Form.Group controlId="areaTo">
                 <Form.Label>В:</Form.Label>
                  <Form.Control as="select" onChange={(e) => { this.setState({editTo : e.target.value}) }} value={this.state.editTo}>
                    <option key={0} name={"Улица"} value={0}>Улица</option>
                     {
                       this.state.area.map((list, j) => (
                         <option key={j} name={list.name} value={list.id}>{list.name}</option>
                       ))
                     }
                 </Form.Control>
                </Form.Group>
              </Form>
              {this.state.editError ?
                <Alert variant="danger">
                  {this.state.editError}
                </Alert>
              :null }
            </Modal.Body>
            <Modal.Footer>
              
              <Button variant="primary" onClick={this.handleSubmitArea.bind(this, this.state.showEditDialogModeEdit ? 'update' : 'create')}>
                {!this.state.showEditDialogModeEdit ? <>Добавить</> : <>Сохранить</> }
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
   );
 }
}
