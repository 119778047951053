import React, {Component, useEffect, useState} from 'react';
import {Card, CardContent, CssBaseline, makeStyles, Paper} from "@material-ui/core";
import Menu from "../Header/Menu";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {getApartmentStatistics, getLicencePlateStatistics} from "../httpUtils";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    card:{
        width: '100%',
        flex: 1
    },
    licencePlateAllowToAll: {
        color: '#4CAF50',
    },
    licencePlatePartialDisallow: {
        color: '#FFC107',
    },
    licencePlateFullDisallow: {
        color: '#F44336',
    },
    cardTitle: {
        textAlign: 'center'
    },
    vi: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
}));

export default function Overview(){
    // const {store} = useContext(ReactReduxContext)
    const classes = useStyles();
    const [licencePlates, setLicencePlates] = useState({});
    const [apartments, setApartments] = useState({});

    function initPage() {
        getLicencePlateStatistics().then((r)=>{
            setLicencePlates(r)
        }).catch((e)=>{
            console.error(e)
        })
        getApartmentStatistics().then((r)=>{
            setApartments(r)
        }).catch((e)=>{
            console.error(e)
        })
    }

    useEffect(()=>{
        initPage();
    }, []);

    return (
        <React.Fragment>
            <Menu title='Обзор' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>

                        <Card className={classes.card} variant="outlined">
                            <CardContent>
                                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                    Номерные знаки
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid container item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        <div className={classes.vi}>
                                            <Typography gutterBottom>
                                                <CheckCircleOutlineIcon className={classes.licencePlateAllowToAll}/> {licencePlates.allowToAll}
                                            </Typography>
                                            <Typography gutterBottom>
                                                <ErrorOutlineIcon className={classes.licencePlatePartialDisallow}/> {licencePlates.partialDisallow}
                                            </Typography>
                                            <Typography gutterBottom>
                                                <RemoveCircleOutlineIcon className={classes.licencePlateFullDisallow}/> {licencePlates.fullDisallow}
                                            </Typography>

                                        </div>
                                    </Grid>
                                    <Grid container item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.flexCenter}>
                                        <Typography variant="h3">
                                            {licencePlates.total}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid container item xs={6} sm={4} md={2} lg={2} xl={1}>
                        <Card className={classes.card} variant="outlined">
                            <CardContent>
                                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                    Квартиры
                                </Typography>
                                <Typography variant="h3" className={classes.flexCenter}>
                                    {apartments.apartmentTotal}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}
