import React, {useContext, useEffect, useState} from 'react';
import {
    makeStyles,
} from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
    bindApartmentLicencePlates,
    findLinkedApartmentLicencePlates,
    loadLicencePlates,
    unbindApartmentLicencePlates
} from "../httpUtils";
import {checkSettings, getSortParam} from "../utils";
import LicencePlateCreateDialog from "../LicencePlates/LicencePlateCreateDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InfoIcon from "@material-ui/icons/Info";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LicencePlateInfo from "../LicencePlates/LicencePlateInfo";
import {ReactReduxContext} from "react-redux";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2),
        display: "flex"
    },
    licencePlatesList: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

export default function ApartmentLicencePlates(props) {
    const classes = useStyles();
    const filter = createFilterOptions();

    const [apartmentId, setApartmentId] = useState(-1);

    const [currentAvailableLicencePlatesList, setCurrentAvailableLicencePlatesList] = useState([]);
    const [currentApartmentLicencePlatesList, setCurrentApartmentLicencePlatesList] = useState([]);

    const [selectedLicencePlate, setSelectedLicencePlate] = useState('');
    const [autocompleteLicencePlate, setAutocompleteLicencePlate] = useState('');
    const [isFetching, setFetching] = useState(false);

    const [createdLicencePlate, setCreatedLicencePlate] = useState('');
    const [isOpenCreatedDialog, setOpenCreatedDialog] = useState(false);

    const [infoLicencePlate, setInfoLicencePlate] = useState('');
    const [isUpdateLicencePlatesAvailable, setUpdateLicencePlatesAvailable] = useState(false);

    useEffect(()=>{
        setUpdateLicencePlatesAvailable(props.isUpdateLicencePlatesAvailable);
    }, [props.isUpdateLicencePlatesAvailable])

    useEffect(()=>{
        setApartmentId(props.apartmentId);
        if (props.apartmentId){
            loadLinkedLicencePlates();
        }
    }, [props.apartmentId])

    useEffect(()=>{
        if (autocompleteLicencePlate) {
            setFetching(true);
            loadLicencePlates(`${getSortParam({limit: 100, licencePlate: autocompleteLicencePlate})}`, true).then((r)=>{
                console.log(r)
                setFetching(false);
                setCurrentAvailableLicencePlatesList(r);
            })
        }
    }, [autocompleteLicencePlate])

    function loadLinkedLicencePlates(){
        findLinkedApartmentLicencePlates(props.apartmentId).then((r)=>{
            setCurrentApartmentLicencePlatesList(r)
        })
            .catch((e)=>{

            })
    }

    function unbindLinkedApartmentLicencePlate(licencePlate){
        unbindApartmentLicencePlates(apartmentId, licencePlate).then((r)=>{
            loadLinkedLicencePlates()
        })
        .catch((e)=>{

        })
    }

    function bindLinkedApartmentLicencePlate(licencePlate){
        bindApartmentLicencePlates(apartmentId, licencePlate).then((r)=>{
            loadLinkedLicencePlates()
        })
            .catch((e)=>{

            })
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <Autocomplete
                    value={selectedLicencePlate}
                    options={currentAvailableLicencePlatesList}
                    loading={isFetching}
                    onChange={(event, newValue) => {
                        if (newValue && newValue.hasOwnProperty('licencePlate') && newValue && newValue.hasOwnProperty('inputValue')) {
                            setCreatedLicencePlate(newValue.inputValue);
                            setOpenCreatedDialog(true);
                            return;
                        }
                        if (newValue && newValue.hasOwnProperty('licencePlate')) {
                            setSelectedLicencePlate(newValue.licencePlate);
                        } else {
                            setSelectedLicencePlate('');
                        }
                    }}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.licencePlate;
                    }}
                    renderOption={(option) => option.licencePlate}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                licencePlate: `Добавить "${params.inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    fullWidth
                    margin="normal"
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Выберите номерной знак" variant="outlined" onChange={(e)=>{setAutocompleteLicencePlate(e.target.value.toUpperCase())}}/>
                    )}
                />
                <Button variant="contained" color="primary" style={{marginLeft: 8}} onClick={()=>{bindLinkedApartmentLicencePlate(selectedLicencePlate)}}>
                    Привязать
                </Button>
            </div>
            <div className={classes.licencePlatesList}>
                <List>
                    {
                        currentApartmentLicencePlatesList.map((data, i) => (
                            <ListItem key={i}>
                                <Typography variant="button" display="block" gutterBottom color={"initial"} >
                                    {data.number}
                                </Typography>
                                <ListItemSecondaryAction>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={<InfoIcon />}
                                        style={{marginRight: 8}}
                                        onClick={ (e) => {
                                            setInfoLicencePlate(data.number);
                                        }}
                                    >
                                        Info
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        endIcon={<HighlightOffIcon />}
                                        onClick={()=>{unbindLinkedApartmentLicencePlate(data.number)}}
                                    >
                                        Отвязать
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>
            </div>
            <LicencePlateCreateDialog isNeedLicencePlateCreate={isOpenCreatedDialog} oneCreateOnly createdLicencePlate={(lp)=>{
                setSelectedLicencePlate(lp);
                console.log(lp)}
            } initLicencePlate={createdLicencePlate} isNeedClose={()=>{setOpenCreatedDialog(false); setCreatedLicencePlate('');}}/>
            <LicencePlateInfo isUpdateLicencePlateAvailable={isUpdateLicencePlatesAvailable} key={infoLicencePlate} open={!!infoLicencePlate} number={infoLicencePlate} onClose={()=>{ setInfoLicencePlate('') }} />
        </React.Fragment>
    )
}