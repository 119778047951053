import React, {useContext, useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Accordion,
    AccordionActions,
    AccordionDetails, AppBar, Avatar, Card, CardActionArea, CardHeader, createTheme,
    CssBaseline, FormControl, InputLabel, Link,
    makeStyles, MenuItem, Select,
    TextField, Tooltip
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {
    addApartmentNumber,
    createUnlived,
    loadApartment,
    loadAreasList,
    loadUnlived,
    loadUnlivedPrefixes
} from "../httpUtils";
import {appendFilter, checkSettings, getSortParam, getTypeEvents} from "../utils";
import {Alert} from "@material-ui/lab";
import FullScreenDialog from "../component/FullScreenDialog";

import PopupDialog from "../component/PopupDialog";
import ScrollToTop from "../component/ScrollToTop";

import {cyrillicToLatinAutoConvert} from "../component/LicencePlate/LicencePlateMask";
import {ReactReduxContext} from "react-redux";
import UnlivedLicencePlates from "./UnlivedLicencePlates";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import UnlivedInfo from "./UnlivedInfo";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: 45,
    },
    alert: {
        marginTop: theme.spacing(2),
    }
}));

export default function Unlived(props) {
    const {store} = useContext(ReactReduxContext)
    const classes = useStyles();
    const [filterParam, setFilterParam] = useState({objectNumber: '', linkedLicencePlate: ''});
    const [loadParam, setLoadParam] = useState();

    const [isFormChanged, setIsFormChanged] = useState(0);
    const [unlivedList, setUnlivedList] = useState([]);

    const [unlivedPrefixesList, setUnlivedPrefixesList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [managedUnlived, setManagedUnlived] = useState({});
    const [isNeedObjectManage, setNeedObjectManage] = useState(false);

    const [isFetching, setIsFetching] = useState(false);

    const [createObjectType, setCreateObjectType] = useState(undefined);
    const [createObjectArea, setCreateObjectArea] = useState(undefined);
    const [createObjectNumber, setCreateObjectNumber] = useState(undefined);
    const [createObjectChar, setCreateObjectChar] = useState(undefined);
    const [createObjectId, setCreateObjectId] = useState(0);
    const [createObjectOneTimeLimitation, setCreateObjectOneTimeLimitation] = useState(undefined);
    const [isObjectCreating, setObjectCreating] = useState(false);
    const [isNeedObjectCreate, setNeedObjectCreate] = useState(false);
    const [isObjectDataInvalid, setObjectDataInvalid] = useState(false);
    const [createdUnlivedAlertType, setCreatedUnlivedAlertType] = useState(-1);
    const [createdUnlivedError, setCreatedUnlivedError] = useState('');

    const [isUpdateLicencePlatesAvailable, setUpdateLicencePlatesAvailable] = useState(false);

    store.subscribe(() => {
        if (checkSettings(store.getState().settings, 16)){
            setUpdateLicencePlatesAvailable(true)
        }
    })

    function resetFilters(){
        setUnlivedList([]);
        setFilterParam(Object.fromEntries(Object.entries(filterParam).map((v)=> [v[0], undefined])))
        setIsFormChanged(Math.random());
    }

    function handleScroll() {
        return;
        if (isNeedObjectManage || isNeedObjectCreate) return;
        if (window.innerHeight + document.documentElement.scrollTop <= (document.scrollingElement.scrollHeight-1)) return;
        setIsFetching(true);
    }

    function createObject() {
        setObjectCreating(true);
        createUnlived(createObjectType, createObjectArea, createObjectNumber, createObjectChar, createObjectOneTimeLimitation).then((r)=>{
            setCreatedUnlivedAlertType(-1);
            setCreateObjectId(r.unlivedId);
            setObjectCreating(false);
            loadObjectList();
        }).catch((e)=>{
            if (e.code === 409){
                setCreatedUnlivedAlertType(1);
                setCreateObjectId(e.unlivedId);
            } else {
                setCreatedUnlivedAlertType(2);
                setCreatedUnlivedError(e.code || e.message);
            }
            setObjectCreating(false);
        })
    }

    useEffect(()=>{
        if (loadParam){
            loadUnlivedPrefixes().then((r)=>{
                setUnlivedPrefixesList(r)
            })
            loadAreasList().then((r)=>{
                setAreaList(r)
            })
            loadUnlived(`${getSortParam(loadParam)}`, true).then((r)=>{
                if (isFetching){
                    setIsFetching(false);
                    setUnlivedList( [...unlivedList, ...r]);
                } else {
                    setUnlivedList(r);
                }
            })
        }
    }, [loadParam])

    function loadObjectList(){
        setUnlivedPrefixesList([]);
        setUnlivedList([]);
        setLoadParam({...appendFilter(filterParam), limit: 200});
    }

    useEffect(()=>{
        loadObjectList();
    }, [filterParam]);

    useEffect(()=>{
        if (isFetching){
            if (unlivedList.length > 0){
                setLoadParam({...loadParam, offsetUnlivedId: unlivedList[unlivedList.length-1].id})
            } else {
                setLoadParam({...loadParam})
            }
        }
    }, [isFetching])

    function initPage() {
        resetFilters();
        window.addEventListener('scroll', handleScroll, false);
        document.addEventListener('touchmove', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
            document.removeEventListener('touchmove', handleScroll, true);
        }
    }

    useEffect(()=>{
        initPage();
    }, []);

    useEffect(()=>{
        setCreateObjectId(0);
        setCreatedUnlivedAlertType(-1);
        setCreatedUnlivedError('');
        if (createObjectType && createObjectNumber){
            setObjectDataInvalid(false);
        } else {
            setObjectDataInvalid(true);
        }
    }, [createObjectType, createObjectNumber, createObjectChar])

    function closeNewObjectDialog(){
        setNeedObjectCreate(false);
        setCreateObjectType(undefined);
        setCreateObjectArea(undefined);
        setCreateObjectId(0);
        setObjectDataInvalid(false);
        setCreateObjectNumber(undefined);
        setCreateObjectChar(undefined);
        setCreatedUnlivedAlertType(-1);
        setCreatedUnlivedError('');
        setCreateObjectOneTimeLimitation(undefined);
    }

    return (
        <React.Fragment>
            <Menu title='Нежилые объекты' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Фильтр</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} key={`filter-${isFormChanged}`}>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, objectNumber: e.target.value})}
                                    value={filterParam.objectNumber}
                                    fullWidth
                                    label="Номер объекта" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, linkedLicencePlate: cyrillicToLatinAutoConvert(e.target.value) })}
                                    value={filterParam.linkedLicencePlate}
                                    fullWidth
                                    label="Связанный номерной знак" variant="outlined" />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button size="small" color="primary" onClick={resetFilters}>
                            Сбросить
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Button variant="contained" color="primary" className={classes.accordion} onClick={()=>{
                    setNeedObjectCreate(true);
                }}>
                    Создать нежилой объект
                </Button>
                <Grid container spacing={1}>
                    { unlivedList.map((data, i) => (
                        <Grid container item xs={6} sm={3} md={2} lg={2} xl={1}>
                            {/*<Tooltip title="Нажми на меня и я покажу окно редактирования" interactive>*/}
                                <Card className={classes.card}>
                                    <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                        setManagedUnlived(data);
                                        setNeedObjectManage(true);
                                    }}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar variant="rounded" className={classes.avatar}>
                                                        {data.prefix}
                                                    </Avatar>
                                                }
                                                subheader={
                                                    <Typography variant="h5" component="h2">{data.number}{data.char ? `/${data.char}` : ``}</Typography>
                                                }
                                            />
                                    </CardActionArea>
                                </Card>
                            {/*</Tooltip>*/}
                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            <ScrollToTop/>
            <FullScreenDialog open={isNeedObjectManage} onClose={()=>{
                setManagedUnlived({});
                setNeedObjectManage(false);
            }} title={`${managedUnlived.number}${managedUnlived.char ? `/${managedUnlived.char}` : ``} - ${managedUnlived.prefix}`}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <UnlivedInfo unlivedId={managedUnlived.id} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <UnlivedLicencePlates unlivedId={managedUnlived.id} isUpdateLicencePlatesAvailable={isUpdateLicencePlatesAvailable}/>
                    </Grid>
                </Grid>
            </FullScreenDialog>

            <PopupDialog open={isNeedObjectCreate} onClose={closeNewObjectDialog} title={"Создание нежилого объекта"} actionBoard={
                <React.Fragment>
                    {
                        createObjectId === 0 ?
                            <>
                                <Button onClick={closeNewObjectDialog} color="default">
                                    Отмена
                                </Button>
                                <Button onClick={createObject} color="primary" disabled={isObjectCreating || isObjectDataInvalid}>
                                    Ок
                                </Button>
                            </>
                            :
                            <Button autoFocus onClick={closeNewObjectDialog} color="default">
                                Закрыть окно
                            </Button>
                    }
                </React.Fragment>
            }>
                <Grid container spacing={1} key={isFormChanged}>
                    <Grid item item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel id="createObjectTypeSelect">Тип объекта</InputLabel>
                            <Select
                                fullWidth={createObjectType === undefined}
                                labelId="createObjectTypeSelect"
                                value={createObjectType}
                                onChange={(e)=> setCreateObjectType(e.target.value)}
                                label="Тип объекта"
                            >
                                <MenuItem value={undefined}>
                                    <em>Не выбран</em>
                                </MenuItem>
                                {
                                    unlivedPrefixesList.map((data, i) => (
                                        <MenuItem value={data.id}>{data.description}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        createObjectType &&
                        <Grid item item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel id="createObjectAreaSelect">Местоположение объекта</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="createObjectAreaSelect"
                                    value={createObjectArea}
                                    onChange={(e)=> setCreateObjectArea(e.target.value)}
                                    label="Местоположение объекта"
                                >
                                    <MenuItem value={undefined}>
                                        <em>Не выбран</em>
                                    </MenuItem>
                                    {
                                        areaList.map((data, i) => (
                                            <MenuItem value={data.id}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {
                        createObjectArea &&
                            <React.Fragment>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <TextField
                                        aria-invalid={!createObjectNumber}
                                        value={createObjectNumber}
                                        onChange={(e)=>{setCreateObjectNumber(e.target.value)}}
                                        fullWidth
                                        type='number'
                                        label="Номер объекта" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div style={{ display: 'flex', verticalAlign: 'middle'}}>
                                        {
                                            createObjectChar !== undefined &&
                                            <TextField
                                                style={{marginRight: 10}}
                                                disabled={false}
                                                label="Символ"
                                                variant="outlined"
                                                value={createObjectChar}
                                                onChange={(e)=>{setCreateObjectChar(e.target.value.length <= 1 ? e.target.value: createObjectChar)}}
                                            />
                                        }
                                        <FormControlLabel
                                            style={{marginBottom: 0}}
                                            control={
                                                <Checkbox
                                                    checked={createObjectChar !== undefined}
                                                    onChange={(e)=>{
                                                        setCreateObjectChar(e.target.checked ? '' : undefined)
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Символ в номере объекта"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div style={{ display: 'flex', verticalAlign: 'middle'}}>
                                        {
                                            createObjectOneTimeLimitation !== undefined &&
                                            <TextField
                                                style={{marginRight: 10}}
                                                disabled={false}
                                                label="Лимит номеров"
                                                variant="outlined"
                                                value={createObjectOneTimeLimitation}
                                                onChange={(e)=>{setCreateObjectOneTimeLimitation(e.target.value.length <= 1 ? e.target.value: createObjectOneTimeLimitation)}}
                                            />
                                        }
                                        <FormControlLabel
                                            style={{marginBottom: 0}}
                                            control={
                                                <Checkbox
                                                    checked={createObjectOneTimeLimitation !== undefined}
                                                    onChange={(e)=>{
                                                        setCreateObjectOneTimeLimitation(e.target.checked ? '' : undefined)
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Ограничение одновременной парковки"
                                        />
                                    </div>
                                </Grid>
                            </React.Fragment>
                    }
                </Grid>
                {
                    createdUnlivedAlertType !== -1 &&
                    <Alert variant="outlined" severity={createdUnlivedAlertType ? "error": "success"} className={classes.alert}>
                        {
                            createdUnlivedAlertType === 1 ?
                                <>Объект <strong>{`${createObjectNumber}${createObjectChar ? `/${createObjectChar}`: ``}`}</strong> уже был создан.</>
                                : createdUnlivedAlertType === 2 && <>Внутрення ошибка сервера {createdUnlivedError}</>
                        }
                        {
                            createdUnlivedAlertType !== 2 &&
                            <Link onClick={()=>{
                                closeNewObjectDialog();
                                setManagedUnlived({id: createObjectId, number: createObjectNumber, char: createObjectChar, prefix: unlivedPrefixesList.find((v)=>v.id===createObjectType).prefix});
                                setNeedObjectManage(true);
                            }} color="inherit">
                                <strong> Нажмите здесь для перехода к редактированию объекта {`${createObjectNumber}${createObjectChar ? `/${createObjectChar}`: ``}`}.</strong>
                            </Link>
                        }
                    </Alert>
                }
            </PopupDialog>
        </React.Fragment>
    )

}
