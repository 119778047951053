import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Accordion, AccordionDetails, Link, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import PopupDialog from "../component/PopupDialog";
import LicencePlateInput from "../component/LicencePlate/LicencePlateInput";
import {changeAreaDetail, loadAreaDetail} from "../httpUtils";

export default function EditAreaDialog(props) {
    const [ isOpen, setOpen ] = useState(false);
    const [ currentId, setCurrentId ] = useState(undefined);

    const [ areaName, setAreaName ] = useState(undefined);
    const [ changedAreaName, setChangedAreaName ] = useState(undefined);

    const [ areaGuest, setAreaGuest ] = useState(undefined);
    const [ changedAreaGuest, setChangedAreaGuest ] = useState(undefined);

    const [ areaCapacity, setAreaCapacity ] = useState(undefined);
    const [ changedAreaCapacity, setChangedAreaCapacity ] = useState(undefined);

    const [ isChanged, setChanged ] = useState(false);
    const [ isSaving, setSaving ] = useState(false);

    useEffect(()=>{
        setChanged(false);
        if (changedAreaCapacity !== areaCapacity) setChanged(true);
        if (changedAreaGuest !== areaGuest) setChanged(true);
        if (changedAreaName !== areaName) setChanged(true);
    }, [changedAreaCapacity, changedAreaGuest, changedAreaName])

    useEffect(()=>{
        if (props.open && props.id){
            setOpen(true)
            setCurrentId(props.id)
        } else {
            setOpen(false);
            setCurrentId(undefined)
        }
    }, [props.open, props.id])

    const loadData = (currentId) => {
        loadAreaDetail(currentId).then((value) => {
            setAreaName(value.name)
            setChangedAreaName(value.name)

            setAreaGuest(value.guest === 1)
            setChangedAreaGuest(value.guest === 1)

            setAreaCapacity(value.capacity || undefined)
            setChangedAreaCapacity(value.capacity || undefined)

            setChanged(false)
        })
    }
    useEffect(()=>{
        if (currentId){
            loadData(currentId)
        }
    }, [currentId])

    const closeDialog = () => {
      props.hasOwnProperty('needClose') && props.needClose();
    }

    const parentUpdate = () => {
        props.hasOwnProperty('needReload') && props.needReload();
    }

    const saveArea = (id, name, guest, capacity) => {
        setSaving(true);
        changeAreaDetail(id, name, guest, capacity).then(value => {
            setSaving(false);
            parentUpdate();
            loadData(id)
        }).catch(reason => {
            setSaving(false);
        })
    }

    return (
        <PopupDialog maxWidth="xs" open={isOpen} onClose={closeDialog} title={"Настройка участка"} actionBoard={
            <React.Fragment>
                {
                    isChanged ?
                        <>
                            <Button onClick={closeDialog} color="default" disabled={isSaving}>
                                Отмена
                            </Button>
                            <Button onClick={()=>{saveArea(currentId, changedAreaName, changedAreaGuest, changedAreaCapacity)}} color="primary" disabled={isSaving}>
                                Сохранить
                            </Button>
                        </>
                        :
                        <Button onClick={closeDialog} color="default" disabled={isSaving}>
                            Закрыть окно
                        </Button>
                }
            </React.Fragment>
        }>

            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <TextField
                        disabled={false}
                        fullWidth
                        label="Наименование"
                        variant="outlined"
                        value={changedAreaName}
                        onChange={(e)=>{setChangedAreaName(e.target.value)}}
                    />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <div style={{ display: 'flex', verticalAlign: 'middle'}}>
                        {
                            changedAreaCapacity !== undefined &&
                            <TextField
                                style={{marginRight: 10}}
                                disabled={false}
                                label="Вместимость"
                                variant="outlined"
                                type='number'
                                value={changedAreaCapacity}
                                onChange={(e)=>{setChangedAreaCapacity(e.target.value > 0 ? e.target.value: changedAreaCapacity)}}
                            />
                        }
                        <FormControlLabel
                            style={{marginBottom: 0}}
                            control={
                                <Checkbox
                                    checked={changedAreaCapacity !== undefined}
                                    onChange={(e)=>{
                                        setChangedAreaCapacity(changedAreaCapacity !== undefined ? undefined: 1)
                                    }}
                                    color="primary"
                                />
                            }
                            label="Ограниченная вместимость"
                        />
                    </div>

                </Grid>

                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <div style={{ display: 'flex', verticalAlign: 'middle'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{marginBottom: 0}}
                                    checked={changedAreaGuest}
                                    onChange={(e)=>{
                                        setChangedAreaGuest(e.target.checked)
                                    }}
                                    color="primary"
                                />
                            }
                            label="Гостевая парковка"
                        />
                    </div>
                </Grid>

            </Grid>

        </PopupDialog>
    )
}
