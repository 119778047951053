import React, {Component, useEffect, useState} from 'react';
import {Link, Route, BrowserRouter as Router} from 'react-router-dom';
import WithAuth from './WithAuth';
import Events from './Event/Events';
import Event from './Event/Event';
import AreaSettings from './Parking/Settings/Area';
import PortalSettings from './Parking/Settings/Portal';
import BarrierSettings from './Parking/Settings/Barrier';
import CameraSettings from './Parking/Settings/Camera';
import EndpointList from './Parking/Endpoint/EndpointList';
import Dashboard from './Dashboard/Dashboard';
import Login from './Login';

import Quotas from "./Parking/Quotas";
import Guests from "./Parking/Guests";
import { createTheme, MuiThemeProvider} from "@material-ui/core";
import { createStore} from '@reduxjs/toolkit'
import {Provider} from "react-redux";
import reducer from "./store/reducer";
import Apartment from "./Apartment/Apartment";
import LicencePlates from "./LicencePlates/LicencePlates";

import { grey, indigo } from '@material-ui/core/colors'
import Statements from "./Statements/Statements";
import {ErrorBoundary} from "./ErrorBoundary";
import LicencePlatesWithoutOwners from "./LicencePlatesWithoutOwners";
import Overview from "./Overview/Overview";
import JournalCRM from "./Journal/JournalCRM";
import Calls from "./Journal/Calls";
import LicencePlatesWithoutBinding from "./LicencePlatesWithoutBinding";
import CamerasSettings from "./Settings/Cameras";
import AreasSettings from "./Settings/Areas";
import Unlived from "./Unlived/Unlived";
import Guest from "./Journal/Guest";
import RentServices from "./Rent/Services";
import RentContractList from "./Rent/RentContractList";
import RentAccountantReports from "./Rent/RentAccountantReports";
import CustomersList from "./Admin/CustomersList";
import RentCashierReports from "./Rent/RentCashierReports";
import LicencePlatesWithKeycodes from "./Keycode/LicencePlatesWithKeycodes";
import LicencePlatesActivity from "./Rent/Reports/LicencePlatesActivity";

const store = createStore(reducer);

function App() {
    const [theme, setTheme] = useState(store.getState().theme)
    const [settings, setSettings] = useState(store.getState().settings)

    const themeLight = createTheme({
        palette: {
            type: "light"
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        backgroundColor: grey[200],
                        width: 10,
                        height: 10,
                    },
                    '*::-webkit-scrollbar-track': {
                        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '*::-webkit-scrollbar-thumb': {
                        borderRadius: 8,
                        border: `3px solid`,
                        borderColor: grey[200],
                        backgroundColor: grey[400],
                    }
                }
            }
        }
    });

    const themeDark = createTheme({
        palette: {
            type: "dark",
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        backgroundColor: grey[900],
                        width: 10,
                        height: 10,
                    },
                    '*::-webkit-scrollbar-track': {
                        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '*::-webkit-scrollbar-thumb': {
                        borderRadius: 8,
                        border: `3px solid`,
                        borderColor: grey[900],
                        backgroundColor: indigo[500],
                    }
                }
            }
        }
    });

    store.subscribe(() => {
        setTheme(store.getState().theme)
    })

    const mqListener = (e => {
        e.matches ? setTheme('dark') : setTheme('light')
    });

    useEffect(() => {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMq.addListener(mqListener);
        return () => darkThemeMq.removeListener(mqListener);
    }, []);

    return (
        // <ErrorBoundary>
        <Router>
                <Provider store={store}>
                    <MuiThemeProvider theme={theme == 'light' ? themeLight : themeDark}>
                        <Route path="/" exact component={Events} /> {/*fixed reload*/}
                        <Route path="/events" exact component={Events} /> {/*fixed reload*/}
                        <Route path="/dashboard" exact component={Dashboard} /> {/*fixed reload*/}
                        <Route path="/event/:eventId" component={Event} /> {/*fixed reload*/}
                        <Route path="/apartment" component={Apartment} /> {/*fixed reload*/}
                        <Route path="/unlived" component={Unlived} /> {/*fixed reload*/}
                        <Route path="/licence-plates" component={LicencePlates} /> {/*fixed reload*/}

                        <Route path="/rent/services" component={RentServices} />
                        <Route path="/rent/contract" component={RentContractList} />

                        <Route path="/rent/report/cashier" component={RentCashierReports} />
                        <Route path="/rent/report/accountant" component={RentAccountantReports} />

                        <Route path="/admin/customers" component={CustomersList} />

                        {/*<Route path="/events/all" component={WithAuth(EventAll)} />*/}
                        <Route path="/settings/area" component={AreasSettings} />
                        {/*<Route path="/settings/area" component={AreasSettings} />*/}

                        <Route path="/settings/portal" component={WithAuth(PortalSettings)} />
                        <Route path="/settings/barrier" component={WithAuth(BarrierSettings)} />
                        <Route path="/settings/camera" component={WithAuth(CameraSettings)} />
                        <Route path="/settings/cameras" component={CamerasSettings} />
                        <Route path="/settings/endpoints" component={WithAuth(EndpointList)} />

                        <Route path="/reports/lpwkc" component={LicencePlatesWithKeycodes} /> {/*fixed reload*/}
                        <Route path="/reports/quotas" component={Quotas} /> {/*fixed reload*/}
                        <Route path="/reports/guests" component={Guests} />
                        <Route path="/reports/lpwow" component={LicencePlatesWithoutOwners} />
                        <Route path="/reports/lpwob" component={LicencePlatesWithoutBinding} /> {/*fixed reload*/}
                        <Route path="/reports/activity" component={LicencePlatesActivity} /> {/*fixed reload*/}

                        <Route path="/statements" component={Statements} /> {/*fixed reload*/}

                        <Route path="/overview" component={Overview} /> {/*fixed reload*/}
                        <Route path="/crmJournal" component={JournalCRM} /> {/*fixed reload*/}
                        <Route path="/callJournal" component={Calls} /> {/*fixed reload*/}

                        <Route path="/guest" component={Guest} /> {/*fixed reload*/}

                    </MuiThemeProvider>
                    <Route path="/login" component={Login} />

                    {/*<Redirect from='*' to='/' />*/}
                </Provider>
        </Router>
        // </ErrorBoundary>
    );
}

export default App;
