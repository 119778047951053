import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
    getCustomerParkingList,
    getSystemParkingList, setCustomerParkingList,
} from "../httpUtils";
import {Checkbox, FormControlLabel} from "@material-ui/core";

export default function EditCustomerDialogParking(props) {
    const [ currentId, setCurrentId ] = useState(undefined);
    const [ customerParking, setCustomerParking ] = useState([]);
    const [ parking, setParking ] = useState([]);

    const [ isSaving, setSaving ] = useState(false);

    const _checkPermission = (id) =>{
        return customerParking.find((v) => v === id) !== undefined
    }

    const _setPermission = (id, exist) => {
        if (exist) {
            if (!_checkPermission(id)) {
                setCustomerParking([...customerParking, id])
            }
        } else {
            const tmp = [...customerParking];
            tmp.splice(customerParking.findIndex((v)=>v==id), 1);
            setCustomerParking(tmp);
        }
    }

    const _savePermission = (userId, permission) =>{
        setSaving(true);
        console.log(userId, permission)
        setCustomerParkingList(userId, permission).then((v)=>{
            setSaving(false);
        }).catch((e)=>{
            setSaving(false);
        })
    }

    useEffect(()=>{
        if (currentId > 0) {
            getCustomerParkingList(currentId).then((v)=>{
                setCustomerParking(v)
            })
            getSystemParkingList().then((v)=>{
                setParking(v)
            })
        }
    }, [currentId]);

    useEffect(()=>{
        setCurrentId(props.id)
    }, [props.id])

    return (


            <Grid container spacing={1}>
                {
                    parking.map((v, i)=> (
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12} key={`pg-${i}`}>
                            <FormControlLabel
                                style={{marginBottom: 0}}
                                control={
                                    <Checkbox
                                        style={{marginBottom: 0}}
                                        checked={_checkPermission(v.id)}
                                        onChange={(e)=>{
                                            _setPermission(v.id, e.target.checked)
                                        }}
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={v.name}
                            />
                        </Grid>
                    ))
                }

                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <Button onClick={_savePermission.bind(null, currentId, customerParking)} color="primary" fullWidth disabled={isSaving}>
                        Сохранить
                    </Button>
                </Grid>

            </Grid>


    )
}
