import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

import Typography from '@material-ui/core/Typography';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

export default class ApartmentOwnersSettings extends Component {
 constructor() {
   super();
   this.state = {
     list: [],
     showEditDialcog: false,
     showEditDialogModeEdit: false,
     editId: 0,
     editNumber: 0,

     editError: '',


     numbers: [],
     open: false,

     fioAddDialog: '',
     selectedOwnerId: -1,


     value: 0,


     apartmentNumbers: [],
     selectedAddNumber: '',
     inputNumberInvalid: false

   }
   this.loadApartmentNumbers = this.loadApartmentNumbers.bind(this);
   this.addApartmentNumber = this.addApartmentNumber.bind(this);
   this.deleteApartmentNumber = this.deleteApartmentNumber.bind(this);
   this.submitNewNumber = this.submitNewNumber.bind(this);

   this.handleLoad = this.handleLoad.bind(this);
   this.handleSubmitArea = this.handleSubmitArea.bind(this);
 }

 handleSubmitArea(action) {
   console.log(action);
   let reqMethod;
   let reqBody;
   let reqUrl;
   switch(action) {
    case 'delete':
      reqMethod='DELETE';
      reqUrl=process.env.REACT_APP_API_URL+'/parking/area/'+this.state.editId;
      reqBody=[];
      break;
    case 'create':
      reqMethod='POST';
      reqUrl=process.env.REACT_APP_API_URL+'/apartments/';
      reqBody=JSON.stringify({"number" :this.state.editNumber });
      break;
    case 'update':
      reqMethod='PUT';
      reqUrl=process.env.REACT_APP_API_URL+'/parking/area/'+this.state.editId;
      reqBody=JSON.stringify({"name" :this.state.editName, "guest" :this.state.editGuest});
      break;
  }
   fetch(reqUrl, {
     method: reqMethod,
     credentials: 'include',
     body: reqBody,
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => {
     console.log(res.status);
     switch (res.status) {
        case 401:

          break;
        case 403:
          this.setState({editError: "Запрещено!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
        case 409:
          this.setState({editError: "Такая территория существует!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
       default:
       this.setState({showEditDialog: false});
       this.handleLoad();
     }
   })
   .catch(err => {
     console.log(err);
   });
 }

 handleLoad(){
  //  fetch(process.env.REACT_APP_API_URL+'/apartments/owners',{
  //    method: 'GET',
  //    credentials: 'include',
  //    headers: {
  //      'Content-Type': 'application/json'
  //    }
  //  })
  //  .then(res => res.json())
  //  .then(res => this.setState({list: res}));

   fetch(process.env.REACT_APP_API_URL+'/apartments/owners',{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => this.setState({numbers: res}));
 }

 loadApartmentNumbers(){
  fetch(process.env.REACT_APP_API_URL+'/apartments/owners/'+this.props.apartmentId,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .then(res => this.setState({apartmentNumbers: res}));
  }

  addApartmentNumber(){

    fetch(process.env.REACT_APP_API_URL+'/apartments/owners/'+this.props.apartmentId, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({"owner" : this.state.selectedOwnerId }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      this.loadApartmentNumbers();
      console.log(res.status);
      switch (res.status) {
         case 401:
 
           break;
         case 403:
          //  this.setState({editError: "Запрещено!"},()=>{
          //    window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          //  });
           break;
         case 409:
          //  this.setState({editError: "Такая территория существует!"},()=>{
          //    window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          //  });
           break;
        default:
        // this.setState({showEditDialog: false});
        
      }
    })
    .catch(err => {
      console.log(err);
    });

  }

  deleteApartmentNumber(number){

    // event.preventDefault();
    // if (!this.state.inputNumberInvalid){
      fetch(process.env.REACT_APP_API_URL+`/apartments/owners/${this.props.apartmentId}/${number}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((res) => {
        this.loadApartmentNumbers();

      })

  }

  submitNewNumber(event){
    event.preventDefault();
    // if (!this.state.inputNumberInvalid){
      fetch(process.env.REACT_APP_API_URL+'/apartments/owners', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({"fio" :this.state.fioAddDialog}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((res) => {
        this.handleLoad()
        this.setState({
          selectedOwnerId: res.insertId,
          selectedAddNumber: this.state.fioAddDialog,
          open: false
        })

      })

      
    // }

  }

 componentDidMount() {
   this.handleLoad()
   this.loadApartmentNumbers()
 }

 render() {
  const filter = createFilterOptions();

   return (
    <>
      <Container style={{ display: 'inline-flex', width: '100%', paddingTop: 20}}>
      <Autocomplete
        value={this.state.selectedAddNumber}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // setTimeout(() => {
              this.setState({
                open: true,
                fioAddDialog: newValue
              });
              newValue.match(/(^[abekmhopctyx]{1}[0-9]{3}[[abekmhopctyx]{2}[0-9]{2,3}$)/i) ? this.setState({inputNumberInvalid : false}) : this.setState({inputNumberInvalid : true})
            // });
          } else if (newValue && newValue.inputValue) {
            this.setState({
              open: true,
              fioAddDialog: newValue.inputValue
            });
            newValue.inputValue.match(/(^[abekmhopctyx]{1}[0-9]{3}[[abekmhopctyx]{2}[0-9]{2,3}$)/i) ? this.setState({inputNumberInvalid : false}) : this.setState({inputNumberInvalid : true})         
          } else {
            this.setState({selectedAddNumber: newValue ? newValue.fio: '', selectedOwnerId: newValue ? newValue.id: -1});
            
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              fio: `Добавить "${params.inputValue}"`,
            });
          }

          return filtered;
        }}

        options={this.state.numbers}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.fio;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.fio}
        fullWidth
        margin="normal"
        freeSolo
        size="small"
        renderInput={(params) => (
          <TextField {...params} label="Выберите владельца" variant="outlined" />
        )}
      />
      <Button variant="contained" color="primary" style={{marginLeft: 8}} onClick={this.addApartmentNumber}>
        Привязать
      </Button>
    </Container>

    <Dialog open={this.state.open} onClose={()=>this.setState({open: false })} aria-labelledby="form-dialog-title1">
      <form onSubmit={this.submitNewNumber}>
        <DialogTitle id="form-dialog-title1">Добавить person</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите данные о человеке
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={this.state.fioAddDialog}
            onChange={(event) => {
                this.setState({fioAddDialog: event.target.value})

                }}
            label="Номер"
            type="text"


          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({open: false })} color="primary">
            Отмена
          </Button>
          <Button type="submit" color="primary">
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    

    <List >
    {
      this.state.apartmentNumbers.map((data, i) => (
        <ListItem key={i}>
          
          <Typography variant="button" display="block" gutterBottom color={"initial"}>
          {data.fio}

          </Typography>
          <ListItemSecondaryAction>

            <Button
              variant="contained"
              color="secondary"
              endIcon={<HighlightOffIcon />}
              onClick={this.deleteApartmentNumber.bind(this, data.id)}
            >
              Отвязать
            </Button>
          </ListItemSecondaryAction> 
        </ListItem>
      ))
    }
    </List> 

    </>
   );
 }
}
