import {IMaskInput, IMask} from "react-imask";
import React from "react";

const cyrillicToLatin = new Map([
    ['А', 'A'],
    ['В', 'B'],
    ['Е', 'E'],
    ['К', 'K'],
    ['М', 'M'],
    ['Н', 'H'],
    ['О', 'O'],
    ['Р', 'P'],
    ['С', 'C'],
    ['Т', 'T'],
    ['У', 'Y'],
    ['Х', 'X'],
])

export const cyrillicToLatinAutoConvert = (str) => {
    return str.toUpperCase().split('').map((s)=> cyrillicToLatin.has(s) ? cyrillicToLatin.get(s): s).join('');
}

export default function LicencePlateMask(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[
                { //dpr
                    mask: 'S 000 SS RRR',

                    blocks: {
                        S: {
                            mask: IMask.MaskedEnum,
                            enum: ['A','B','E','K','M','H','O','P','C','T','Y','X']
                        },
                        RRR: {
                            mask: IMask.MaskedEnum,
                            enum: ['DPR', 'LPR']
                        }
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { //rus
                    mask: 'S DDD SS DD[D]',
                    definitions: {
                        S: /[ABEKMHOPCTYX]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { //rus
                    mask: 'DDDD SS DD[D]',
                    definitions: {
                        S: /[ABEKMHOPCTYX]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { //rus
                    mask: 'SS DDDD DD[D]',
                    definitions: {
                        S: /[ABEKMHOPCTYX]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // lv
                    mask: 'SS{-}D[DDD]',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // Est
                    mask: 'DDD SSS',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // fin
                    mask: 'SS[S]{-}DD[D]',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // italy
                    mask: 'SS DDD SS',
                    definitions: {
                        S: /[A-HJ-NPR-TV-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // ua
                    mask: 'SS DDDD SS',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // by
                    mask: 'DDDD SS{-}D',
                    definitions: {
                        S: /[ABEIKMNOPCTX]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // by
                    mask: 'SS DDDD{-}D',
                    definitions: {
                        S: /[ABEIKMNOPCTX]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
                { // kg
                    mask: 'DD DDD SS[S]',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                },
                { // kz
                    mask: 'DDD SS[S] DD',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                },
                { // norway
                    mask: 'SS DDDD[D]',
                    definitions: {
                        S: /[A-Z]/,
                        D: /[0-9]/,
                    },
                    prepare: cyrillicToLatinAutoConvert,
                    lazy: false,
                },
            ]}
            unmask={true}
            inputRef={inputRef}
            onAccept={
                (value, mask) => {
                    // console.log(value)
                }
            }
            onComplete={
                (value, mask) => {
                    onChange(value)
                }
            }
            placeholder='Введите номерной знак'
        />
    );
}
