import React, {Component, useEffect, useState} from 'react';
import Menu from '../Header/Menu';
import EventCard from './EventCard';
import {ButtonGroup, CssBaseline, makeStyles} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    buttonGroup: {
        marginBottom: theme.spacing(2),
    }
}));

export default function Event(props){
    const classes = useStyles();

    const [nextEvent, setNextEvent] = useState();
    const [prevEvent, setPrevEvent] = useState();
    const [currentEvent, setCurrentEvent] = useState({});
    const [currentEventFilters, setCurrentEventFilters] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [fetchDirection, setFetchDirection] = useState('');


    async function loadEvent(id, filter){
        const r = await fetch(process.env.REACT_APP_API_URL+'/parking/events/'+id+''+filter,{
             method: 'GET',
             credentials: 'include',
             headers: {
               'Content-Type': 'application/json'
             }
        })
        const d = await r.json();
        setCurrentEvent(d.current);
        setNextEvent(d.hasOwnProperty('next') ? d.next: undefined);
        setPrevEvent(d.hasOwnProperty('prev') ? d.prev: undefined);
    }

    function fetchPrev(){
        setFetchDirection('prev');
        setIsFetching(true);
    }

    function fetchNext(){
        setFetchDirection('next');
        setIsFetching(true);
    }

    function handleKeyEvent(event) {
        switch (event.key) {
            case 'ArrowLeft':
                fetchPrev();
                break;
            case 'ArrowRight':
                fetchNext();
                break;
        }
    }

    useEffect(()=>{
        if (isFetching && fetchDirection){
            setIsFetching(false);
            setFetchDirection('');
            if (prevEvent && prevEvent.hasOwnProperty('id') || nextEvent && nextEvent.hasOwnProperty('id')) {
                switch (fetchDirection) {
                    case 'next':
                        if (nextEvent){
                            let navigationUrl = {Page: nextEvent.id, Url: `/event/${nextEvent.id}${currentEventFilters}`};
                            window.history.replaceState({}, navigationUrl.Page, navigationUrl.Url);
                            loadEvent(nextEvent.id, `/${currentEventFilters}`);
                        }
                        break;
                    case 'prev':
                        if (prevEvent){
                            let navigationUrl = {Page: prevEvent.id, Url: `/event/${prevEvent.id}${currentEventFilters}`}
                            window.history.replaceState({}, navigationUrl.Page, navigationUrl.Url);
                            loadEvent(prevEvent.id, `/${currentEventFilters}`);
                        }
                        break;
                }
            }
        }
    }, [isFetching, fetchDirection])

    useEffect(()=>{
        if ( window.location.search ) {
            setCurrentEventFilters(`/${window.location.search}`)
        }
        loadEvent(props.match.params.eventId, `/${window.location.search}`);
    }, [props.match.params.eventId, window.location.search])

    useEffect(()=>{
        document.addEventListener("keydown", handleKeyEvent, false);
        return () => {
            document.removeEventListener("keydown", handleKeyEvent, false);
        }
    }, [])

    return(
        <React.Fragment>
            <Menu title='Событие' subtitle={currentEvent.id} reloadData={()=>{
                window.location.href = "/events";
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <CssBaseline />
                <ButtonGroup fullWidth className={classes.buttonGroup} variant="contained" aria-label="outlined primary button group">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<NavigateBeforeIcon>send</NavigateBeforeIcon>}
                        onClick={(e)=> { fetchPrev(); }}
                    >
                        Предыдущее событие
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<NavigateNextIcon>send</NavigateNextIcon>}
                        justify="flex-end"
                        onClick={(e)=> { fetchNext(); }}
                    >
                        Следующее событие
                    </Button>
                </ButtonGroup>
                <EventCard current={currentEvent} />
            </div>


        </React.Fragment>
    )
}
