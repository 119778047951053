import React, { useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";

export default function AcceptancePaymentMethod(props) {
    const [amount, setAmount] = useState(0);

    const [amountCash, setAmountCash] = useState(0);
    const [amountCashless, setAmountCashless] = useState(0);

    const [mixedPaymentMethod, setMixedPaymentMethod] = useState(false);

    useEffect(()=>{
        if (amount >= 0){
            setAmount(props.amount)
        }
    }, [props.amount])

    const accept = (cash, cashless) => {
        props.hasOwnProperty('accept') && props.accept({
            amountCash: cash > 0 ? cash : undefined,
            amountCashless: cashless > 0 ? cashless : undefined
        });
    }

    useEffect(()=>{
        if (mixedPaymentMethod){
            const _amountCashless = parseInt(amountCashless)
            if (_amountCashless > 0) setAmountCash(amount - _amountCashless)
        }
    }, [amountCashless])

    useEffect(()=>{
        if (mixedPaymentMethod){
            const _amountCash = parseInt(amountCash)
            if (_amountCash > 0) setAmountCashless(amount - _amountCash)
        }
    }, [amountCash])

    useEffect(()=>{
        if (mixedPaymentMethod){
            setAmountCash(0);
            setAmountCashless(0);
        }
    }, [mixedPaymentMethod])

    return (
        <React.Fragment>
            {
                mixedPaymentMethod ?
                    <React.Fragment>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                type={"number"}
                                fullWidth
                                onChange={(e)=>{
                                    const value = parseFloat(e.target.value || 0).toFixed(2) * 100;
                                    if (value <= amount)setAmountCash(value)
                                }}
                                value={amountCash/100}
                                label="Наличными" variant="outlined" step="0.01"/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                type={"number"}
                                fullWidth
                                onChange={(e)=>{
                                    const value = parseFloat(e.target.value || 0).toFixed(2) * 100;
                                    if (value <= amount)setAmountCashless(value)
                                }}
                                value={amountCashless/100}
                                label="Безналичными" variant="outlined" step="0.01"/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button variant={"contained"} color={"default"} fullWidth disabled={false} onClick={()=>{setMixedPaymentMethod(false)}}>Назад</Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button variant={"contained"} color={"primary"} fullWidth disabled={(amountCash+amountCashless) !== amount} onClick={accept.bind(this, amountCash, amountCashless)}>Подтвердить</Button>
                        </Grid>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button variant={"contained"} color={"primary"} fullWidth disabled={false} onClick={accept.bind(this, amount, 0)}>Наличными</Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button variant={"contained"} color={"primary"} fullWidth disabled={false} onClick={accept.bind(this, 0, amount)}>Безналичными</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant={"contained"} color={"default"} fullWidth disabled={false} onClick={()=>{setMixedPaymentMethod(true)}}>Смешанная оплата</Button>
                        </Grid>
                    </React.Fragment>
            }
        </React.Fragment>
    )
}
