import React, {useEffect, useState} from 'react';
import {
    changeLicencePlateArea,
    loadArea,
    loadLicencePlateAreaInfo,
} from "../httpUtils";
import {
    Checkbox,
    FormControlLabel, FormGroup, makeStyles,
    TextField, Tooltip
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    inputForm: {
        marginBottom: theme.spacing(2),
    },
}));

export default function LicencePlateAllowedArea(props) {
    const classes = useStyles();
    const [number, setNumber] = useState('');
    const [areaList, setAreaList] = useState([]);

    const [allowOnInit, setAllowOnInit] = useState([]);
    const [disallowReasonOnInit, setDisallowReasonOnInit] = useState('');

    const [areaNumberDisallowed, setAreaNumberDisallowed] = useState([]);
    const [disallowReason, setDisallowReason] = useState('');
    const [isDisallow, setDisallow] = useState(false);

    const [isAreaChanging, setAreaChanging] = useState(false);
    const [isChanging, setChanging] = useState(false);

    useEffect(()=>{
        setNumber(props.number);
    }, [props.number])

    useEffect(()=>{
        props.hasOwnProperty('setDisallowAreas') && props.setDisallowAreas(areaNumberDisallowed)
    }, [areaNumberDisallowed])

    useEffect(()=>{
        const withLocking = props.hasOwnProperty('saveWithLocking')
        if (props.onSave) saveArea(withLocking);
    }, [props.onSave])

    useEffect(()=>{
        if (props.onReload) load();
    }, [props.onReload])

    useEffect(()=>{
        setChanging(props.disabled)
    }, [props.disabled])

    useEffect(()=>{
        if (props.allow){
            setAllowOnInit(props.allow)
        }
    }, [props.allow])

    // useEffect(()=>{
    //     if (props.disallow){
    //         setAreaNumberDisallowed(props.disallow)
    //     }
    // }, [props.disallow])

    useEffect(()=>{
        setDisallowReasonOnInit(props.disallowReason)
    }, [props.disallowReason])

    function load(){

        loadArea().then((r)=>{
            const areaListR = [{id: 0, name: "Улица"},...r];
            setAreaList(areaListR);

            loadLicencePlateAreaInfo(number).then((r1)=>{
                setDisallow(r1.isDisallow);
                if(r1.isDisallow){
                    setDisallowReason(r1.disallowReason);
                    setAreaNumberDisallowed(r1.disallowAreas);
                }
            }).catch((e1)=>{
                if (props.disallow){
                    setAreaNumberDisallowed(props.disallow)
                }
                setDisallowReason(disallowReasonOnInit)
            }).finally(() => {
                if (allowOnInit.length > 0){
                    setAreaNumberDisallowed(areaListR.filter((i) => {return allowOnInit.indexOf(i.id) < 0} ).map((i)=>i.id))
                    setDisallowReason(disallowReasonOnInit)
                }
            })
        }).catch((e)=>{

        })
    }

    function saveArea(withLocking) {
        setAreaChanging(false);
        setChanging(true);
        changeLicencePlateArea(number, disallowReason, areaNumberDisallowed).then((r)=>{
            if (props.hasOwnProperty('onSaveAvailable')) props.onNeedRelaod();
            !withLocking && setChanging(false);
        }).catch((e)=>{
            !withLocking && setChanging(false);
        })
    }

    useEffect(()=>{
        if (areaNumberDisallowed.length > 0){
            setDisallow(true)
        } else {
            setDisallow(false);
            setDisallowReason('');
        }
    }, [areaNumberDisallowed])

    useEffect(()=>{
        if (number) load()
    }, [number])

    useEffect(()=> {
        if (!isAreaChanging || isChanging) {
            if (props.hasOwnProperty('onSaveAvailable')) props.onSaveAvailable(false);
        } else {
            if (props.hasOwnProperty('onSaveAvailable')) props.onSaveAvailable(true);
        }
    }, [isAreaChanging, isChanging]);

    return(
        <React.Fragment>
            <div className={classes.root}>
                <FormGroup row>
                    {
                        areaList.map((al,i)=>(
                            <FormControlLabel
                                key={`${i}-fcllp`}
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        key={`chckb-${i}`}
                                        checked={areaNumberDisallowed.findIndex((v)=>v===al.id) === -1}
                                        onChange={(e)=>{
                                            if (e.target.checked){
                                                setAreaNumberDisallowed(areaNumberDisallowed.filter((a)=> a !== al.id));
                                            } else {
                                                setAreaNumberDisallowed([...areaNumberDisallowed, al.id]);
                                            }
                                            setAreaChanging(true);
                                        }}
                                        color="primary"
                                        disabled={isChanging}
                                    />
                                }
                                label={al.name}
                            />
                        ))
                    }
                </FormGroup>
                {
                    isDisallow &&
                    <Tooltip title="Рекомендуем подробно указывать причину запрета. Причина будет отображена на посте охраны." placement="bottom">
                        <TextField
                            disabled={isChanging}
                            className={classes.inputForm}
                            onChange={(e)=>{setDisallowReason(e.target.value); setAreaChanging(true)}}
                            value={disallowReason}
                            fullWidth
                            label="Причина запрета" variant="outlined" />
                    </Tooltip>
                }
            </div>
        </React.Fragment>
    )
}
