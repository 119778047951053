import React, {useEffect, useState} from 'react';
import Menu from '../Header/Menu';
import {
    Accordion, AccordionActions,
    AccordionDetails,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CssBaseline, FormControl, Input, InputAdornment, InputLabel, LinearProgress,
    makeStyles, MenuItem, OutlinedInput, Paper, Select, TextField, Tooltip
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {appendFilter, getSortParam, getTypeEvents, showNumber} from "../utils";
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {loadBarrierList} from "../httpUtils";
import ScrollToTop from "../component/ScrollToTop";
import {cyrillicToLatinAutoConvert} from "../component/LicencePlate/LicencePlateMask";
import IconButton from "@material-ui/core/IconButton";
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import {Alert, AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    activeFactSearch: {
        color: theme.palette.primary.main
    }
}));

export default function Events(props) {
    const classes = useStyles();
    const [eventList, setEventList] = useState([]);
    const [barrierList, setBarrierList] = useState([]);
    const [filterParam, setFilterParam] = useState({number: undefined, barrier: undefined, eventType: undefined, eventDatetimeBegin: undefined, eventDatetimeEnd: undefined, reverse: false, fastSearch: false});

    const [isFetching, setFetching] = useState(false);
    const [isContinueLoad, setContinueLoad] = useState(false);
    const [loadParam, setLoadParam] = useState({limit: 20});
    const [isFormChanged, setIsFormChanged] = useState(0);

    async function loadEvents(param) {
        const timeout = setTimeout(()=>{
            setFetching(true);
        }, 500);

        let r = await fetch(`${process.env.REACT_APP_API_URL}/parking/events${param}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache'
            },
            credentials: 'include'
        })
        let data = await r.json();
        clearTimeout(timeout);
        setFetching(false);
        if (data){
            return data;
        }
    }

    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop <= (document.scrollingElement.scrollHeight-1)) return;
        setContinueLoad(true);
    }

    function resetFilters(){
        setFilterParam(Object.fromEntries(Object.entries(filterParam).map((v)=> [v[0], undefined])))
        setIsFormChanged(Math.random());
    }

    function initPage() {
        window.addEventListener('scroll', handleScroll, false);
        document.addEventListener('touchmove', handleScroll, true);
        resetFilters();
        loadBarrierList().then((r)=>{
            setBarrierList(r.barrier);
        });
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
            document.removeEventListener('touchmove', handleScroll, true);
        }
    }

    useEffect(()=>{
        setLoadParam({...appendFilter(filterParam), limit: 20});
    }, [filterParam]);

    useEffect(()=>{
        initPage()
    }, []);

    useEffect(()=>{
        loadEvents(`${getSortParam(loadParam)}`, true).then((r)=>{
            if (isContinueLoad){
                setEventList( [...eventList, ...r]);
                setContinueLoad(false);
            } else {
                setEventList(r);
            }
        })
    }, [loadParam])

    useEffect(()=>{
        if (isContinueLoad){
            if (eventList.length !== 0){
                setLoadParam({...loadParam, id: eventList[eventList.length-1].id})
            } else {
                setLoadParam(loadParam)
            }
        }
    }, [isContinueLoad])

    function handleImageError(e) {
        e.target.onerror = null;
        // e.target.src = "https://via.placeholder.com/320x200?text=%D0%9D%D0%95%D0%A2+%D0%98%D0%97%D0%9E%D0%91%D0%A0%D0%90%D0%96%D0%95%D0%9D%D0%98%D0%AF"
    }

    return (
     <React.Fragment>
         <Menu title='События' reloadData={()=>{
             initPage();
         }}/>
         <CssBaseline />
         <div className={classes.root}>
             <Accordion className={classes.accordion}>
                 <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                 >
                     <Typography className={classes.heading}>Фильтр</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Grid container spacing={1} key={isFormChanged}>
                         <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                             <FormControl fullWidth variant="outlined">
                                 <InputLabel htmlFor="outlined-adornment-password">Номерной знак</InputLabel>
                                 <OutlinedInput
                                     id="outlined-adornment-password"
                                     onChange={(e)=> !isFetching && setFilterParam({...filterParam, number: cyrillicToLatinAutoConvert(e.target.value) })}
                                     value={filterParam.number}
                                     fullWidth
                                     label="Номерной знак"
                                     endAdornment={
                                         <InputAdornment position="end">
                                             <IconButton
                                                 edge="end"
                                             >
                                                 {
                                                     filterParam.fastSearch ?
                                                         <Tooltip title="Включить произвольный поиск. Можно вводить часть номерного знака (занимает больше времени)" placement={"bottom"} interactive leaveDelay={1500} className={classes.activeFactSearch}>
                                                             <TextRotationNoneIcon
                                                                 onClick={()=>{
                                                                     setFilterParam({...filterParam, fastSearch: false })
                                                                 }}
                                                             />
                                                         </Tooltip>
                                                         :
                                                         <Tooltip title="Включить быстрый поиск. Необходимо вводить номерной знак начиная с первого символа" placement={"bottom"} interactive leaveDelay={1500}>
                                                             <TextRotationNoneIcon
                                                                 onClick={()=>{
                                                                     setFilterParam({...filterParam, fastSearch: true })
                                                                 }}
                                                             />
                                                         </Tooltip>
                                                 }

                                             </IconButton>
                                         </InputAdornment>
                                     }
                                 />
                             </FormControl>

                         </Grid>
                         <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                             <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                 <InputLabel id="filterBarrierSelect">Барьер</InputLabel>
                                 <Select
                                     fullWidth
                                     labelId="filterBarrierSelect"
                                     value={filterParam.barrier}
                                     onChange={(e)=> setFilterParam({...filterParam, barrier: e.target.value})}
                                     label="Барьер"
                                     disabled={isFetching}
                                 >
                                     <MenuItem value={undefined}>
                                         <em>Не выбран</em>
                                     </MenuItem>
                                     {barrierList.map((data, i) => (
                                         <MenuItem value={data.id}>{data.name}</MenuItem>
                                     ))}
                                 </Select>
                             </FormControl>
                         </Grid>
                         <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                             <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                 <InputLabel id="filterEventTypeSelect">Тип события</InputLabel>
                                 <Select
                                     fullWidth
                                     labelId="filterEventTypeSelect"
                                     value={filterParam.eventType}
                                     onChange={(e)=> setFilterParam({...filterParam, eventType: e.target.value})}
                                     label="Тип события"
                                     disabled={isFetching}
                                 >
                                     <MenuItem value={undefined}>
                                         <em>Не выбран</em>
                                     </MenuItem>
                                     {
                                         [1,2,3,8,9,91,92].map((data, i) => (
                                             <MenuItem value={data}>{getTypeEvents(data)}</MenuItem>
                                         ))
                                     }
                                 </Select>
                             </FormControl>
                         </Grid>
                         <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                             <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                 <DateTimePicker
                                     fullWidth
                                     className={classes.filterBoxField}
                                     label="Начало периода отчета"
                                     inputVariant="outlined"
                                     ampm={false}
                                     value={filterParam.eventDatetimeBegin ? filterParam.eventDatetimeBegin : null }
                                     onChange={(e)=> setFilterParam({...filterParam, eventDatetimeBegin: e ? e.toDate() : undefined})}
                                     disabled={isFetching}
                                 />
                             </MuiPickersUtilsProvider>
                         </Grid>
                         <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                             <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                 <DateTimePicker
                                     fullWidth
                                     className={classes.filterBoxField}
                                     label="Конец периода отчета"
                                     inputVariant="outlined"
                                     ampm={false}
                                     value={filterParam.eventDatetimeEnd ? filterParam.eventDatetimeEnd : null }
                                     onChange={(e)=> setFilterParam({...filterParam, eventDatetimeEnd: e ? e.toDate() : undefined})}
                                     disabled={isFetching}
                                 />
                             </MuiPickersUtilsProvider>
                         </Grid>
                         <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                             <FormControlLabel
                                 control={
                                     <Checkbox
                                         value={filterParam.reverse}
                                         onChange={(e)=>{
                                             setFilterParam({...filterParam, reverse: (e.target.checked === true) })
                                         }}
                                         color="primary"
                                         disabled={isFetching}
                                     />
                                 }
                                 label="Вывести события в обратном порядке"
                             />
                         </Grid>
                     </Grid>
                 </AccordionDetails>
                 {
                     isFetching ? <LinearProgress />
                         : <Divider />
                 }
                 <AccordionActions>
                     <Button size="small" color="primary" onClick={resetFilters}>Сбросить</Button>
                 </AccordionActions>
             </Accordion>
             {
                 eventList.length > 0 ?
                     <>
                         <Grid container spacing={1}>
                             {eventList.map((data, i) => (
                                 <Grid container item key={'cardi-' + i} xs={12} sm={6} md={4} lg={3} xl={2}>
                                     {/*<Tooltip title="Нажми на меня чтобы узнать детали события">*/}
                                     <Card className={classes.card}>
                                         <CardActionArea style={{ textDecoration: 'none' }} component={Link} to={`/event/${data.id}${getSortParam(loadParam, ['limit', 'id'])}`}>
                                             <CardMedia
                                                 component="img"
                                                 className={classes.media}
                                                 image={"//photo2.parking.vipcrm.ru/thumbnails/" + data.id}
                                                 title={`event-${data.id}`}
                                                 onError={handleImageError}
                                             />
                                             <CardContent>
                                                 <Typography gutterBottom variant="h6" component="h5">
                                                     {showNumber(data, true)}
                                                 </Typography>
                                                 <Typography variant="body2" color="textSecondary" component="p">
                                                     <span>{new Date(data.time).toLocaleString()}</span><br/>
                                                     {data.barrier_name}
                                                 </Typography>
                                             </CardContent>
                                         </CardActionArea>
                                     </Card>
                                     {/*</Tooltip>*/}
                                 </Grid>
                             ))
                             }
                         </Grid>
                         <ScrollToTop/>
                     </>
                     :
                     <Alert severity="warning">
                         <AlertTitle>Нет данных</AlertTitle>
                         Попробуйте изменить значение фильтров
                     </Alert>
             }

         </div>
      </React.Fragment>
    );
}
