import React, {useContext, useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Accordion,
    AccordionActions,
    AccordionDetails, AppBar, Avatar, Card, CardActionArea, CardHeader, createTheme,
    CssBaseline, Link,
    makeStyles,
    TextField, Tooltip
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {addApartmentNumber, loadApartment} from "../httpUtils";
import {appendFilter, checkSettings, getSortParam} from "../utils";
import {Alert} from "@material-ui/lab";
import FullScreenDialog from "../component/FullScreenDialog";
import ApartmentOwnersSettings from "./ApartmentOwners";
import PopupDialog from "../component/PopupDialog";
import ScrollToTop from "../component/ScrollToTop";
import ApartmentLicencePlates from "./ApartmentLicencePlates";
import {cyrillicToLatinAutoConvert} from "../component/LicencePlate/LicencePlateMask";
import {ReactReduxContext} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: 45,
    },
    alert: {
        marginTop: theme.spacing(2),
    }
}));

export default function Apartment(props) {
    const {store} = useContext(ReactReduxContext)
    const classes = useStyles();
    const [filterParam, setFilterParam] = useState({apartmentNumber: '', linkedOwner: '', linkedLicencePlate: ''});
    const [loadParam, setLoadParam] = useState();

    const [isFormChanged, setIsFormChanged] = useState(0);
    const [apartmentList, setApartmentList] = useState([]);

    const [isApartmentManage, setApartmentManage] = useState(false);
    const [managedApartment, setManagedApartment] = useState(0);
    const [isFetching, setIsFetching] = useState(false);

    const [isNeedApartmentCreate, setNeedApartmentCreate] = useState(false);
    const [isApartmentCreating, setApartmentCreating] = useState(false);
    const [createdApartmentNumber, setCreatedApartmentNumber] = useState('');
    const [createdApartmentId, setCreatedApartmentId] = useState(0);
    const [createdApartmentAlertType, setCreatedApartmentAlertType] = useState(-1);
    const [createdApartmentError, setCreatedApartmentError] = useState('');

    const [isUpdateLicencePlatesAvailable, setUpdateLicencePlatesAvailable] = useState(false);
    store.subscribe(() => {
        if (checkSettings(store.getState().settings, 16)){
            setUpdateLicencePlatesAvailable(true)
        }
    })

    function resetFilters(){
        setApartmentList([]);
        setFilterParam(Object.fromEntries(Object.entries(filterParam).map((v)=> [v[0], undefined])))
        setIsFormChanged(Math.random());
    }

    function handleScroll() {
        if (isApartmentManage) return;
        if (window.innerHeight + document.documentElement.scrollTop <= (document.scrollingElement.scrollHeight-1)) return;
        setIsFetching(true);
    }

    function update(e){
        setCreatedApartmentNumber(e.target.value);
    }

    function createApartment() {
        setApartmentCreating(true);
        addApartmentNumber(createdApartmentNumber).then((r)=>{
            setCreatedApartmentAlertType(0);
            setCreatedApartmentId(r.apartmentId);
            setApartmentCreating(false);
            loadApartmentList();
        }).catch((e)=>{
            if (e.code == 409){
                setCreatedApartmentAlertType(1);
                setCreatedApartmentId(e.apartmentId);
            } else {
                setCreatedApartmentAlertType(2);
                setCreatedApartmentError(e.code || e.message);
            }
            setApartmentCreating(false);
        })
    }

    useEffect(()=>{
        if (loadParam){
            loadApartment(`${getSortParam(loadParam)}`, true).then((r)=>{
                if (isFetching){
                    setIsFetching(false);
                    setApartmentList( [...apartmentList, ...r]);
                } else {
                    setApartmentList(r);
                }
            })
        }
    }, [loadParam])

    function loadApartmentList(){
        setApartmentList([]);
        setLoadParam({...appendFilter(filterParam), limit: 200});
    }

    useEffect(()=>{
        loadApartmentList();
    }, [filterParam]);

    useEffect(()=>{
        if (isFetching){
            if (apartmentList.length > 0){
                setLoadParam({...loadParam, offsetApartmentNumber: apartmentList[apartmentList.length-1].apartmentNumber})
            } else {
                setLoadParam({...loadParam})
            }
        }
    }, [isFetching])

    function initPage() {
        resetFilters();
        window.addEventListener('scroll', handleScroll, false);
        document.addEventListener('touchmove', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
            document.removeEventListener('touchmove', handleScroll, true);
        }
    }

    useEffect(()=>{
        initPage();
    }, []);

    useEffect(()=>{
        setCreatedApartmentId(0);
        setCreatedApartmentAlertType(-1);
    }, [createdApartmentNumber])

    function closeNewApartmentDialog(){
        setNeedApartmentCreate(false);
        setCreatedApartmentNumber('');
        setCreatedApartmentAlertType(-1);
        setCreatedApartmentError('');
        setCreatedApartmentId(0);
    }

    // throw new Error('asdsad')
    return (
        <React.Fragment>
            <Menu title='Квартиры' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Фильтр</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} key={`filter-${isFormChanged}`}>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, apartmentNumber: e.target.value})}
                                    value={filterParam.apartmentNumber}
                                    fullWidth
                                    label="Номер квартиры" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, linkedLicencePlate: cyrillicToLatinAutoConvert(e.target.value) })}
                                    value={filterParam.linkedLicencePlate}
                                    fullWidth
                                    label="Связанный номерной знак" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, linkedOwner: e.target.value})}
                                    value={filterParam.linkedOwner}
                                    fullWidth
                                    label="Связанный владелец" variant="outlined" />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button size="small" color="primary" onClick={resetFilters}>
                            Сбросить
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Button variant="contained" color="primary" className={classes.accordion} onClick={()=>{
                    setNeedApartmentCreate(true);
                }}>
                    Добавить квартиру
                </Button>
                <Grid container spacing={1}>
                    { apartmentList.map((data, i) => (
                        <Grid container item xs={6} sm={3} md={2} lg={2} xl={1}>
                            {/*<Tooltip title="Нажми на меня и я покажу окно редактирования" interactive>*/}
                                <Card className={classes.card}>
                                    <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                        // if (data.mock) return;
                                        setManagedApartment(data);
                                        setApartmentManage(true);
                                    }}>
                                            <CardHeader
                                                avatar={
                                                    // data.mock ?
                                                    //     <Skeleton animation="wave" variant="rect" width={45} height={45} />
                                                    //     :
                                                        <Avatar variant="rounded" aria-label="recipe" className={classes.avatar}>
                                                            {data.apartmentNumber}
                                                        </Avatar>
                                                }
                                                subheader={
                                                    // data.mock ?
                                                    //     <React.Fragment>
                                                    //         <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                                                    //         <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                                                    //     </React.Fragment>
                                                    //     :
                                                        <React.Fragment>
                                                            <Typography>Номера: {data.linkedLicencePlatesTotal}</Typography>
                                                            <Typography>Жители: {data.linkedOwnersTotal}</Typography>
                                                        </React.Fragment>
                                                }
                                            />
                                    </CardActionArea>
                                </Card>
                            {/*</Tooltip>*/}
                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            <ScrollToTop/>
            <FullScreenDialog open={isApartmentManage} onClose={()=>{
                setManagedApartment({});
                setApartmentManage(false);
            }} title={`Квартира: ${managedApartment.apartmentNumber}`}>
                <Grid container spacing={0}>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <ApartmentLicencePlates apartmentId={managedApartment.apartmentId} isUpdateLicencePlatesAvailable={isUpdateLicencePlatesAvailable}/>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <ApartmentOwnersSettings apartmentId={managedApartment.apartmentId} />
                    </Grid>
                </Grid>
            </FullScreenDialog>

            <PopupDialog open={isNeedApartmentCreate} onClose={closeNewApartmentDialog} title={"Добавление квартиры"} actionBoard={
                <React.Fragment>
                    {
                        createdApartmentId === 0 ?
                            <>
                                <Button onClick={closeNewApartmentDialog} color="default">
                                    Отмена
                                </Button>
                                <Button onClick={createApartment} color="primary" disabled={isApartmentCreating}>
                                    Ок
                                </Button>
                            </>
                            :
                            <Button autoFocus onClick={closeNewApartmentDialog} color="default">
                                Закрыть окно
                            </Button>
                    }
                </React.Fragment>
            }>
                <TextField
                    onChange={update}
                    aria-invalid={!createdApartmentNumber}
                    value={createdApartmentNumber}
                    // onChange={(e)=>{setCreatedApartmentNumber(e.target.value)}}
                    fullWidth
                    autoFocus
                    label="Номер квартиры" variant="outlined" />
                {
                    createdApartmentAlertType !== -1 &&
                    <Alert variant="outlined" severity={createdApartmentAlertType ? "error": "success"} className={classes.alert}>
                        {
                            createdApartmentAlertType == 1 ?
                                <>Квартира с номером <strong>{createdApartmentNumber}</strong> уже была создана.</>
                                : createdApartmentAlertType == 2 && <>Внутрення ошибка сервера {createdApartmentError}</>
                        }
                        {
                            createdApartmentAlertType !== 2 &&
                            <Link onClick={()=>{
                                closeNewApartmentDialog();
                                setManagedApartment({apartmentNumber: createdApartmentNumber, apartmentId: createdApartmentId});
                                setApartmentManage(true);
                            }} color="inherit">
                                <strong>Нажмите здесь для перехода к редактированию квартиры {createdApartmentNumber}.</strong>
                            </Link>
                        }
                    </Alert>
                }
            </PopupDialog>
        </React.Fragment>
    )

}
