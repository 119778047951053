import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'

export default class CameraAreaSettings extends Component {
 constructor() {
   super();
   this.state = {
     coord: [],
     edit: -1,
     image: null
   }
   this.loadArea = this.loadArea.bind(this);

 }

 addMouseEvents() {
     this.refs.canvas.addEventListener('mousedown', this.onMouseDown, false);
     // this.refs.canvas.addEventListener('ontouchstart', this.onMouseDown, false);
     this.refs.canvas.addEventListener('mousemove', this.onMouseMove, false);
     // this.refs.canvas.addEventListener('ontouchmove', this.onMouseMove, false);
     this.refs.canvas.addEventListener('mouseup', this.onMouseUp, false);
     // this.refs.canvas.addEventListener('ontouchend', this.onMouseUp, false);
   }

 removeMouseEvents() {
   this.refs.canvas.removeEventListener('mousedown', this.onMouseDown, false);
   this.refs.canvas.removeEventListener('mousemove', this.onMouseMove, false);
   this.refs.canvas.removeEventListener('mouseup', this.onMouseUp, false);
 }

 getCanvasCoord(e){
   var rect = this.refs.canvas.getBoundingClientRect();
   return [((e.clientX - rect.left) / rect.width) * this.refs.canvas.width, ((e.clientY - rect.top) / rect.height) * this.refs.canvas.height];
 }

 getCanvasCoordAbsolute(item){
   console.log(item);
   var x = 0
   var y = 0
   if (item[0] != 0) {
     x = parseFloat(this.refs.canvas.width)*item[0];
   }
   if (item[1] != 0) {
     y = parseFloat(this.refs.canvas.height)*item[1];
   }
   return [x,y]
 }

 draw(){
   const canvas = this.refs.canvas;

   const context = canvas.getContext('2d');
   context.drawImage(this.state.image, 0, 0, canvas.width, canvas.height);
   context.fillStyle = 'rgba(255,0,0,1)'
   context.strokeStyle = 'rgba(0,0,255,1)'
   // context.fillRect(10, 10, 20, 20)

   context.beginPath();
   context.moveTo(this.getCanvasCoordAbsolute(this.state.coord[0])[0],this.getCanvasCoordAbsolute(this.state.coord[0])[1]);
   this.state.coord.forEach((item,index) => {
     var c = this.getCanvasCoordAbsolute(item);

     context.lineTo(c[0],c[1]);
  })
  context.lineTo(this.getCanvasCoordAbsolute(this.state.coord[0])[0],this.getCanvasCoordAbsolute(this.state.coord[0])[1])
  context.stroke();

   this.state.coord.forEach((item,index) => {
     context.beginPath();
     var c = this.getCanvasCoordAbsolute(item);

    context.arc(c[0],c[1], 3, 0, 2 * Math.PI);
    context.fill();
    context.stroke();
    })
 }

 onMouseDown = (e) => {
   console.log(this.getCanvasCoord(e));
   this.state.coord.forEach((item,index) => {
     var c = this.getCanvasCoordAbsolute(item);
     var l = this.getCanvasCoord(e);
     if (l[0] <= c[0]+5 && l[0] >= c[0]-5 && l[1] <= c[1]+5 && l[1] >= c[1]-5 ){
       this.setState({ edit : index })
     }
   })

 };

 onMouseMove = (e) => {
   if (this.state.edit != -1) {
     var l = this.getCanvasCoord(e);
     this.state.coord[this.state.edit][0]=l[0]/this.refs.canvas.width
     this.state.coord[this.state.edit][1]=l[1]/this.refs.canvas.height
     this.props.areaEdited(this.state.coord);
     this.draw();
   }
 };

 onMouseUp = (e) => {
   console.log("up");
   this.setState({ edit : -1 })
 };

 componentDidMount() {
   fetch(process.env.REACT_APP_API_URL+'/parking/camera/realtime/'+this.props.camera,{
     method: 'POST',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })

   this.state.image = new Image();
   this.state.image.src = process.env.REACT_APP_API_URL+'/parking/camera/realtime/'+this.props.camera;
   this.state.image.onload = () => {
     this.loadArea(this.props.camera)
     this.addMouseEvents()
   }
   this.state.image.onerror = () => {
     if (this.state.image) {
       setTimeout(function (image,camera) {
         image.src = process.env.REACT_APP_API_URL+'/parking/camera/realtime/'+camera;
       }, 1000, this.state.image, this.props.camera);
     }
   }
   console.log(this.state.image.complete);
 }

 componentWillUnmount(){
   delete this.state.image;
 }

 loadArea(id){
   fetch(process.env.REACT_APP_API_URL+'/parking/camera/area/'+id,{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => {
     let c = [];
     res.area.forEach(function (r) {
        c.push([r.X, r.Y]);
     })
     console.log(c);
     this.setState({coord: c});
     this.props.areaEdited(this.state.coord);
     this.draw();
   });
 }

 render() {
   return (
     <>
       <canvas ref='canvas' style={{width:'100%', display: this.state.image && this.state.image.complete ? 'block' : 'none'}}/>
       <center><Spinner animation="grow" variant="primary" style={{ display: this.state.image && this.state.image.complete ? 'none' : 'block'}}/></center>
     </>
   )
 }
}
