import React, {useEffect, useState} from 'react';
import {
    bindKeycodeToLicencePlate, changeLicencePlate,
    changeLicencePlateArea,
    loadArea, loadKeytags,
    loadLicencePlateAreaInfo, loadLicencePlateKeytags,
} from "../httpUtils";
import {
    Avatar,
    Checkbox,
    FormControlLabel, FormGroup, ListItem, ListItemAvatar, ListItemText, makeStyles,
    TextField, Tooltip
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Button from "@material-ui/core/Button";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import KeycodeCreateDialog from "./KeycodeCreateDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    inputForm: {
        marginBottom: theme.spacing(2),
    },
    container: {
        display: 'flex'
    }
}));

export default function LicencePlateKeycode(props) {
    const classes = useStyles();
    const filter = createFilterOptions();

    const [linkedKeycodes, setLinkedKeycodes] = useState([]);
    const [availableKeycodes, setAvailableKeycodes] = useState([]);

    const [addNewKeycodeId, setAddNewKeycodeId] = useState(0);

    const [isNeedKeycodeCreate, setNeedKeycodeCreate] = useState(false);
    const [licencePlate, setLicencePlate] = useState('');

    const _load = (licencePlate) => {
        loadLicencePlateKeytags(licencePlate).then((r)=>{
            setLinkedKeycodes(r);
        }).catch((e)=>{

        })
        loadKeytags({onlyUnbindedOfLicencePlate: true}).then((r)=>{
            setAvailableKeycodes(r);
        }).catch((e)=>{

        })
    }

    const _linkKeycodeToLicencePlate = (licencePlate, keycodeId) => {
        bindKeycodeToLicencePlate(keycodeId, licencePlate).then((r)=>{
            console.log(r)
            _load(licencePlate);
        }).catch((e)=>{

        })
    }

    useEffect(()=>{
        // setNumber(props.licencePlate);
        setLicencePlate(props.licencePlate)
        _load(props.licencePlate)
    }, [props.licencePlate])


    return(
        <React.Fragment>
            <div className={classes.root}>
                <List>
                    {
                        linkedKeycodes.map((al, i) => (
                            <ListItem style={{paddingLeft: 0, paddingRight: 0}}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <LocalOfferIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${al.id}`}
                                    primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" color={'secondary'} style={{paddingLeft: 0, paddingRight: 0}}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>

                <div className={classes.container}>
                    <Autocomplete
                        options={availableKeycodes}
                        loading={false}
                        getOptionSelected={(option, value)=>{
                            return option.id === addNewKeycodeId
                        }}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.hasOwnProperty('text')) {
                                setAddNewKeycodeId(0);
                                setNeedKeycodeCreate(true);
                                return;
                            }
                            if (newValue && newValue.hasOwnProperty('id')) {
                                setAddNewKeycodeId(newValue.id);
                            } else {
                                setAddNewKeycodeId(0);
                            }
                        }}
                        getOptionLabel={(option) => {
                            if (option.text) return;
                            return option.id.toString();
                        }}
                        renderOption={(option) => `${option.text ? option.text : option.id}`}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // if (params.inputValue !== '') {
                                filtered.push({
                                    text: `Добавить новый ключ / метку`,
                                });
                            // }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        fullWidth
                        margin="normal"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="Выберите ключ / метку" variant="outlined"
                                       // onChange={(e)=>{setAutocompleteLicencePlate(e.target.value.toUpperCase())}}
                            />
                        )}
                    />
                    <Button variant="contained" color="primary" style={{marginLeft: 8}} onClick={()=>{
                        // bindLinkedApartmentLicencePlate(selectedLicencePlate)
                    }} disabled={addNewKeycodeId === 0}>
                        Привязать
                    </Button>
                </div>

                {/*<Button color="default" variant={'outlined'} fullWidth>*/}
                {/*    Привязать новый пульт/метку*/}
                {/*</Button>*/}
            </div>
            <KeycodeCreateDialog isNeedLicencePlateCreate={isNeedKeycodeCreate} isNeedClose={()=>{setNeedKeycodeCreate(false)}} isNeedOpenEditLicencePlate={(lp)=>{}} isNeedReload={()=>{}} createdKeycodeId={(keycodeId)=>{_linkKeycodeToLicencePlate(licencePlate, keycodeId)}}/>
        </React.Fragment>
    )
}
