import React, {useContext, useEffect, useState} from 'react';
import {
    FormControl, InputLabel, Link,
    makeStyles, MenuItem, Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {loadAreasList,loadUnlivedInfo, loadUnlivedPrefixes, updateUnlived } from "../httpUtils";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },
    licencePlatesList: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    }
}));

export default function UnlivedInfo(props) {
    const classes = useStyles();

    const [unlivedId, setUnlivedId] = useState(0);

    const [unlivedPrefixesList, setUnlivedPrefixesList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [objectType, setObjectType] = useState(0);
    const [objectTypeChanged, setObjectTypeChanged] = useState(0);

    const [objectArea, setObjectArea] = useState(0);
    const [objectAreaChanged, setObjectAreaChanged] = useState(0);

    const [objectNumber, setObjectNumber] = useState(0);
    const [objectNumberChanged, setObjectNumberChanged] = useState(0);

    const [objectChar, setObjectChar] = useState('');
    const [objectCharChanged, setObjectCharChanged] = useState('');

    const [objectOneTimeLimitation, setObjectOneTimeLimitation] = useState(0);
    const [objectOneTimeLimitationChanged, setObjectOneTimeLimitationChanged] = useState(0);

    const [isChanged, setChanged] = useState(false);
    const [isChanging, setChanging] = useState(false);

    const [createdUnlivedAlertType, setCreatedUnlivedAlertType] = useState(-1);
    const [createdExistId, setCreatedExistId] = useState(-1);
    const [updateUnlivedError, setUpdateUnlivedError] = useState('');

    useEffect(()=>{
        setCreatedUnlivedAlertType(-1);
        setUpdateUnlivedError('');
        if (
            objectTypeChanged !== objectType ||
            objectAreaChanged !== objectArea ||
            objectNumberChanged !== objectNumber ||
            objectCharChanged !== objectChar ||
            objectOneTimeLimitationChanged !== objectOneTimeLimitation
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [objectTypeChanged, objectAreaChanged, objectNumberChanged, objectCharChanged, objectOneTimeLimitationChanged])

    useEffect(()=>{
        loadUnlivedPrefixes().then((r)=>{
            setUnlivedPrefixesList(r)
        })
        loadAreasList().then((r)=>{
            setAreaList(r)
        })
    },[]);

    useEffect(()=>{
        setUnlivedId(props.unlivedId);
        if (props.unlivedId){
            loadInfo(props.unlivedId)
        }
    }, [props.unlivedId])

    function loadInfo(id){
        setChanged(false);
        setChanging(false);
        setCreatedUnlivedAlertType(-1);
        setUpdateUnlivedError('');
        loadUnlivedInfo(id).then((r)=>{
            setObjectType(r.prefix);
            setObjectTypeChanged(r.prefix);

            setObjectArea(r.area);
            setObjectAreaChanged(r.area);

            setObjectNumber(r.number);
            setObjectNumberChanged(r.number);

            setObjectChar(r.char);
            setObjectCharChanged(r.char);

            setObjectOneTimeLimitation(r.oneTimeLicencePlatesLimit);
            setObjectOneTimeLimitationChanged(r.oneTimeLicencePlatesLimit);
        })
        .catch((e)=>{

        })
    }

    function update(){
        setChanging(true);
        updateUnlived(unlivedId, objectTypeChanged, objectAreaChanged, objectNumberChanged, objectCharChanged, objectOneTimeLimitationChanged).then((r)=>{
            loadInfo(unlivedId)
            setChanging(false);
        }).catch((e)=>{
            if (e.code === 409){
                setCreatedUnlivedAlertType(1);
            } else {
                setCreatedUnlivedAlertType(2);
                setUpdateUnlivedError(e.message);
            }
            setChanging(false);
        })
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel id="createObjectTypeSelect">Тип объекта</InputLabel>
                            <Select
                                disabled={isChanging}
                                fullWidth
                                labelId="createObjectTypeSelect"
                                value={objectTypeChanged}
                                onChange={(e)=> setObjectTypeChanged(e.target.value)}
                                label="Тип объекта"
                            >
                                <MenuItem value={0}>
                                    <em>Не выбран</em>
                                </MenuItem>
                                {
                                    unlivedPrefixesList.map((data, i) => (
                                        <MenuItem value={data.id}>{data.description}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel id="createObjectAreaSelect">Местоположение объекта</InputLabel>
                            <Select
                                disabled={isChanging}
                                fullWidth
                                labelId="createObjectAreaSelect"
                                value={objectAreaChanged}
                                onChange={(e)=> setObjectAreaChanged(e.target.value)}
                                label="Местоположение объекта"
                            >
                                <MenuItem value={0}>
                                    <em>Не выбран</em>
                                </MenuItem>
                                {
                                    areaList.map((data, i) => (
                                        <MenuItem value={data.id}>{data.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            disabled={isChanging}
                            value={objectNumberChanged}
                            onChange={(e)=>{setObjectNumberChanged(e.target.value)}}
                            fullWidth
                            type='number'
                            label="Номер объекта" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ display: 'flex', verticalAlign: 'middle'}}>
                            {
                                objectCharChanged !== undefined &&
                                <TextField
                                    style={{marginRight: 10}}
                                    disabled={isChanging}
                                    label="Символ"
                                    variant="outlined"
                                    value={objectCharChanged}
                                    onChange={(e)=>{setObjectCharChanged(e.target.value.length <= 1 ? e.target.value: objectCharChanged)}}
                                />
                            }
                            <FormControlLabel
                                style={{marginBottom: 0}}
                                control={
                                    <Checkbox
                                        disabled={isChanging}
                                        checked={objectCharChanged !== undefined}
                                        onChange={(e)=>{
                                            setObjectCharChanged(e.target.checked ? '' : undefined)
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Символ в номере объекта"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ display: 'flex', verticalAlign: 'middle'}}>
                            {
                                objectOneTimeLimitationChanged !== undefined &&
                                <TextField
                                    style={{marginRight: 10}}
                                    disabled={isChanging}
                                    label="Лимит"
                                    variant="outlined"
                                    value={objectOneTimeLimitationChanged}
                                    onChange={(e)=>{setObjectOneTimeLimitationChanged(e.target.value.length <= 1 ? e.target.value: objectOneTimeLimitationChanged)}}
                                />
                            }
                            <FormControlLabel
                                style={{marginBottom: 0}}
                                control={
                                    <Checkbox
                                        disabled={isChanging}
                                        checked={objectOneTimeLimitationChanged !== undefined}
                                        onChange={(e)=>{
                                            setObjectOneTimeLimitationChanged(e.target.checked ? '' : undefined)
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Ограничение одновременной парковки"
                            />
                        </div>
                    </Grid>


                </Grid>
                {
                    isChanged && ( createdUnlivedAlertType === -1 || createdUnlivedAlertType === 2 ) &&
                    <Button size="medium" color="primary" variant="outlined" style={{marginTop: 10}} onClick={update} disabled={isChanging}>
                        Сохранить
                    </Button>
                }
                {
                    createdUnlivedAlertType !== -1 &&
                    <Alert variant="outlined" severity="error" style={{marginTop: 10}}>
                        {
                            createdUnlivedAlertType === 1 ?
                                <>Объект <strong>{`${objectNumberChanged}${objectCharChanged ? `/${objectCharChanged}`: ``}`}</strong> уже был создан.</>
                                : createdUnlivedAlertType === 2 && <>Внутрення ошибка сервера {updateUnlivedError}</>
                        }
                        {
                            createdUnlivedAlertType !== 2 &&
                            <Link onClick={()=>{
                                // closeNewObjectDialog();
                                // setManagedUnlived({id: createObjectId, number: objectNumber, char: objectChar, prefix: unlivedPrefixesList.find((v)=>v.id===createObjectType).prefix});
                                // setNeedObjectManage(true);
                            }} color="inherit">
                                <strong> Нажмите здесь для перехода к редактированию объекта {`${objectNumberChanged}${objectCharChanged ? `/${objectCharChanged}`: ``}`}.</strong>
                            </Link>
                        }
                    </Alert>
                }
            </div>
        </React.Fragment>
    )
}
