import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Menu from "./Header/Menu";
import Container from "@material-ui/core/Container";
import {CssBaseline, TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ScrollToTop from "./component/ScrollToTop";

export default class LicencePlatesWithoutOwners extends Component {
    constructor() {
        super();
        this.state = {
            list: [],
        }

        this.handleLoad = this.handleLoad.bind(this);
    }

    handleLoad(){
        fetch(process.env.REACT_APP_API_URL+'/reports/lpwow',{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => this.setState({list: res}));
    }

    componentDidMount() {
        this.handleLoad()
    }

    drawNumbers(numbers){
        return numbers.join(', ')
    }


    render() {
        return (
            <>
                <Menu title='Номера без контактных данных'/>
                <CssBaseline />
                <TableContainer component={Paper}>
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Номерной знак</TableCell>
                                <TableCell>Квартира</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) => (
                                <TableRow key={row.licencePlate}>
                                    <TableCell component="th" scope="row">
                                        {row.licencePlate}
                                    </TableCell>
                                    <TableCell>{row.apartmentNumber}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ScrollToTop/>
            </>
        );
    }
}
