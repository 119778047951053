import * as actionTypes from "./actionTypes";

const initialState = {
    theme: localStorage.getItem('theme') ||  window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light',
    settings: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_THEME:
            localStorage.setItem('theme', action.theme)
            return {
                ...state,
                theme: action.theme
            }
        case actionTypes.CHANGE_USER_SETTINGS:
            return {
                ...state,
                settings: action.settings
            }
    }
    return state
}

export default reducer
