import React, {useEffect, useState} from 'react';
import {InputAdornment, makeStyles, TextField, Tooltip} from "@material-ui/core";
import PhoneNumberMask from "./PhoneNumberMask";
import PhoneIcon from '@material-ui/icons/Phone';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
}));

export default function PhoneNumberInput(props) {
    const classes = useStyles();
    const [createdPhoneNumber, setCreatedPhoneNumber] = useState('');

    useEffect(()=>{
        props.onChange(createdPhoneNumber)
    }, [createdPhoneNumber]);

    useEffect(()=>{
        setCreatedPhoneNumber(props.value)
    }, [props.value])

    return (
        <React.Fragment>
            <div className={classes.box}>
                <TextField
                    autoFocus={props.autofocus}
                    fullWidth
                    label={props.label}
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberMask,
                        value: createdPhoneNumber,
                        onChange: (e, m)=>{
                            setCreatedPhoneNumber(e);
                        },
                        disabled: props.disabled,
                        autoFocus: props.autofocus
                    }}
                />
                {
                    props.hasOwnProperty('withCallActionButton') &&
                    <Tooltip title="Совершить звонок по указанному номеру" placement={"left-end"} interactive>
                        <a href={`tel:+${createdPhoneNumber}`}>
                            <IconButton aria-label="close" edge="end" color="primary">
                                <PhoneIcon />
                            </IconButton>
                        </a>
                    </Tooltip>
                }

            </div>

        </React.Fragment>
    )
}
