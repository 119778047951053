import {useEffect, useRef, useState} from "react";

export default function CanvasAreaEditor(props) {
    const canvasRef = useRef(null)

    const [tolerance] = useState(20);

    const [image, setImage] = useState(undefined);
    const [points, setPoints] = useState([]);

    const [editablePointId, setEditablePointId] = useState(-1);
    const [editableMoveTo, setEditableMoveTo] = useState(undefined);

    const [mouseDownTo, setMouseDownTo] = useState(undefined);
    const [mouseUpTo, setMouseUpTo] = useState(undefined);

    const [newPointMode, setNewPointMode] = useState(false);
    const [delPointMode, setDelPointMode] = useState(false);

    useEffect(()=>{
        setNewPointMode(props.newPointMode)
    }, [props.newPointMode])

    useEffect(()=>{
        setDelPointMode(props.delPointMode)
    }, [props.delPointMode])

    const isPointOnLineWithTolerance = (x1, y1, x2, y2, x, y, tolerance) => {
        const distance = Math.abs((y2 - y1) * x - (x2 - x1) * y + x2 * y1 - y2 * x1) / Math.sqrt((y2 - y1)**2 + (x2 - x1)**2);
        return distance <= tolerance;
    }

    const findIntersectionPoint = (linePoint1, linePoint2, externalPoint) => {
        // Находим угловой коэффициент прямой
        let m = (linePoint2.Y - linePoint1.Y) / (linePoint2.X - linePoint1.X);

        // Находим угловой коэффициент перпендикуляра
        let perpendicularM = -1 / m;

        // Находим константы для уравнения прямой и перпендикуляра
        let lineC = linePoint1.Y - m * linePoint1.X;
        let perpendicularC = externalPoint.Y - perpendicularM * externalPoint.X;

        // Решаем систему уравнений для нахождения точки пересечения
        let intersectionX = (perpendicularC - lineC) / (m - perpendicularM);
        let intersectionY = m * intersectionX + lineC;

        return {X: intersectionX, Y: intersectionY};
    }

    useEffect(()=>{
        if (mouseDownTo){
            const canvas = canvasRef.current
            for (const [pointIndex, point] of points.entries()) {
                const c = getCanvasCordAbsolute(canvas, point);
                if (mouseDownTo.X <= c.X+tolerance && mouseDownTo.X >= c.X-tolerance && mouseDownTo.Y <= c.Y+tolerance && mouseDownTo.Y >= c.Y-tolerance ){
                    setEditablePointId(pointIndex);
                }
            }
            setMouseDownTo(undefined)
        }
    }, [mouseDownTo])

    useEffect(()=>{
        if (mouseUpTo){
            if (newPointMode) {
                const canvas = canvasRef.current
                const clickPoint = {X: mouseUpTo.X/canvas.width, Y: mouseUpTo.Y/canvas.height};

                const localPoints = [...points];
                for (let i = 0; i < localPoints.length; i++) {
                    let current = localPoints[i];
                    let next = localPoints[i < (localPoints.length-1) ? i+1: (localPoints.length -1) -i];
                    if (isPointOnLineWithTolerance(current.X,current.Y, next.X, next.Y, clickPoint.X, clickPoint.Y, 0.05)) {
                        const intersectionPoint = findIntersectionPoint(current, next, clickPoint);
                        const newPoints = [...localPoints.slice(0, i+1), intersectionPoint, ...localPoints.slice(i+1)]
                        setPoints(newPoints)
                        props.setPoints(newPoints)
                    }
                }
            }
            if (editablePointId >= 0) {
                if (delPointMode) {
                    const newPoints = [...points.slice(0, editablePointId), ...points.slice(editablePointId+1)]
                    setPoints(newPoints)
                    setEditablePointId(-1);
                    props.setPoints(newPoints)
                } else {
                    setEditablePointId(-1);
                    props.setPoints(points)
                }
            }
            setMouseUpTo(undefined)
        }
    }, [mouseUpTo, newPointMode, editablePointId])

    useEffect(()=>{
        if (props.image){
            setImage(props.image)
        }
    }, [props.image])

    useEffect(()=>{
        if (props.points){
            setPoints(props.points)
        }
    }, [props.points])

    useEffect(() => {
        if (image) {
            const canvas = canvasRef.current
            canvas.style.backgroundImage = `url(${image})`
            canvas.style.backgroundSize = 'cover'
            canvas.style.width = '100%'

            canvas.addEventListener('touchstart', (e)=> {
                e.preventDefault();
                setMouseDownTo(getCanvasCord(canvas, e.targetTouches[0]))
            }, false);
            canvas.addEventListener('touchmove', (e)=>{
                e.preventDefault();
                setEditableMoveTo(getCanvasCord(canvas, e.targetTouches[0]))
            }, false);
            canvas.addEventListener('touchend', (e)=>{
                setMouseUpTo(getCanvasCord(canvas, e.changedTouches[0]))
            }, false);
            canvas.addEventListener('mousedown', (e)=> {
                setMouseDownTo(getCanvasCord(canvas, e))
            }, false);
            canvas.addEventListener('mousemove', (e)=>{
                setEditableMoveTo(getCanvasCord(canvas, e))
            }, false);
            canvas.addEventListener('mouseup', (e)=>{
                setMouseUpTo(getCanvasCord(canvas, e))
            }, false);
        }
    }, [image])

    useEffect(()=>{
        if (editablePointId >= 0 && editableMoveTo) {
            const canvas = canvasRef.current
            const localPoints = [...points];
            const currentPoint = localPoints[editablePointId]
            const x = editableMoveTo.X / canvas.width;
            const y = editableMoveTo.Y / canvas.height;
            if (x < 0 || x > 1) return;
            if (y < 0 || y > 1) return;
            currentPoint.X = x;
            currentPoint.Y = y;
            setPoints(localPoints)
        }
    }, [editableMoveTo, editablePointId])

    const getCanvasCord = (canvas, e) => {
        const rect = canvas.getBoundingClientRect();
        return {X: ((e.clientX - rect.left) / rect.width) * canvas.width, Y: ((e.clientY - rect.top) / rect.height) * canvas.height};
    }

    const getCanvasCordAbsolute = (canvas, item) => {
        let x = 0
        let y = 0
        if (item.X !== undefined) {
            x = parseFloat(canvas.width)*item.X;
        }
        if (item.Y !== undefined) {
            y = parseFloat(canvas.height)*item.Y;
        }
        return {X:x,Y:y}
    }

    useEffect(()=>{
        if (points.length > 0) {
            const canvas = canvasRef.current
            const context = canvas.getContext('2d');
            context.canvas.width  = 1280;
            context.canvas.height = 720;

            context.clearRect(0, 0, canvas.width, canvas.height);

            const localPoints = [...points];
            const firstPoint = localPoints[0];

            context.fillStyle = 'rgba(0,255,0, 0.25)'
            context.beginPath();
            context.moveTo(getCanvasCordAbsolute(canvas, firstPoint).X, getCanvasCordAbsolute(canvas, firstPoint).Y);
            for (const point of points) {
                const c = getCanvasCordAbsolute(canvas, point);
                context.lineTo(c.X,c.Y);
            }
            context.moveTo(getCanvasCordAbsolute(canvas, firstPoint).X, getCanvasCordAbsolute(canvas, firstPoint).Y);
            context.fill();

            context.strokeStyle = 'rgba(0,255,0,1)'
            context.lineWidth = 5
            for (const point of points) {
                context.beginPath();
                const c = getCanvasCordAbsolute(canvas, point);
                context.moveTo(Math.round(c.X-tolerance/2), c.Y)
                context.lineTo(Math.round(c.X+tolerance/2), c.Y);
                context.moveTo(c.X, Math.round(c.Y-tolerance/2))
                context.lineTo(c.X, Math.round(c.Y+tolerance/2));
                context.stroke();
            }
        }
    }, [points])

    return <canvas ref={canvasRef}/>
}