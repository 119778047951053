import React, { useEffect, useState } from 'react';
import Menu from "../Header/Menu";
import {
    AppBar,
    Box,
    CssBaseline,
    makeStyles, Tab, Tabs,
} from "@material-ui/core";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import ReportSalesServicesGrouped from "./Reports/ReportSalesServicesGrouped";
import ReportContractsGrouped from "./Reports/ReportContractsGrouped";
import ReportCashBox from "./Reports/ReportCashBox";
import ReportServicesRealized from "./Reports/ReportServicesRealized";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh",
    },
    container: {
        display: "flex",
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    switchBody: {
        backgroundColor: theme.palette.background.paper,
    }
}));

function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1} className={classes.switchBody}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function RentAccountantReports() {
    const classes = useStyles();
    const [begin, setBegin] = useState(undefined);
    const [end, setEnd] = useState(undefined);

    const [list, setList] = useState([]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function initPage() {
        // getLogs().then((r)=>{
        //     setList(r)
        // })
    }

    useEffect(()=>{
        initPage();
    }, [])

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Menu title='Отчеты по аренде' reloadData={()=>{
                    initPage();
                }}/>
                <CssBaseline />
                <div className={classes.container}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                <DateTimePicker
                                    fullWidth
                                    label="Начало периода отчета"
                                    inputVariant="outlined"
                                    ampm={false}
                                    value={begin ? begin : null }
                                    onChange={(e)=> setBegin(e ? e.toDate() : undefined)}

                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                <DateTimePicker
                                    fullWidth
                                    label="Конец периода отчета"
                                    inputVariant="outlined"
                                    ampm={false}
                                    value={end ? end : null }
                                    onChange={(e)=> setEnd(e ? e.toDate() : undefined)}

                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        {
                            end !== undefined && begin !== undefined ?
                                end < begin &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Alert severity="error">Время окончания меньше времени начала</Alert>
                                    </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Alert severity="info">Для просмотра отчетов выберите период</Alert>
                                </Grid>
                        }

                    </Grid>
                    {
                        end !== undefined && begin !== undefined && end > begin &&
                        <div className={classes.mt1}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="Отчет по кассе" id={0} />
                                    <Tab label="Отчет по реализации" id={1} />
                                    <Tab label="Бух отчет по услугам" id={2} />
                                    <Tab label="Бух отчет по договорам" id={3} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <ReportCashBox begin={begin} end={end}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ReportServicesRealized begin={begin} end={end}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <ReportSalesServicesGrouped begin={begin} end={end}/>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <ReportContractsGrouped begin={begin} end={end}/>
                            </TabPanel>

                        </div>
                    }
                </div>

            </div>
        </React.Fragment>
    )
}
