import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import PopupDialog from "../component/PopupDialog";
import Grid from "@material-ui/core/Grid";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography, withStyles
} from "@material-ui/core";
import {
    loadAvailableAreasList,
    loadBarrierExtendedData,
    loadCamerasNew, updateCameraConfig
} from "../httpUtils";
import EditCameraArea from "./EditCameraArea";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CustomAccordion = withStyles(({ palette, spacing }) => ({
    root: {
        marginBottom: spacing(1),
        "&$expanded": {
            margin: 0,
            marginBottom: spacing(1),
        }
    },
    expanded: {}
}))(Accordion);

export default function EditCameraDialog(props) {
    const [cameraId, setCameraId] = useState(0);

    const [correctForm, setCorrectForm] = useState(false);

    const [functionality, setFunctionality] = useState(0);

    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [processingSource, setProcessingSource] = useState('');
    const [processingFramerate, setProcessingFramerate] = useState(0);
    const [mountingHeight, setMountingHeight] = useState(0.0);
    const [directionAngle, setDirectionAngle] = useState(0.0);
    const [fieldOfView, setFieldOfView] = useState(0.0);
    const [focalLength, setFocalLength] = useState(0.0);
    const [watchArea, setWatchArea] = useState(Infinity);
    const [controlledBarrier, setControlledBarrier] = useState(0);
    const [workAreaCoordinates, setWorkAreaCoordinates] = useState([]);

    const [enabledInit, setEnabledInit] = useState(true);
    const [nameInit, setNameInit] = useState('');
    const [processingSourceInit, setProcessingSourceInit] = useState('');
    const [processingFramerateInit, setProcessingFramerateInit] = useState(0);
    const [mountingHeightInit, setMountingHeightInit] = useState(0.0);
    const [directionAngleInit, setDirectionAngleInit] = useState(0.0);
    const [fieldOfViewInit, setFieldOfViewInit] = useState(0.0);
    const [focalLengthInit, setFocalLengthInit] = useState(0.0);
    const [watchAreaInit, setWatchAreaInit] = useState(Infinity);
    const [controlledBarrierInit, setControlledBarrierInit] = useState(0);
    const [workAreaCoordinatesInit, setWorkAreaCoordinatesInit] = useState([]);

    const [availableWatchAreas, setAvailableWatchAreas] = useState([]);
    const [availableAreas, setAvailableAreas] = useState([]);
    const [availableBarriers, setAvailableBarriers] = useState([]);

    const saveChanges = (_cameraId, _name, _processingSource, _processingFramerate, _mountingHeight, _directionAngle, _fieldOfView, _focalLength, _watchArea, _controlledBarrier, _workAreaCoordinates) => {
        updateCameraConfig({
            cameraId: _cameraId,
            name: _name,
            processingSource: _processingSource,
            processingFramerate: _processingFramerate,
            mountingParameters: {
                mountingHeight: _mountingHeight,
                directionAngle: _directionAngle,
            },
            cameraParameters: {
                fieldOfView: _fieldOfView,
                focalLength: _focalLength,
            },
            watchArea: _watchArea,
            controlledBarrier: _controlledBarrier,
            workAreaCoordinates: _workAreaCoordinates
        }).then((res)=>{
            console.log(res)
            if (!_cameraId) props.needClose()
            props.needReload()
        }).catch((err)=>{
            console.log(err)
        })
    }

    const loadInitialData = () => {
        loadAvailableAreasList().then((r)=>{
            setAvailableAreas(r)
        })
        loadBarrierExtendedData().then((r)=>{
            setAvailableBarriers(r)
        })
    }

    useEffect(()=>{
        loadInitialData()
    }, [])

    useEffect(()=>{
        if (cameraId > 0){
            loadCamerasNew(cameraId).then((r)=>{
                setName(r.name)
                setNameInit(r.name)
                setProcessingSource(r.processingSource)
                setProcessingSourceInit(r.processingSource)
                setProcessingFramerate(r.processingFramerate|| 0.0)
                setProcessingFramerateInit(r.processingFramerate|| 0.0)
                setMountingHeight(r.mountingParameters.mountingHeight || 0.0)
                setMountingHeightInit(r.mountingParameters.mountingHeight || 0.0)
                setDirectionAngle(r.mountingParameters.directionAngle|| 0.0)
                setDirectionAngleInit(r.mountingParameters.directionAngle|| 0.0)
                setFieldOfView(r.cameraParameters.fieldOfView|| 0.0)
                setFieldOfViewInit(r.cameraParameters.fieldOfView|| 0.0)
                setFocalLength(r.cameraParameters.focalLength|| 0.0)
                setFocalLengthInit(r.cameraParameters.focalLength|| 0.0)

                setWorkAreaCoordinates(r.workAreaCoordinates || [])

                setFunctionality(r.controlledBarrier ? 1 : 2)

                setWatchArea(r.watchArea)
                setWatchAreaInit(r.watchArea)
                setControlledBarrier(r.controlledBarrier)
                setControlledBarrierInit(r.controlledBarrier)
            })
        }
    }, [cameraId])

    useEffect(()=>{
        setCameraId(props.cameraId)
    }, [props.cameraId])

    useEffect(()=>{
        // setWatchArea(Infinity);
        switch (functionality) {
            case 1:
                const barrier = availableBarriers.find((v)=>v.id===controlledBarrier)
                if (!barrier) break;
                setAvailableWatchAreas(availableAreas.filter((v)=>(v.id===barrier.from_area || v.id===barrier.to_area)))
                break;
            case 2:
                setAvailableWatchAreas(availableAreas)
                break;
        }
    }, [functionality, controlledBarrier, availableAreas, availableBarriers])

    useEffect(()=>{
        setCorrectForm(false)
        if (
            nameInit === name &&
            processingSourceInit === processingSource &&
            processingFramerateInit === processingFramerate &&
            mountingHeightInit === mountingHeight &&
            directionAngleInit === directionAngle &&
            fieldOfViewInit === fieldOfView &&
            focalLengthInit === focalLength &&
            watchAreaInit === watchArea &&
            controlledBarrierInit === controlledBarrier
        ) return;
        if (functionality === 1 && controlledBarrier === 0) return;
        // if (functionality === 2 && controlledBarrier === 0) return;
        if (functionality === 0 || !name || !processingSource || watchArea === Infinity) return;
        setCorrectForm(true)
    }, [functionality, name, processingSource, watchArea, controlledBarrier])

    const closeDialog = () => {
      props.hasOwnProperty('needClose') && props.needClose();
    }

    return (
        <PopupDialog open={true} onClose={closeDialog} title={"Настройка камеры"} actionBoard={
            <React.Fragment>
                <>
                    <Button onClick={closeDialog} color="default">
                        Отмена
                    </Button>
                    <Button onClick={saveChanges.bind(null, cameraId, name, processingSource, processingFramerate, mountingHeight, directionAngle, fieldOfView, focalLength, watchArea, controlledBarrier, workAreaCoordinates)} color="primary" disabled={!correctForm}>
                        Сохранить
                    </Button>
                </>
            </React.Fragment>
        }>
            <CustomAccordion >
                <AccordionSummary
                    disableGutters
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Параметры</Typography>
                </AccordionSummary>
                <AccordionDetails disableGutters>
                    <Grid container spacing={1}>
                        {/*<Grid item lg={12} md={12} sm={12} xl={12} xs={12}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        style={{marginBottom: 0}}*/}
                        {/*        control={*/}
                        {/*            <Checkbox*/}
                        {/*                checked={enabled}*/}
                        {/*                onChange={(e)=>{*/}
                        {/*                    setEnabled(e.target.checked)*/}
                        {/*                }}*/}
                        {/*                color="primary"*/}
                        {/*            />*/}
                        {/*        }*/}
                        {/*        label="Активно"*/}
                        {/*    />*/}
                        {/*</Grid>*/}

                        <Grid item lg={3} md={3} sm={3} xl={6} xs={6}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="mountingHeight"
                                variant="outlined"
                                type='number'
                                value={mountingHeight}
                                onChange={(e)=>{setMountingHeight(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={3} md={3} sm={3} xl={6} xs={6}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="directionAngle"
                                variant="outlined"
                                type='number'
                                value={directionAngle}
                                onChange={(e)=>{setDirectionAngle(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={3} md={3} sm={3} xl={6} xs={6}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="fieldOfView"
                                variant="outlined"
                                type='number'
                                value={fieldOfView}
                                onChange={(e)=>{setFieldOfView(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={3} md={3} sm={3} xl={6} xs={6}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="focalLength"
                                variant="outlined"
                                type='number'
                                value={focalLength}
                                onChange={(e)=>{setFocalLength(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xl={12} xs={12}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="Наименование"
                                variant="outlined"
                                value={name}
                                onChange={(e)=>{setName(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xl={10} xs={9}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="Источник"
                                variant="outlined"
                                value={processingSource}
                                onChange={(e)=>{setProcessingSource(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={2} md={2} sm={2} xl={2} xs={3}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label="FPS"
                                variant="outlined"
                                type='number'
                                value={processingFramerate}
                                onChange={(e)=>{setProcessingFramerate(e.target.value)}}
                            />
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="camera-functionality">Функция</InputLabel>
                                <Select
                                    labelId="camera-functionality"
                                    value={functionality}
                                    onChange={(e)=>{
                                        setWatchArea(Infinity);
                                        setControlledBarrier(0);
                                        setFunctionality(e.target.value)
                                    }}
                                    fullWidth
                                    label="Функция"
                                >
                                    <MenuItem value={0}>Не выбрана</MenuItem>
                                    <MenuItem value={1}>Распознавание номеров</MenuItem>
                                    <MenuItem value={2}>Контроль скорости</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        { functionality !== 0 &&
                            <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="watch-area">Наблюдает за</InputLabel>
                                    <Select
                                        labelId="watch-area"
                                        value={watchArea}
                                        onChange={(e)=>{setWatchArea(e.target.value)}}
                                        fullWidth
                                        label="Наблюдает за"
                                        disabled={functionality === 1 && controlledBarrier === 0}
                                    >
                                        <MenuItem value={Infinity}>Не выбран</MenuItem>
                                        {
                                            availableWatchAreas.map((v, i)=>(
                                                <MenuItem key={`slwa-${i}`} value={v.id}>{v.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {
                            functionality === 1 &&
                            <Grid item lg={6} md={6} sm={12} xl={12} xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="controlled-barrier">Баръер</InputLabel>
                                    <Select
                                        labelId="controlled-barrier"
                                        value={controlledBarrier}
                                        onChange={(e)=>{
                                            setWatchArea(Infinity);
                                            setControlledBarrier(e.target.value)
                                        }}
                                        fullWidth
                                        label="Баръер"
                                    >
                                        <MenuItem value={0}>Не выбран</MenuItem>
                                        {
                                            availableBarriers.map((v, i)=>(
                                                <MenuItem key={`slbr-${i}`} value={v.id}>{v.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </CustomAccordion>
            <Grid>
                {
                    cameraId > 0 &&
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <EditCameraArea cameraId={cameraId} areaCoordinates={workAreaCoordinates} areaCoordinatesUpdate={(v)=>setWorkAreaCoordinates(v)}/>
                    </Grid>
                }
            </Grid>
        </PopupDialog>
    )
}
