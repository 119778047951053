import React, { useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {Accordion, AccordionDetails, Link, ListItem, ListItemText, makeStyles, TextField} from "@material-ui/core";
import {openBarrier} from "../httpUtils";
import Grid from "@material-ui/core/Grid";
import LicencePlateInput from "../component/LicencePlate/LicencePlateInput";
import PhoneNumberInput from "../component/PhoneNumber/PhoneNumberInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import LicencePlateAllowedArea from "../LicencePlates/LicencePlateAllowedArea";
import {Alert} from "@material-ui/lab";
import PopupDialog from "../component/PopupDialog";

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 105
    },
    keyColor: {
        color: theme.palette.success.dark
    }
}));

export default function BarrierListItem(props){
    const classes = useStyles();
    const [barrierInfo, setBarrierInfo] = useState({});
    const [barrierOpen, setBarrierOpen] = useState(undefined);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isConcierge, setConcierge] = useState(false);
    const [isConciergeConfirmOpen, setConciergeConfirmOpen] = useState(false);
    const [isConciergeConfirmationNeeded, setConciergeConfirmationNeeded] = useState(false);
    const [conciergeComment, setConciergeComment] = useState('');

    const [currentKey, setCurrentKey] = useState(undefined);
    const [pressedKey, setPressedKey] = useState(undefined);
    const [isKeyOpenAllow, setKeyOpenAllow] = useState(false);

    const closeConciergeConfirmationDialog = function () {
        setBarrierOpen(undefined)
        setConciergeConfirmOpen(false)
        setConciergeConfirmationNeeded(false)
        setConciergeComment('')
    }

    const openBarrierUi = function (barrierId, isConcierge, conciergeComment) {
        setIsProcessing(true);
        openBarrier(barrierId, isConcierge, conciergeComment).then((r)=>{
            setTimeout(()=>setIsProcessing(false), 1000)
        }).catch((e)=>{
            setTimeout(()=>setIsProcessing(false), 1000)
            window.location.reload();
        })

    }

    useEffect(()=>{
        if (isConciergeConfirmOpen){
            openBarrierUi(barrierOpen, isConcierge, conciergeComment)
            closeConciergeConfirmationDialog()
        }
    }, [isConciergeConfirmOpen])

    useEffect(()=>{
        if (barrierOpen) {
            if (isConcierge){
                setConciergeConfirmationNeeded(true);
            } else {
                openBarrierUi(barrierOpen, undefined, undefined)
                setBarrierOpen(undefined)
            }
        }
    }, [barrierOpen])

    useEffect(()=>{
        if (pressedKey) {
            if (isKeyOpenAllow && currentKey === pressedKey && !isProcessing){
                setBarrierOpen(barrierInfo.id)
            }
            setPressedKey(undefined)
        }
    }, [pressedKey])

    function handleKey(e){
        setPressedKey(e.key)
    }

    useEffect(()=>{
        setConcierge(props.isConcierge);
        setBarrierInfo(props.info);
        setCurrentKey(props.info.bindKey)
        setKeyOpenAllow(props.isKeyOpenAllow)
        if (props.info.hasOwnProperty('bindKey') && props.info.bindKey !== null && currentKey === undefined){
            document.addEventListener("keydown", handleKey, false);
            // document.addEventListener("keydown", handleKeyEvent(props.info.bindKey, props.isKeyOpenAllow, props.info.id, props.info.name), false);
            return () => {
                document.removeEventListener("keydown", handleKey, false);
                // document.removeEventListener("keydown", handleKeyEvent(props.info.bindKey, props.isKeyOpenAllow, props.info.id, props.info.name), false);
            }
        }
    }, [props]);

    useEffect(()=>{
        !currentKey && setCurrentKey(props.currentKey)
    }, [props.currentKey])

    useEffect(()=>{
        !isKeyOpenAllow && setKeyOpenAllow(props.isKeyOpenAllow)
    }, [props.isKeyOpenAllow])

    return (
        <ListItem button>
            <ListItemText primary={`${barrierInfo.name}` } secondary={
                isKeyOpenAllow && currentKey &&
                <>
                    Открытие по кнопке
                    ( <b className={classes.keyColor}> {currentKey} </b> )
                    на клавиатуре
                </>
            }/>
            <Button size="medium" variant='contained' color={isProcessing ? "default" : "primary"}  disabled={isProcessing} className={classes.button} onClick={()=>{setBarrierOpen(barrierInfo.id)}}>
                Открыть
            </Button>
            <PopupDialog open={isConciergeConfirmationNeeded} onClose={closeConciergeConfirmationDialog} title={`Открыть "${barrierInfo.name}"`} actionBoard={
                <React.Fragment>
                    <Button onClick={closeConciergeConfirmationDialog} color="default">
                        Отмена
                    </Button>
                    <Button onClick={()=>{setConciergeConfirmOpen(true)}} color="primary" disabled={conciergeComment.length === 0}>
                        Ок
                    </Button>
                </React.Fragment>
            }>
                <TextField
                    value={conciergeComment}
                    fullWidth
                    label="Цель открытия"
                    variant="outlined"
                    autoFocus
                    onChange={(e)=>{setConciergeComment(e.target.value)}}
                />
            </PopupDialog>
        </ListItem>

    )
}

