import React, { useEffect, useState } from 'react';
import {
    Box,
    Collapse,
    makeStyles, Paper, TableCell,
} from "@material-ui/core";
import {loadRentAccountantReportContractsGrouped, loadRentAccountantReportSalesServicesGrouped} from "../../httpUtils";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "react-bootstrap/Table";
import TableBody from "@material-ui/core/TableBody";
import {penniesToRubles} from "../../utils";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh",
    },
    container: {
        display: "flex",
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    switchBody: {
        backgroundColor: theme.palette.background.paper,
    }
}));

function ReportTableCollapseCell(props){
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow key={`tr-${props.row.contractId}`}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">Договор № <b>{props.row.contractId}</b> от <b>{new Date(props.row.contractBegin).toLocaleString()}</b> по <b>{new Date(props.row.contractEnd).toLocaleString()}, {props.row.tariffObjectName}, {props.row.contractLicencePlate}</b></TableCell>
                <TableCell align="right">{penniesToRubles(props.row.amount)}</TableCell>
            </TableRow>
            <TableRow key={`tsr-${props.row.contractId}`}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Услуга</TableCell>
                                        <TableCell align="center">Кол-во</TableCell>
                                        <TableCell align="center">Итого</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.row.items.map((row) => (
                                        <TableRow key={row.itemId}>
                                            <TableCell>{row.itemDescription}</TableCell>
                                            <TableCell  align="center">{row.itemQuantity}</TableCell>
                                            <TableCell  align="center">{penniesToRubles(row.billItemCost)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default function ReportContractsGrouped(props) {
    const classes = useStyles();
    const [report, setReport] = useState(undefined);

    const loadReport = (begin, end) => {
        loadRentAccountantReportContractsGrouped(begin, end).then((r)=>{
            setReport(r)
        }).catch((e)=>{

        })
    };

    useEffect(()=>{
        if (props.begin && props.end){
            loadReport(new Date(props.begin).toUTCString(), new Date(props.end).toUTCString());
        }
    }, [props.begin, props.end])

    return (
        <React.Fragment>
            {
                report &&
                <TableContainer component={Paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center">Договор</TableCell>
                                <TableCell align="right">Итого</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {report.rows.map((row) => (
                                <ReportTableCollapseCell row={row}/>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2} align="right"><b>Итого</b></TableCell>
                                <TableCell align="right"><b>{penniesToRubles(report.totalAmount)}</b></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </React.Fragment>
    )
}
