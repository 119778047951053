export {
    Rect,
    Symb,
    syStr,
    genStr,
    regr,
    tanimotoD,
    tanimoto,
    merger_and,
    dist,
    merger_or,
    merger_middle,
    findeS,
    log
}

// http: //cr.parking.vipcrm.ru:3000/event/5ec210ec876e434f5c0dd866

function log() {
    global.it = global.it || 0
    global.it++
        // console.log(global.it, Array.from(arguments))
        // if (global.it == 930) debugger
}

const _mrg = {
    cmp: 0,
    relative: 2
}

const _place = {
    P1: 1,
    P2: 1,
    P3: 0.7,
    len: 1.2,
    fitD: 3.5,
    prsnt: 80,
    kX: 1.5
}
const _st = {
        cnt: 1.3,
        P: 1.10,
        cnt2: 0.9
    }
    // коэффициент похожести строк
function tanimoto(s1, s2) {
    let c = 0
    for (let i = 0; i < s1.length; i++) {
        for (let j = 0; j < s2.length; j++) {
            if (s1[i] == s2[j]) {
                c++
            }
        }
    }
    let a = s1.length,
        b = s2.length
    return c / (a + b - c)

}
//геометрическая подгонка строк, выравнивание по МНК (линейная функция нелинейного растяжения-сжатия)

function tanimotoD(s1, s2) {
    //любые одинаковые символы
    log('in->', s1.numT, s2.numT, cP(s1.lpR), cP(s1[0].R.x1), cP(s2.lpR), cP(s2[s2.length - 1].R.x1))
    let li = []
    for (let i = 0; i < s1.length; i++) {
        for (let j = 0; j < s2.length; j++) {
            if (s1.S[i] == s2.S[j]) {
                li.push({ s1: s1[i], s2: s2[j], i: i, j: j, })
            }
        }
    }
    let l = groupBy(li, 'i')
        // оставляем только непротиворечивые пересечения (не повторяющиеся символы)
    l = l.filter((a) => (a.length == 1)).map((a) => a[0])
    fitD(s1, s2, l)
        // сравнение выровненных строк
    li.forEach((a) => {
        a.r = ((1 - Math.abs(a.s1.R.xc - a.s2.R.xc) / s2.lpR.w) * 100)

    })

    let ch = true
    log('li', li.map((a) => {
            let b = cP(a)
            b.s1 = b.s1.S
            b.s2 = b.s2.S
            return b
        }))
        //выбираем попавшие на свои места символы из групп одинаковых
    l = groupBy(li, 'i')
    li = l.map((a) => a.sort((b, c) => c.r - b.r)[0])
    l = groupBy(li, 'j')
    li = l.map((a) => a.sort((b, c) => c.r - b.r)[0])
        //оставляем только допустимо (prsnt) совпавшие
    li = li.filter((a) => a.r > _place.prsnt)
    log('li', li.map((a) => {
        let b = cP(a)
        b.s1 = b.s1.S
        b.s2 = b.s2.S
        return b
    }))
    log('out->', s1.numT, s2.numT, cP(s1.lpR), cP(s1[0].R.x1), cP(s2.lpR), cP(s2[s2.length - 1].R.x1))
    if (li.length == 0) return [0]
        //повторное выравнивание по всем одинаковым и стоящим на своих местах символам
    return fitD(s1, s2, li)
}

//вычисление коэффициента регресии
function regr(points) {
    let n = points.length
    if (n < 2) return 0
    let sX = 0,
        sY = 0,
        sXY = 0,
        sXX = 0
    points.forEach((p) => {
        sXY += p.x * p.y;
        sX += p.x;
        sY += p.y;
        sXX += p.x * p.x
    })
    return (sY / n - sXY / sX) / (sX / n - sXX / sX)
}
//вычислние уравнения регресии первого порядка
function regr2(points) {
    let n = points.length
    if (n < 2) return 0
    let sX = 0,
        sY = 0,
        sXY = 0,
        sXX = 0
    points.forEach((p) => {
        sXY += p.x * p.y;
        sX += p.x;
        sY += p.y;
        sXX += p.x * p.x
    })
    let a = (sY / n - sXY / sX) / (sX / n - sXX / sX)
    let b = (sY - a * sX) / n
    return { a: a, b: b }
}
//выравнивание по списку совпадающих в строках символов
function fitD(s1, s2, li, stretchX = true) {
    let h1 = s1.reduce((a, it) => a + it.R.h, 0) / s1.length
    let h2 = s2.reduce((a, it) => a + it.R.h, 0) / s2.length
    let dy = s1.reduce((a, it) => a + it.R.y1, 0) / s1.length -
        s2.reduce((a, it) => a + it.R.y1, 0) / s2.length

    let x1 = s1.lpR.x1
    let x2 = s2.lpR.x1
    let w1 = s1.lpR.w
    let w2 = s2.lpR.w
    let xMinMax = s2.reduce((a, it) => {
            if (a.min > it.R.x1) a.min = it.R.x1
            if (a.max < it.R.x2) a.max = it.R.x2
            return a
        }, { min: 1e10, max: -1e10 })
        //проверка размеров номерной рамки на "вместимость" символов номера
    if (xMinMax.min < x2 || xMinMax.max > (x2 + w2)) {
        console.log('check!!', s2.numT)
        x2 = xMinMax.min
        w2 = xMinMax.max - xMinMax.min
        s2.lpR.x1 = x2
        s2.lpR.w = w2
    }
    let cMax = -1e10

    let dLi = []
    let dx,
        maxSy = 0
        //линейныая фиункция взаимного выравнивания агрумент - координата во второй строке - функция - координата в первой строке 
    const fx = (x, re) => (re.a * ((x - x2) / w2 - dx) + re.b) * w1 + x1

    let mW = s1.reduce((a, it) => a + it.R.w, 0) / s1.length
    for (let i = 0; i < li.length; i++) {
        let dx0 = (li[i].s2.R.xc - x2) / w2 - (li[i].s1.R.xc - x1) / w1
        let delta = 0
        for (let j = 0; j < li.length; j++) {
            let d = Math.abs((li[j].s2.R.xc - x2) / w2 - (li[j].s1.R.xc - x1) / w1 - dx0)
                //  подсчет совпадений с учетом взаимных расстояний 
            delta += (d < mW / w1) ? 1 - d : 0
        }
        log(s1.numT, s2.numT, '(' + i + ')', li[i].s1.S, delta, mW / w1)
        if (maxSy < delta) {
            maxSy = delta
            dx = dx0
        }
    }
    // составление уравнения регрессии 
    li.forEach((a, i) => {
        let d = Math.abs((a.s2.R.xc - x2) / w2 - (a.s1.R.xc - x1) / w1 - dx)
        log('(' + i + ')', a.s1.S, d, mW / w1)
        if (d / _place.fitD < mW / w1) dLi.push({
            x: (a.s2.R.xc - x2) / w2 - dx,
            y: (a.s1.R.xc - x1) / w1
        })

    })
    log('List   ', dLi)

    //если разрешено - масштабируем 
    if (dLi.length > 1 && stretchX) {
        let re = regr2(dLi)

        if (!isNaN((re.a + re.b) / 2)) {

            s2.forEach((a) => {
                let tmpX = a.R.x2
                a.R.x1 = fx(a.R.x1, re)
                a.R.x2 = fx(tmpX, re)
                a.R.y1 += dy
                a.R.h *= (h1 / h2)
            })

            let tmpX = s2.lpR.x2
            s2.lpR.x1 = fx(s2.lpR.x1, re)
            s2.lpR.x2 = fx(tmpX, re)
        }

    }
    li.forEach((a) => {
            a.r = ((1 - Math.abs(a.s1.R.xc - a.s2.R.xc) / s2.lpR.w) * 100)

        })
        //коэффициент сравнениния строк общий + посимвольный список совпавших символов 
    cMax = li.reduce((a, it) => a + it.r, 0)

    return [cMax, li]
}




function cP(a) {
    return JSON.parse(JSON.stringify(a))
}
class Rect extends Array {
    static get[Symbol.species]() { return Array; }
    constructor(r) {
        super()
        if (r.hasOwnProperty('x1') || r.hasOwnProperty('x')) {
            for (var key in r) this[key] = r[key]
        } else r.forEach((a, i) => this[i] = a)
    }
    get x1() { return this[0] }
    get x2() { return this[0] + this[2] }
    get y1() { return this[1] }
    get y2() { return this[1] + this[3] }
    set x1(a) { this[0] = a }
    set x2(a) { this[2] = a - this[0] }
    set y1(a) { this[1] = a }
    set y2(a) { this[3] = a - this[1] }
    get x() { return this[0] }
    get y() { return this[1] }
    get xc() { return this[0] + this[2] / 2 }
    get yc() { return this[1] + this[3] / 2 }
    get w() { return this[2] }
    get h() { return this[3] }
    set x(a) { this[0] = a }
    set y(a) { this[1] = a }
    set w(a) { this[2] = a }
    set h(a) { this[3] = a }
    area() {
        return this.w * this.h
    }
    center() {
        return { x: this.x + this.w / 2, y: this.y + this.h / 2 }
    }
}

const dist = (R1, R2) => {
        let c1 = R1.center()
        let c2 = R2.center()
        let x = c1.x - c2.x
        let y = c1.y - c2.y
        let res = { r: Math.sqrt(x * x + y * y), x: R1.xc - R2.xc, y: R1.yc - R2.yc }
        return res
    }
    // слияние прямоугольников по логическому Или
const merger_or = (r1, r2) => {
        let res = {}
        res.x1 = Math.min(r1.x1, r2.x1)
        res.y1 = Math.min(r1.y1, r2.y1)
        res.x2 = Math.max(r1.x2, r2.x2)
        res.y2 = Math.max(r1.y2, r2.y2)
        return new Rect(res)
    }
    // слияние прямоугольников по логическому И
const merger_and = (r1, r2) => {
        let res = {}
        res.x1 = Math.max(r1.x1, r2.x1)
        res.y1 = Math.max(r1.y1, r2.y1)
        res.x2 = Math.min(r1.x2, r2.x2)
        res.y2 = Math.min(r1.y2, r2.y2)
        if (res.x1 > res.x2) res.x1 = res.x2
        if (res.y1 > res.y2) res.y2 = res.y2
        return new Rect(res)
    }
    // слияние прямоугольников в усредненный
const merger_middle = (r1, r2) => {
    return new Rect({ x: (r1.x + r2.x) / 2, y: (r1.y + r2.y) / 2, w: (r1.w + r2.w) / 2, h: (r1.h + r2.h) / 2 })
}


const findeS = (S, R, first = 0) => {
    if (S.length == 0) return
    var res = S[0]
    res.i = 0
    var l = dist(S[0].R, R)
    for (let i = first; i < S.length; i++) {
        let it = S[i]
        let l1 = dist(it.R, R)
        if (l.r >= l1.r) {
            l = l1
            res = it
            res.i = i
        }
        res.l = l
        res.place = l.r / it.R.w
    }
    let i = -1
    res.i2 = -1
    res.place2 = 1e10
    if (res.l.x > 0 && res.i > 0) i = res.i - 1
    if (res.l.x < 0 && res.i < S.length - 1) i = res.i + 1
    if (i >= 0) {
        let l = dist(S[i].R, R)
        res.l2 = l
        res.i2 = i
        res.place2 = res.l2.r / S[i].R.w
    }
    return res
}

class Symb {
    constructor(it) {
        this.S = it.S
            //координаты символа
        this.R = new Rect(it.R)
            //вероятность
        this.P = it.P
        this.n = it.n
    }
}
class syStr extends Array {
    static get[Symbol.species]() { return Array; }
    constructor(it) {
        super()
        it.forEach((a) => {
                this.push(new Symb(a))
            })
            //время события
        this.t = it.t
            //координаты номерного знака
        this.lpR = new Rect(it.lpR)
    }
    get num() {
        let s = ''
        this.forEach((a) => { s += a.S })
        return s
    }
    get numT() {
        return this.num + ' t=' + this.t
    }
    get rLi() { return this.map((a => a.R)) }
    get S() { return this.map((a => a.S)) }
}

//групировка списка по ключевому полю 
function groupBy(li, key) {
    let o = li.reduce((acc, a) => {
        acc[a[key]] = acc[a[key]] || []
        acc[a[key]].push(a)
        return acc
    }, {})
    let res = []
    for (key in o) res.push(o[key])
    return res
}

//символ в строке слияния всех строк
class sP {
    constructor(S) {
        this.li = []
        this.S = S
    }
    set S(S) {
        this.R = this.R || S.R
        this.R = merger_middle(this.R, S.R)
        this.li.push(S)
    }
    get S() {
        if (this.li.length == 0) return []
        let sLi = groupBy(this.li, 'S')
        sLi.sort((a, b) => b.length - a.length)
        sLi.forEach((a) => {
            a.sort((c, d) => d.P - c.P)
        })
        let res
        if (sLi.length == 1) {
            res = sLi[0][0]
        } else {
            let k = sLi[0].length / sLi[1].length
                //критерии - количество символов, вероятность
            if (k > _st.cnt || sLi[0][0].P / sLi[1][0].P > _st.P ||
                (k > _st.cnt2 && sLi[0][0].P > sLi[1][0].P)) {
                res = sLi[0][0]
            } else {
                res = sLi[1][0]
            }
        }
        // res.R = this.R
        return res
    }
}
//строка слияния
class genStr extends Array {
    static get[Symbol.species]() { return Array; }
    constructor() {
        super()
    }
    get S() {
        let ret = this.map((a) => a.S)
        ret.lpR = this.lpR
        return ret
    }
    get num() {
            return this.S.reduce((a, x) => { return a + x.S }, '')
        }
        //ставка символа в строку слияния
    pushS(it) {
            let s1 = this.S,
                i = 0
            let s = findeS(s1, it.R, i)
                // console.log(s1, it, s)
            if (typeof s === 'undefined') {
                this.splice(0, 0, new sP(it))
                return
            }
            i = s.i
            if (s.place < _place.P1) {
                i = s.i

                if (s.place2 < _place.P2) {
                    if ((s1[i].S != it.S) && (s1[s.i2].S == it.S)) {
                        i = s.i2
                    }
                }
                this[i].S = new Symb(it)
            } else {
                if (s.l.x >= 0) this.splice(i, 0, new sP(it))
                if (s.l.x < 0) this.splice(i + 1, 0, new sP(it))
            }
        }
        //добавление строки к строке слияния по порогу cmp
    addS(S, cmp) {
        this.lpR = S.lpR
        let S0 = new syStr(this.S)
        if (this.S.length > 0) {
            let t = tanimotoD(S0, S)
            let li = t[1]

            if (cmp / t[0] > _mrg.relative) return

            if (typeof(li) != 'undefined') {
                if (li.length > 0) {
                    S.forEach((it) => {
                        this.pushS(it)
                    })
                }
            }
        } else {
            S.forEach((it) => {
                this.pushS(it)
            })
        }

        //проверка на задвоение символов наиболее неустойчивое место в алгоритме, 
        //НЕОБХОДИМЫЕ УЛУЧШЕНИЯ:
        // качество слияния зависит от пороговых  коэффициентов и других критериев слияния
        // требуется постфильтрация полученных результатов, на основе критериев качества сливаемой строки
        // например, появление нового символа или объединение радом стоящих должно взвешиваться по критерию достоверности 
        //(cmp) очередной строки
        let i = 1
        while (i < this.length) {
            let a = this[i - 1],
                b = this[i]
            let place = dist(a.S.R, b.S.R).r / (a.S.R.w + b.S.R.w) * 2
            let iI, iD
            if (a.li.length > b.li.length) {
                iI = i - 1
                iD = i
            } else {
                iI = i
                iD = i - 1
            }
            let aD = this[iD],
                aI = this[iI]
            if ((place < _place.P3 && a.S.S == b.S.S &&
                    (aI.li.length / aD.li.length >= _place.len)) ||
                (place < _place.P3 && aD.li.length < 3 && aI.li.length > 1)) {
                aD.li.forEach((a) => {
                    aI.S = a
                })
                this.splice(iD, 1)
                continue
            }
            i++
        }
        log(this.map((a) => a.S))
    }
}




// function getProp(it, prop) {
//     let pLi = prop.split('.')
//     let z = it
//     pLi.forEach((a) => z = z[a])
//     return z
// }

// function average(li, prop = '') {
//     let l
//     if (prop != '') {
//         l = li.map((a) => getProp(a, prop))
//     } else {
//         l = li.map((a) => a)
//     }
//     return l.reduce((a, it) => a + it, 0) / li.length
// }

// function fit(s1, s2, li) {
//     let ky = average(s1, 'R.h') / average(s2, 'R.h')
//     let i = li[0].i,
//         j = li[0].j
//     let dx = s1[i].R.xc - s2[j].R.xc
//     let x0 = s2[j].R.xc
//     let dy = average(s1, 'R.y1') - average(s2, 'R.y1')
//     let points = li.map((a) => {
//         let delta = s1[a.i].R.xc - s2[a.j].R.xc - dx
//         return { x: s2[a.j].R.xc - x0, y: delta }
//     })
//     let k = regr(points),
//         kx
//     const delta = (x) => dx + k * (x - x0)
//     kx = (s2.lpR.x2 - x0 + delta(s2.lpR.x2) - dx) / (s2.lpR.x2 - x0)

//     s2.forEach((a) => {
//         a.R.x1 += delta(a.R.x1)
//         a.R.w *= kx
//         a.R.y1 += dy
//         a.R.h *= ky
//     })
//     s2.lpR.x1 += dx;
//     s2.lpR.y1 += dy;
//     s2.lpR.w *= kx;
//     s2.lpR.h *= ky
//     return 100 * li.reduce((a, it) => a + (1 - Math.abs(s1[it.i].R.xc - s2[it.j].R.xc) / s2.lpR.w), 0)
// }

// function selectIJ(S0, S1, t = 0) {
//     const xor = (a, b) => (a || b) && !(a && b)
//     let not = { i1: -1, i2: -1, j1: -1, j2: -1 }
//     let cmp = subStr(S0, S1)
//     if (cmp.length == 0) return not
//         // максимальное количество совпавших символов
//     cmp.sort((a, b) => {
//         return (-(a.i2 - a.i1) + (b.i2 - b.i1))
//     })
//     let i1 = cmp[0].i1,
//         i2 = cmp[0].i2,
//         j1 = cmp[0].j1,
//         j2 = cmp[0].j2
//         // средняя ширина одной символьной позиции

//     S0.mWn = i2 - i1
//     S0.mW = (S0[i2].R.xc - S0[i1].R.xc) / S0.mWn
//     S1.mWn = j2 - j1
//     S1.mW = (S1[j2].R.xc - S1[j1].R.xc) / S1.mWn
//     cmp.sort((a, b) => {
//             return (a.j1 - b.j1)
//         })
//         // cmp.sort((a,b)=>((-(a.j2-a.j1)+(b.j2-b.j1))*1e10+(-a.j1+b.j1))) 

//     if (cmp.length == 1) {
//         let p = cmp[0]
//         p.fit = true
//         return p
//     }
//     let nL = []
//     for (let i = 0; i < cmp.length; i++) {
//         let p2 = cmp[i]
//         let ok = false
//         for (let j = 0; j < nL.length; j++) {
//             let p1 = nL[j]
//             if (!xor(p1.i2 <= p2.i2, p1.j2 <= p2.j2)) {
//                 let p = {
//                         i1: Math.min(p1.i1, p2.i1),
//                         i2: Math.max(p1.i2, p2.i2),
//                         j1: Math.min(p1.j1, p2.j2),
//                         j2: Math.max(p1.j2, p2.j2)
//                     }
//                     //проверка на геометрическую совместимость
//                 let dx0 = (S0[p.i2].R.xc - S0[p.i1].R.xc) / S0.mW
//                 let dx1 = (S1[p.j2].R.xc - S1[p.j1].R.xc) / S1.mW
//                     // если ошибка менее чем в 2 символа
//                 if (Math.abs(dx0 - dx1) < 2) {
//                     ok = true
//                     nL[j] = p
//                 } else {
//                     // console.log('IJ error ', S0.numT, S1.numT, dx0, dx1, p)
//                     // console.log('S0.mW, S0.mWn, S1.mW, S1.mWn ', S0.mW, S0.mWn, S1.mW, S1.mWn)
//                 }

//             }
//         }
//         if (!ok) nL.push(p2)
//     }
//     nL.sort((a, b) => -(a.j2 - a.j1) + b.j2 - b.j1)
//     let p = nL[0]
//     let dx0 = (S0[p.i2].R.xc - S0[p.i1].R.xc) / S0.mW
//     let dx1 = (S1[p.j2].R.xc - S1[p.j1].R.xc) / S1.mW
//         // если ошибка менее чем в 2 символа
//     p.fit = Math.abs(dx0 - dx1) < 2
//     return p
// }


// function subStr(S0, S1, n = 2) {
//     let cmpList = []
//     let l0 = S0.length
//     let l1 = S1.length
//     let x0 = -l0 + n // shift S0 left
//     if (x0 > 0) x0 = 0
//     let x = l0 - n
//     if (x < 0) x = 0
//     for (let i = x0; i < l1 - n; i++) {
//         let mt = [i, i + l0, 0, l1].sort((a, b) => {
//                 return a - b
//             }) //
//         var toList = {}
//         let c = 0
//         let jj = -1
//         let kX
//         for (let j = mt[1]; j < mt[2]; j++) {
//             if (S0[x + j - mt[1]].S == S1[j].S) {
//                 if (jj < 0) jj = j
//                     // проверка строк на непрерывность геометрически
//                 if (j > jj) {
//                     let dX0 = (S0[x + j - mt[1]].R.xc - S0[x + j - mt[1] - 1].R.xc) / S0.lpR.w

//                     let dX1 = (S1[j].R.xc - S1[j - 1].R.xc) / S1.lpR.w

//                     kX = (dX1 > dX0) ? dX1 / dX0 : dX0 / dX1
//                 }
//                 if (jj == j || kX < _place.kX) {
//                     c++;
//                     [toList.i2, toList.j2] = [x + j - mt[1], j]
//                     if (c == 1) {;
//                         [toList.i1, toList.j1] = [x + j - mt[1], j]
//                     }
//                 } else {
//                     if (c >= n) cmpList.push(cpyO(toList))
//                     c = 0
//                     jj = -1
//                 }
//             } else {
//                 if (c >= n) cmpList.push(cpyO(toList))
//                 c = 0
//             }
//         }
//         if (c >= n) cmpList.push(cpyO(toList))
//         x--;
//         if (x < 0) x = 0
//     }
//     return cmpList
// }

// function fit2(s1, s2, pos, fitSymb = false, stretchX = 1) {
//     let sXLim = 20,
//         sYLim = 20
//     let i1 = pos.i1,
//         j1 = pos.j1,
//         i2 = pos.i2,
//         j2 = pos.j2
//     let x1 = s1[i2].R.x2 - s1[i1].R.x1
//     let x2 = s2[j2].R.x2 - s2[j1].R.x1
//     let h1 = s1.reduce((sum, it) => sum + it.R.h, 0) / s1.length
//     let h2 = s2.reduce((sum, it) => sum + it.R.h, 0) / s2.length
//     let kx = x1 / x2 * stretchX,
//         ky = h1 / h2

//     let kxR = s1.lpR.w / s2.lpR.w * stretchX,
//         kyR = s1.lpR.h / s2.lpR.h
//     let dx = s1[i1].R.x,
//         dy = s1[i1].R.y - s2[j1].R.y,
//         xc = s2[j1].R.x
//     let scX = kx / kxR,
//         scY = ky / kyR
//     scX = (scX >= 1) ? scX : 1 / scX
//     scY = (scY >= 1) ? scY : 1 / scY

//     if (scX > sXLim || scY > sYLim) {
//         pos.scale = false
//         return pos
//     }

//     function calc(it) {
//         it.x = dx + (it.x - xc) * kx;
//         it.w *= kx
//         it.y = dy + it.y;
//         it.h *= ky
//     }
//     s2.forEach((s) => calc(s.R))
//     let i = 0
//         // console.log(s1.numT, s2.numT, s2.rLi)
//     s2.forEach((it, j) => {
//         let s = findeS(s1, it.R, i)
//         if (s.place < _place.P1) {
//             i = s.i
//                 // console.log(s2.numT, i, s1[i].S, it.S)
//             if (s.place2 < _place.P2) {
//                 if ((s1[i].S != it.S) && (s1[s.i2].S == it.S)) {
//                     // console.log(i, '->', s.i2, it.S, s1.numT, s2.numT)
//                     i = s.i2
//                 }
//             }
//             if (fitSymb) {
//                 // console.log('fit: ', pos, s1.numT, s2.numT, i, s1[i].S, it.S, s.place, s.place2)
//                 // let R = merger_middle(s1[i].R, it.R)
//             }
//         }
//     })
//     i = i1
//     let err = 0
//     let ii, jj
//     let rmin = 1
//     let lmax = 0
//     for (let j = j1; j <= j2; j++) {
//         let s = findeS(s1, s2[j].R, i)
//         i = s.i
//         if (s.l.r / s.R.w < 2) {
//             if (lmax < s.l.r) {
//                 ii = i;
//                 jj = j;
//                 lmax = s.l.r;
//                 err++
//             }
//         }
//     }

//     if (err > 0) {
//         let delta = (s1[ii].R.xc - s2[jj].R.xc) / (s2[jj].R.x - s2[0].R.x)
//         s2.forEach((it) => {
//             // it.R.x += ((it.R.x - s2[0].R.x) * delta)
//         })
//     }
//     calc(s2.lpR)
//     pos.scale = true
//     return pos
// }