import React, { useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Avatar,
    Card, CardActionArea, CardHeader,
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import {loadCamerasList, loadCamerasNew, loadLicencePlates} from "../httpUtils";
import Typography from "@material-ui/core/Typography";
import EditCameraDialog from "./EditCameraDialog";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: 45,
    },
    alert: {
        marginTop: theme.spacing(2),
    },
    licencePlateAllowToAllAvatar: {
        backgroundColor: '#4CAF50',
        width: 45,
    },
    licencePlatePartialDisallowAvatar: {
        backgroundColor: '#FFC107',
        width: 45,
    },
    licencePlateFullDisallowAvatar: {
        backgroundColor: '#F44336',
        width: 45,
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        '&$expanded': {
            margin: 0,
            transform: "rotate(0deg)",
        },
    }
}));

export default function CamerasSettings(props) {
    const classes = useStyles();

    const [cameraList, setCameraList] = useState([]);

    const [currentCamera, setCurrentCamera] = useState(Infinity);

    const loadCameras = () => {
        loadCamerasNew().then((r)=>{
            setCameraList(r)
        })
    }

    useEffect(()=>{
        loadCameras()
    }, [])

    function getAvatarClassname(){
        return classes.licencePlateAllowToAllAvatar
        // if (fullDisallow) return classes.licencePlateFullDisallowAvatar
        // if (partialDisallow) return classes.licencePlatePartialDisallowAvatar
    }

    function getAvatarIcon(){
        return <CheckCircleOutlineIcon/>
        // if (fullDisallow) return <RemoveCircleOutlineIcon/>
        // if (partialDisallow) return <ErrorOutlineIcon/>
    }


    return (
        <React.Fragment>
            <Menu title='Камеры' reloadData={loadCameras}/>
            <CssBaseline />
            <div className={classes.root}>
                <Button variant="contained" color="primary" className={classes.accordion} onClick={()=>{
                    setCurrentCamera(0);
                }}>
                    Добавить камеру
                </Button>

                <Grid container spacing={1}>
                    { cameraList.length > 0 && cameraList.map((data, i) => (
                        <Grid container item xs={12} sm={6} md={4} lg={4} xl={2}>

                            <Card className={classes.card}>
                                <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                    console.log(data)
                                    setCurrentCamera(data.id);
                                }}>
                                    <CardHeader
                                        avatar={
                                            <Avatar variant="rounded" aria-label="recipe" className={getAvatarClassname()}>
                                                { getAvatarIcon() }
                                            </Avatar>
                                        }
                                        subheader={
                                            <React.Fragment>
                                                <Typography variant="h5" component="h2">{data.name}</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </CardActionArea>
                            </Card>

                        </Grid>
                    ))
                    }
                </Grid>
            </div>

            {currentCamera !== Infinity && <EditCameraDialog cameraId={currentCamera} needClose={()=>{setCurrentCamera(Infinity)}} needReload={loadCameras}/>}
        </React.Fragment>
    )
}
