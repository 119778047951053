import React, { useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Avatar,
    Card, CardActionArea, CardHeader,
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Typography from "@material-ui/core/Typography";
import {loadRentActualTariffs, loadRentServices} from "../httpUtils";
import EditServiceDialog from "./EditServiceDialog";
import Button from "@material-ui/core/Button";
import TitleIcon from '@material-ui/icons/Title';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: 45,
    },
    alert: {
        marginTop: theme.spacing(2),
    },
    licencePlateAllowToAllAvatar: {
        backgroundColor: '#CDDC39',
        width: 45,
    },
    licencePlatePartialDisallowAvatar: {
        backgroundColor: '#4CAF50',
        width: 45,
    },
    actionBar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

export default function RentServices(props) {
    const classes = useStyles();

    const [actualTariffList, setActualTariffList] = useState([]);
    const [currentService, setCurrentService] = useState(undefined);

    const _loadRentActualTariffs = () => {
        loadRentServices().then((r)=>{
            setActualTariffList(r)
        })
    }

    useEffect(()=>{
        _loadRentActualTariffs()
    }, [])

    function getAvatarClassname(data){
        return data.tariff ? classes.licencePlateAllowToAllAvatar : classes.licencePlatePartialDisallowAvatar
    }

    function getAvatarIcon(data){
        return data.tariff ? <TitleIcon/> : <WorkOutlineIcon/>
    }


    return (
        <React.Fragment>
            <Menu title='Услуги'/>
            <CssBaseline />
            <div className={classes.root}>
                <div className={classes.actionBar}>
                    <Button variant="contained" color="primary" onClick={()=>{
                        setCurrentService(0);
                    }}>
                        Создать услугу
                    </Button>
                </div>
                <Grid container spacing={1}>
                    { actualTariffList.length > 0 && actualTariffList.map((data, i) => (
                        <Grid container item xs={12} sm={6} md={4} lg={4} xl={2} key={`grk-${i}`}>
                            <Card className={classes.card}>
                                <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                    setCurrentService(data.id);
                                }}>
                                    <CardHeader
                                        avatar={
                                            <Avatar variant="rounded" aria-label="recipe" className={getAvatarClassname(data)}>
                                                { getAvatarIcon(data) }
                                            </Avatar>
                                        }
                                        subheader={
                                            <React.Fragment>
                                                <Typography variant="h5" component="h2">{data.description}</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </CardActionArea>
                            </Card>

                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            { currentService !== undefined && <EditServiceDialog id={currentService} open={currentService} needClose={()=>{setCurrentService(undefined)}} needReload={()=>{_loadRentActualTariffs()}}/> }
        </React.Fragment>
    )
}
