import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Carousel from 'react-bootstrap/Carousel'

import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export default class EventVisualizer extends Component {
 constructor(props) {
   super(props);
   console.log(props);
   this.state = {
     list: [],

     anch: '10 10 10 1'
   }

   this.handleRecalc = this.handleRecalc.bind(this);

   this.color = this.color.bind(this);
 }

 color(i){
   let color = ['#b71c1c', '#4A148C', '#E65100', '#01579B', '#FFEB3B', '#33691E', '#F57F17', '#1A237E', '#3E2723'];
   return color[(i % color.length + color.length) % color.length];
 }

 componentWillReceiveProps(nextProps){
   let propss = nextProps.visualizer;

   propss.map(function (r) {
        r.checked=false;
      })
      // console.log(propss);
   this.setState({
     list: propss
   })
 }

 componentDidMount() {
   let propss = this.props.visualizer;

   propss.map(function (r) {
        r.checked=false;
      })
      // console.log(propss);
   this.setState({
     list: propss
   })
 }

  handleRecalc(){
    console.log(this.props.id);
    var m = {xmin: 0, xmax: 0, ymin: 0, ymax: 0 }
    var find = false;
    this.state.list.forEach(function (r) {
      if (r.checked) {
        r.symbols.forEach(function (rs) {
          if (find) {
            if (rs.rect[0] < m.xmin) {
              m.xmin = rs.rect[0];
            }
            if (rs.rect[0]+rs.rect[2] > m.xmax) {
              m.xmax = rs.rect[0]+rs.rect[2];
            }
            if (rs.rect[1] < m.ymin) {
              m.ymin = rs.rect[1];
            }
            if (rs.rect[1]+rs.rect[3] > m.ymax) {
              m.ymax = rs.rect[1]+rs.rect[3];
            }
          } else {
            m.xmin = rs.rect[0];
            m.xmax = rs.rect[0]+rs.rect[2];
            m.ymin = rs.rect[1];
            m.ymax = rs.rect[1]+rs.rect[3];
            find = true;
          }
        })
      }
    })
    let width = m.xmax-m.xmin;
    let height = m.ymax-m.ymin;
    let anch = ""+(m.xmin-(width*0.05)) +" "+ (m.ymin-(height*0.05)) +" "+ (width + (width*0.15)) +" "+ (height + (height*0.15)) +"";
    console.log(anch);
    this.setState({
      anch: anch
     })
  }

 render() {

   return (
     <>

              <Form>
              {
                this.state.list.map((data, i) => (
                 <>

                 <OverlayTrigger
                     key={this.props.id+"plbty-"+i}
                     placement={'top'}
                     overlay={
                       <Tooltip id={this.props.id+"plb-"+i}>
                         {data.symbols.map((symbol, j) => (
                           <>
                           {symbol.S + " - " + symbol.confidence}
                           <br/>
                           </>
                         ))}
                       </Tooltip>
                     }
                   >
                      <Button key={this.props.id+"bt-"+i} variant={data.checked ? "secondary":"light"} size="sm" style={{border: '3px solid '+ this.color(data.cluster)}} className="mb-1 mr-1" onClick={ (e)=>{
                         e.target.blur();
                          let s=this.state.list;
                          s[i].checked=!data.checked;
                          this.setState({list: s});
                          this.handleRecalc();
                      }}>
                        {data.num}
                      </Button>
                   </OverlayTrigger>{' '}
                  </>
                ))
              }
              </Form>
                <svg width='100%' height='100%' viewBox={this.state.anch}>
                {
                  this.state.list.map((data, i) => (

                      data.checked ?
                        data.symbols.map((symbols, j) =>(
                          <>
                          <rect key={this.props.id+"anch-"+j}
                               width={symbols.rect[2]}
                               height={symbols.rect[3]}
                               x={ symbols.rect[0]}
                               y={ symbols.rect[1]}
                               strokeWidth= '1%'
                               stroke = {this.color(j)}
                               fill='none'
                               fillOpacity='0.01'>
                               {symbols.S}
                          </rect>
                          <text key={this.props.id+"sm-"+j}
                               x={ symbols.rect[0]}
                               y={ symbols.rect[1] + symbols.rect[3]}
                               strokeWidth= '1%'
                               stroke = {this.color(j)}
                               fill='none'
                               fillOpacity='0.01'>
                               {symbols.S}
                          </text>
                          </>
                        ))
                      : null
                  ))
                }
                </svg>

      </>
   );
 }
}
