import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails, Avatar,
    Box, Card, CardContent, Collapse, ListItem, ListItemAvatar, ListItemText,
    makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import PopupDialog from "../component/PopupDialog";
import {loadRentContractExtendedInfo} from "../httpUtils";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import PaymentIcon from '@material-ui/icons/Payment';
import CheckIcon from '@material-ui/icons/Check';
import {penniesToRubles} from "../utils";
import Grid from "@material-ui/core/Grid";
import RentContractAcceptance from "./RentContractAcceptance";

const styledClassStyle = makeStyles((theme) => ({
    my1: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
}));

function BillListItem(data) {
    const styledClass = styledClassStyle();

    const [ isOpen, setOpen ] = useState(false);

    return (
        <React.Fragment>
            <ListItem button onClick={()=>{setOpen(true)}}>
                <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#F5F5F5'}}>
                        <CheckIcon style={{color: '#00C853'}}/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`Счет на оплату № ${data.bill.billId}`} secondary={`От ${new Date(data.bill.billCreated).toLocaleString()}`} />
            </ListItem>

            <PopupDialog maxWidth="sm" open={isOpen} onClose={()=>setOpen(false)} title={`Счет на оплату № ${data.bill.billId}`}>
                <Card variant="elevation" className={styledClass.my1}>
                    <CardContent>
                        <Typography variant="body1">
                            Создан: <b>{new Date(data.bill.billCreated).toLocaleString()}</b>
                        </Typography>
                        <Typography variant="body1">
                            Статус: <b>{data.bill.billStatus === 0 ? 'Оплачен' : 'Ожидает оплаты'}</b>
                        </Typography>
                        <Typography variant="body1">
                            За период:
                        </Typography>
                        <Typography variant="body1" className={styledClass.ml1}>
                            С: <b>{new Date(data.bill.billBegin).toLocaleString()}</b>
                        </Typography>
                        <Typography variant="body1" className={styledClass.ml1}>
                            По: <b>{new Date(data.bill.billEnd).toLocaleString()}</b>
                        </Typography>
                        <Typography variant="body1">
                            Позиции:
                        </Typography>
                        {
                            data.bill.items.map((it, i) => (
                                <Typography key={`tr-${i}`} variant="body1" className={styledClass.ml1}>{it.billItemServiceName} - <b>{it.billItemQuantity} шт, всего {penniesToRubles(it.billItemAmount)}</b></Typography>
                            ))
                        }
                        <Typography variant="body1">
                            Сумма: <b>{penniesToRubles(data.bill.billAmount)}</b>
                        </Typography>
                        {
                            data.bill.acceptance.map((bi, i)=>(
                                <Typography key={`acr-${i}`} variant="body1" className={styledClass.ml1}>{bi.billAcceptanceType === 0 ? `Наличными` : bi.billAcceptanceType === 1 ? `Безналичнми` : bi.billAcceptanceType === 13 ? `Аванс` : ``}: <b>{penniesToRubles(bi.billAcceptanceAmount)}</b></Typography>
                            ))
                        }
                    </CardContent>
                </Card>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button fullWidth variant={"contained"} color={"primary"} disabled>Провести фискальный чек</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button fullWidth variant={"contained"} color={"primary"} disabled>Печать товарного чека</Button>
                    </Grid>
                </Grid>
            </PopupDialog>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    billList: {

        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    accordion: {
        marginTop: theme.spacing(1),
        expanded: {
            margin: theme.spacing(1)
        }
    },
    accordionIcon:{
        marginRight: theme.spacing(1),
    },
    accordionDetails: {
        paddingLeft: 0,
        paddingRight: 0,
    }
}));

export default function RentContractInfoDialog(props) {
    const classes = useStyles();

    const [ isOpen, setOpen ] = useState(false);
    const [ isNeedContinue, setNeedContinue ] = useState(false);

    const [ contractId, setContractId ] = useState(0);

    const [ contract, setContract ] = useState(undefined);
    const [ bills, setBills ] = useState(undefined);

    const loadInfo = (id) => {
        console.log('upd')
        loadRentContractExtendedInfo(id).then((v)=>{
            setContract({
                ...v,
                bills: undefined
            })
            setBills(v.bills)
        }).catch((e)=>{

        })
    }

    useEffect(()=>{
        console.log(props.contractId)
        if (props.open, props.contractId){
            setContractId(props.contractId)
            loadInfo(props.contractId);
            setOpen(true)
        } else {
            setOpen(false);
            setContractId(0);
        }
    }, [props.open, props.contractId])

    const closeDialog = () => {
        props.hasOwnProperty('needClose') && props.needClose();
    }

    const renewParent = () => {
        props.hasOwnProperty('renewParent') && props.renewParent();
    }

    return (
        <PopupDialog maxWidth="sm" open={isOpen} onClose={closeDialog} title={`Работа с договором № ${contractId}`} actionBoard={
            <React.Fragment>
                <Button color="primary" disabled={true}>
                    Ведомость по счетам
                </Button>
                <Button color="primary" disabled={false} onClick={()=>{setNeedContinue(true)}}>
                    Продлить договор
                </Button>
            </React.Fragment>
        }>
            <>
                {contract !== undefined &&
                    <Card variant="elevation" className={classes.my1}>
                        <CardContent>
                            <Typography variant="body1">
                                Дата заключения договора: <b>{new Date(contract.contractBegin).toLocaleString()}</b>
                            </Typography>
                            <Typography variant="body1">
                                Заключен до: <b>{new Date(contract.contractEnd).toLocaleString()}</b>
                            </Typography>
                            <Typography variant="body1">
                                Тип арендуемого объекта: <b>{contract.tariffObjectName}</b>
                            </Typography>
                            <Typography variant="body1">
                                Номерной знак: <b>{contract.contractLicencePlate}</b>
                            </Typography>
                        </CardContent>
                    </Card>
                }
                {
                    bills !== undefined && bills.length > 0 &&
                    <Accordion hidden={false} className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box alignItems="center" display="flex">
                                <Box>
                                    <PaymentIcon className={classes.accordionIcon}/>
                                </Box>
                                <Box>
                                    <Typography>Счета</Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <List className={classes.billList}>
                            {
                                bills.map((bill,  billIndex)=> (
                                    <BillListItem bill={bill}/>
                                ))
                            }
                            </List>
                        </AccordionDetails>
                    </Accordion>
                }
            </>
            {isNeedContinue && <RentContractAcceptance open={isNeedContinue} needClose={()=>{renewParent(); loadInfo(contractId); setNeedContinue(false);}} licencePlate={contract.contractLicencePlate} contractId={contractId}/>}
        </PopupDialog>
    )
}
