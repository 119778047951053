import React, {useEffect, useState} from "react";
import {AppBar, Dialog, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    dialogBody: {
        backgroundColor: theme.palette.background.default
    }
}));

export default function FullScreenDialog(props){
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(props.open);
    }, [props.open])

    function close() {
        props.onClose();
    }

    return (
        <React.Fragment>
            <Dialog open={open} fullScreen={fullScreen} fullWidth maxWidth={"lg"} PaperProps={{ classes: {root: classes.dialogBody } }}>
                <AppBar position={"relative"} >
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                            {props.subtitle &&
                                <Typography variant="body2">
                                    {props.subtitle}
                                </Typography>
                            }
                        </Typography>
                        <IconButton color="inherit" onClick={ close } aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {props.children}
            </Dialog>
        </React.Fragment>
    )
}