import React, {useContext, useEffect, useState} from 'react';
import {
    InputAdornment,
    makeStyles,
} from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
    addLicencePlateToUnlived,
    bindApartmentLicencePlates,
    findLinkedApartmentLicencePlates, findLinkedUnlivedLicencePlates,
    loadLicencePlates,
    unbindApartmentLicencePlates, unbindLicencePlateFromUnlived
} from "../httpUtils";
import {checkSettings, getSortParam} from "../utils";
import LicencePlateCreateDialog from "../LicencePlates/LicencePlateCreateDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InfoIcon from "@material-ui/icons/Info";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LicencePlateInfo from "../LicencePlates/LicencePlateInfo";
import {ReactReduxContext} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(2),
        display: "flex"
    },
    licencePlatesList: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    selectContainer: {
        display: "flex",
        flexDirection: "column",
        width: '100%'
    }
}));

export default function UnlivedLicencePlates(props) {
    const classes = useStyles();
    const filter = createFilterOptions();

    const [unlivedId, setUnlivedId] = useState(0);

    const [currentAvailableLicencePlatesList, setCurrentAvailableLicencePlatesList] = useState([]);
    const [currentApartmentLicencePlatesList, setCurrentApartmentLicencePlatesList] = useState([]);

    const [selectedLicencePlate, setSelectedLicencePlate] = useState('');
    const [autocompleteLicencePlate, setAutocompleteLicencePlate] = useState('');
    const [isFetching, setFetching] = useState(false);

    const [createdLicencePlate, setCreatedLicencePlate] = useState('');
    const [isOpenCreatedDialog, setOpenCreatedDialog] = useState(false);

    const [infoLicencePlate, setInfoLicencePlate] = useState('');
    const [isUpdateLicencePlatesAvailable, setUpdateLicencePlatesAvailable] = useState(false);

    useEffect(()=>{
        setUpdateLicencePlatesAvailable(props.isUpdateLicencePlatesAvailable);
    }, [props.isUpdateLicencePlatesAvailable])

    useEffect(()=>{
        setUnlivedId(props.unlivedId);
        if (props.unlivedId){
            loadLinkedLicencePlates(props.unlivedId);
        }
    }, [props.apartmentId])

    useEffect(()=>{
        if (autocompleteLicencePlate) {
            setFetching(true);
            loadLicencePlates(`${getSortParam({limit: 100, licencePlate: autocompleteLicencePlate})}`, true).then((r)=>{
                console.log(r)
                setFetching(false);
                setCurrentAvailableLicencePlatesList(r);
            })
        }
    }, [autocompleteLicencePlate])

    function loadLinkedLicencePlates(id){
        findLinkedUnlivedLicencePlates(id).then((r)=>{
            setCurrentApartmentLicencePlatesList(r)
        })
            .catch((e)=>{

            })
    }

    function unbindLinkedUnlivedLicencePlate(licencePlate){
        unbindLicencePlateFromUnlived(unlivedId, licencePlate).then((r)=>{
            loadLinkedLicencePlates(unlivedId)
        })
        .catch((e)=>{

        })
    }

    function bindLinkedUnlivedLicencePlate(licencePlate){
        addLicencePlateToUnlived(unlivedId, licencePlate).then((r)=>{
            loadLinkedLicencePlates(unlivedId)
            setSelectedLicencePlate('');
        })
            .catch((e)=>{

            })
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.selectContainer}>
                    <Autocomplete
                        value={selectedLicencePlate}
                        options={currentAvailableLicencePlatesList}
                        loading={isFetching}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.hasOwnProperty('licencePlate') && newValue && newValue.hasOwnProperty('inputValue')) {
                                setCreatedLicencePlate(newValue.inputValue);
                                setOpenCreatedDialog(true);
                                return;
                            }
                            if (newValue && newValue.hasOwnProperty('licencePlate')) {
                                setSelectedLicencePlate(newValue.licencePlate);
                            } else {
                                setSelectedLicencePlate('');
                            }
                        }}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.licencePlate;
                        }}
                        renderOption={(option) => option.licencePlate}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    licencePlate: `Добавить "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        fullWidth
                        margin="normal"
                        freeSolo
                        size='medium'
                        renderInput={(params) => (
                            <TextField {...params} label="Выберите номерной знак" variant="outlined" onChange={(e)=>{setAutocompleteLicencePlate(e.target.value.toUpperCase())}}/>
                        )}
                    />
                    {
                        selectedLicencePlate &&
                        <Button variant="outlined" color="primary" size="medium" style={{marginTop: 8}} onClick={()=>{bindLinkedUnlivedLicencePlate(selectedLicencePlate)}}>
                            Привязать
                        </Button>
                    }

                </div>

            </div>
            <div className={classes.licencePlatesList}>
                <List>
                    {
                        currentApartmentLicencePlatesList.map((data, i) => (
                            <ListItem key={i}>

                                <Typography variant="button" display="block" color={"initial"} >

                                    {data.licencePlate}
                                </Typography>
                                <ListItemSecondaryAction>
                                    <IconButton>
                                        <InfoOutlinedIcon fontSize="medium" color="primary" onClick={ (e) => {
                                            setInfoLicencePlate(data.licencePlate);
                                        }}/>
                                    </IconButton>
                                    <IconButton edge={"end"}>
                                        <HighlightOffOutlinedIcon fontSize="medium" color="secondary" onClick={ (e)=>{unbindLinkedUnlivedLicencePlate(data.licencePlate)}}/>
                                    </IconButton>

                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>
            </div>
            <LicencePlateCreateDialog isNeedLicencePlateCreate={isOpenCreatedDialog} oneCreateOnly createdLicencePlate={(lp)=>{
                setSelectedLicencePlate(lp);
            }} initLicencePlate={createdLicencePlate} isNeedClose={()=>{setOpenCreatedDialog(false); setCreatedLicencePlate('');}}/>
            <LicencePlateInfo isUpdateLicencePlateAvailable={isUpdateLicencePlatesAvailable} key={infoLicencePlate} open={!!infoLicencePlate} number={infoLicencePlate} onClose={()=>{ setInfoLicencePlate('') }} />
        </React.Fragment>
    )
}
