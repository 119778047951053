import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    createRentContract,
    loadLastEntryLicencePlate,
    loadRentActualTariffs,
    loadRentContractBillItems,
    loadRentContractBillItemsUponRequest,
    loadRentContractExtendedInfo,
    loadRentTariffObjects
} from "../httpUtils";
import {Alert} from "@material-ui/lab";
import {
    Card,
    CardContent,
    FormControl, FormLabel,
    InputLabel,
    makeStyles, MenuItem, Radio, RadioGroup,
    Select,
    TextField
} from "@material-ui/core";
import {getTypeEvents, penniesToRubles} from "../utils";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PopupDialog from "../component/PopupDialog";
import AcceptancePaymentMethod from "./AcceptancePaymentMethod";

const addMonth = (date, month) => {
    const isLeapYear = function (year) {
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
    };

    const getDaysInMonth = function (year, month) {
        return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };

    const n = date.getDate();
    date.setDate(1);
    date.setMonth(date.getMonth() + month);
    date.setDate(Math.min(n, getDaysInMonth(date.getFullYear(), date.getMonth()) ));
    return date;
}

const findService = (services, id) => {
    for (const s of services) {
        if (s.id === id) return s;
        if (s.relatedServices) {
            const r = findService(s.relatedServices, id)
            if (r) return r;
        }
    }
    return undefined;
}

const useStyles = makeStyles((theme) => ({
    my1: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
}));

function ServiceListItem(props) {
    const styledClass = useStyles();

    const [ service, setService ] = useState(false);

    useEffect(()=>{
        if (props.service.id){
            console.log(findService(props.services, props.service.id))
            setService(findService(props.services, props.service.id))
            if (props.service.parentId){

            }
        }
    }, [props.service, props.services])

    const deleteCurrent = (id) => {
        props.hasOwnProperty('deleteCurrent') && props.deleteCurrent(id);
    }

    return (
        <React.Fragment>
            <Card variant="elevation" className={styledClass.my1} key={props.key} >
                <CardContent>
                    <Typography color="textPrimary" gutterBottom>
                        {service.description}
                    </Typography>
                    <Typography variant="body2">
                        Стоимость: {penniesToRubles(service.cost)}
                    </Typography>
                    <Typography variant="body2">
                        Колличество: {props.service.quantity}
                    </Typography>
                    <Typography variant="body2">
                        Итог: {penniesToRubles(props.service.amount)}
                    </Typography>
                </CardContent>
                {!props.service.parentId &&<Button size="small" color={"secondary"} fullWidth onClick={deleteCurrent.bind(null, service.id)}>Удалить</Button> }
            </Card>
        </React.Fragment>
    )
}



export default function RentContractAcceptance(props) {
    const styledClass = useStyles();
    const [ isOpenAcceptConfirmationDialog, setOpenAcceptConfirmationDialog ] = useState(false);

    const [ isOpen, setOpen ] = useState(false);

    // dictionary
    const [rentTariffObjectList, setRentTariffObjectList] = useState([]);
    const [actualTariffList, setActualTariffList] = useState([]);
    const [rentContractBillItems, setRentContractBillItems] = useState([]);
    const [rentContractBillItemsUponRequest, setRentContractBillItemsUponRequest] = useState([]);
    // dictionary

    const [contractLicencePlate, setContractLicencePlate] = useState(''); // to server

    const [licencePlateLastEntryTime, setLicencePlateLastEntryTime] = useState();


    const [contractBeginDatetime, setContractBeginDatetime] = useState(); // to server
    const [isContractBeginDatetimeEditable, setContractBeginDatetimeEditable] = useState(true); // to server

    const [contractEndDatetime, setContractEndDatetime] = useState(new Date()); // to server
    const [isContractEndDatetimeEditable, setContractEndDatetimeEditable] = useState(false); // to server

    const [contractEndDatetimeTariff, setContractEndDatetimeTariff] = useState(new Date());
    const [isContractEndDatetimeFromTariff, setContractEndDatetimeFromTariff] = useState(false);

    const [contractLeaveToDatetimeTariff, setContractLeaveToDatetimeTariff] = useState(new Date());

    const [contractDuration, setContractDuration] = useState(0);
    const [contractBillTariffDuration, setContractBillTariffDuration] = useState(0);

    const [contractBillTariff, setContractBillTariff] = useState([]); // to server

    // add to table
    const [selectedBillTariffAdd, setSelectedBillTariffAdd] = useState(undefined);
    const [selectedBillQuantityAdd, setSelectedBillQuantityAdd] = useState(undefined);
    // add to table

    const [selectedRentTariffObject, setSelectedRentTariffObject] = useState(undefined); // to server

    // payments
    const [contractCost, setContractCost] = useState(0);
    const [contractAcceptedCash, setContractAcceptedCash] = useState(0); // to server
    const [contractAcceptedCashless, setContractAcceptedCashless] = useState(0); // to server
    const [contractAcceptedAdvance, setContractAcceptedAdvance] = useState([]); // to server
    // payments

    const [ contractId, setContractId ] = useState(0);
    const [ isRenew, setRenew ] = useState(false);

    const [ isSending, setSending ] = useState(false);

    const [ isAutofillDisable, setAutofillDisable ] = useState(false);

    const submitCurrentContract = (contractLicencePlate, selectedRentTariffObject, contractBeginDatetime, contractEndDatetime, contractLeaveToDatetime, contractBillTariff, contractAcceptedCash, contractAcceptedCashless, contractAcceptedAdvance, renewContractId) => {
        setSending(true);
        createRentContract({
            renewContractId: renewContractId,
            licencePlate: contractLicencePlate,
            tariffObject: selectedRentTariffObject,
            begin: new Date(contractBeginDatetime).toUTCString(),
            end: new Date(contractEndDatetime).toUTCString(),
            leaveTo: new Date(contractLeaveToDatetime).toUTCString(),
            current: new Date().toUTCString(),
            tariffs: contractBillTariff.map((v)=>{return { id: v.id, quantity: v.quantity}}),
            payment: {
                cash: contractAcceptedCash,
                cashless: contractAcceptedCashless,
                advance: contractAcceptedAdvance
            }
        }).then((r)=>{
            console.log(r)
            setSending(false);
            renewParent();
            closeDialog();
        }).catch((e)=>{
            setSending(false);
            console.warn(e)
        })
    }

    const initData = () => {
        setOpenAcceptConfirmationDialog(false);
        setContractBeginDatetime(undefined);
        setContractEndDatetime(new Date(new Date().setSeconds(0, 0)));
        setContractLeaveToDatetimeTariff(new Date(new Date().setSeconds(0, 0)));
        setContractBeginDatetimeEditable(true);
        setContractEndDatetimeEditable(false);
        setContractBillTariff([]);
        setActualTariffList([]);
        setContractLicencePlate('');
        setLicencePlateLastEntryTime(undefined);
        setSelectedBillTariffAdd(undefined);
        setSelectedBillQuantityAdd(undefined)
        setContractDuration(0);
        setContractBillTariffDuration(0);
        setSelectedRentTariffObject(undefined);
        setContractAcceptedCash(0);
        setContractAcceptedCashless(0)
        setContractEndDatetimeFromTariff(false);
        setContractEndDatetimeTariff(new Date(new Date().setSeconds(0, 0)));
        setAutofillDisable(false);
    }

    useEffect(()=>{
        if (isContractEndDatetimeFromTariff){
            if (contractBillTariff.length > 0 && contractBillTariffDuration){
                setContractEndDatetimeTariff(contractEndDatetime)
                setContractEndDatetime(new Date(((new Date(contractBeginDatetime).getTime()/1000) + contractBillTariffDuration) * 1000))
            }
        } else {
            setContractEndDatetime(contractEndDatetimeTariff);
        }
    }, [isContractEndDatetimeFromTariff])

    useEffect(()=>{
        if (isContractEndDatetimeEditable){

        } else {
            setContractEndDatetime(new Date(new Date().setSeconds(0, 0)));
        }
    }, [isContractEndDatetimeEditable])


    useEffect(()=>{
        if (props.open){
            loadRentTariffObjects().then((r)=>{
                setRentTariffObjectList(r)
            })
            setOpen(true)
        } else {
            setOpen(false);
            initData();
        }
    }, [props.open])

    const closeDialog = () => {
        initData();
        props.hasOwnProperty('needClose') && props.needClose();
    }

    const renewParent = () => {
        props.hasOwnProperty('renewParent') && props.renewParent();
    }

    useEffect(()=>{
        setContractBillTariff([]);
        setActualTariffList([]);
        setContractDuration(0);
        setContractBillTariffDuration(0);
        if (selectedRentTariffObject) {
            loadRentActualTariffs(selectedRentTariffObject).then((r)=>{
                setActualTariffList(r)
            })
        }
    }, [selectedRentTariffObject]);

    useEffect(()=>{
        console.log(props.licencePlate)
        if (props.licencePlate) {
            setContractLicencePlate(props.licencePlate)
        }
    }, [props.licencePlate]);

    useEffect(()=>{
        if (contractLicencePlate) {
            loadLastEntryLicencePlate(contractLicencePlate).then((res) => {
                if (res.time) {
                    setLicencePlateLastEntryTime(new Date(new Date(res.time).setSeconds(0, 0)));
                }
            }).catch((e) => {
                setLicencePlateLastEntryTime(undefined);
            })
        }
    }, [contractLicencePlate]);

    useEffect(()=>{
        if (contractId){
            loadRentContractExtendedInfo(contractId).then((v)=>{
                loadRentContractBillItemsUponRequest(contractId).then((v1)=>{
                    console.log(v1);

                    setRentContractBillItemsUponRequest(v1)

                    setAutofillDisable(true);
                    setContractLicencePlate(v.contractLicencePlate);
                    setContractBeginDatetime(new Date(v.contractEnd));
                    setSelectedRentTariffObject(v.tariffObjectId);
                    setRenew(true)
                    setRentContractBillItems(v.bills.map((v1)=>v1.items).reduce((c, v1)=>[...c, ...v1], []))
                }).catch((e1)=>{

                })
            }).catch((e)=>{

            })
        }
    }, [contractId]);

    useEffect(()=>{
        console.log(props.contractId)
        if (props.contractId){
            setContractId(props.contractId);
        }
    }, [props.contractId]);

    useEffect(()=>{
        if (contractBeginDatetime && contractEndDatetime && actualTariffList.length > 0) {
            const contractDuration = Math.ceil(( Math.abs(contractEndDatetime.getTime() - contractBeginDatetime.getTime()) )/ 1000);
            setContractDuration(contractDuration)
            if (!isAutofillDisable){
                setContractEndDatetimeFromTariff(false);
                setContractBillTariff(autofillTariffs(contractBeginDatetime, contractDuration, actualTariffList))
            } else {

            }
        }
    }, [contractBeginDatetime, contractEndDatetime, actualTariffList]);

    useEffect(()=>{
        if (contractBillTariff && contractBillTariff.length > 0){
            let overpaidTime = Infinity;
            for (const cbt of contractBillTariff) {
                if (cbt.overpaidTime !== undefined) {
                    if (overpaidTime > cbt.overpaidTime) overpaidTime = cbt.overpaidTime;
                }
            }
            if (overpaidTime === Infinity) overpaidTime = 0;

            const contractBillTariffEnd = contractBillTariff.reduce((a, c) => {
                if (c.rentTime){
                    return a + ((c.rentTime ? c.rentTime : 0) * c.quantity) * 1000;
                }
                if (c.rentMonth){
                    const cur = new Date(a);
                    const next = addMonth(new Date(a), parseInt(c.rentMonth * c.quantity))
                    return a + ((next.getTime() - cur.getTime()));
                }
                return a;
            }, new Date(contractBeginDatetime).getTime());

            // const contractBillTariffDuration = contractBillTariff.reduce((previousValue, currentValue) => previousValue + (currentValue.rentTime ? currentValue.rentTime : 0 ) * currentValue.quantity, 0)
            const contractCost = contractBillTariff.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0)
            setContractBillTariffDuration((new Date(contractBillTariffEnd).getTime() - new Date(contractBeginDatetime).getTime()) / 1000 )
            console.log(new Date(contractBillTariffEnd))


            if (isAutofillDisable){
                setContractEndDatetimeFromTariff(true);
                setContractEndDatetime(new Date(contractBillTariffEnd))
                setContractLeaveToDatetimeTariff(new Date(new Date(contractBillTariffEnd).getTime() + overpaidTime*1000 ))
                // setContractDuration((new Date(contractBillTariffEnd).getTime() - new Date(contractBeginDatetime).getTime()) / 1000)
                console.log()
            } else {
                setContractLeaveToDatetimeTariff(new Date(new Date(contractEndDatetime).getTime() + overpaidTime*1000 ))
            }
            setContractCost(contractCost)

        } else {
            setContractBillTariffDuration(0)
            setContractCost(0)
        }
    }, [contractBillTariff, isAutofillDisable]);

    useEffect(()=>{
        if (!isRenew){
            if (licencePlateLastEntryTime) {
                setContractBeginDatetime(licencePlateLastEntryTime)
                setContractBeginDatetimeEditable(false)
            } else {
                setContractBeginDatetimeEditable(true)
            }
        }
    }, [licencePlateLastEntryTime]);

    useEffect(()=>{
        if (!isContractBeginDatetimeEditable && licencePlateLastEntryTime) {
            setContractBeginDatetime(licencePlateLastEntryTime)
            setContractBeginDatetimeEditable(false)
        } else {
            setContractBeginDatetimeEditable(true)
        }
    }, [isContractBeginDatetimeEditable]);

    useEffect(()=>{
        if (setContractEndDatetimeEditable) {
            setContractEndDatetime(new Date(new Date().setSeconds(0, 0)))
            setContractEndDatetimeEditable(false)
        } else {
            setContractEndDatetimeEditable(true)
        }
    }, [setContractEndDatetimeEditable]);

    const removeItemFromContractBillTariff = (id) => {
        setAutofillDisable(true);
        setContractBillTariff(contractBillTariff.filter((v)=>(v.id !== id)).filter((v)=>(v.parentId !== id)))
    }

    const addItemFromContractBillTariff = (id, quantity, tariffs) => {
        const currentTariff = tariffs.find((v)=>v.id==id)
        // skip
        if (currentTariff.acceptanceOnceOnAllTerm === 1) {
            if ([...rentContractBillItems].find((v)=>v.billItemServiceId === currentTariff.id)) return;
        }
        const _contractBillTariff = [...contractBillTariff];
        const existContractBillTariff = _contractBillTariff.find((v)=>v.id === id)
        if (existContractBillTariff){
            if (currentTariff.stackable) {
                existContractBillTariff.amount += parseInt(quantity) * currentTariff.cost;
                existContractBillTariff.quantity += parseInt(quantity);
            }
        } else {
            if (currentTariff.tariff){
                _contractBillTariff.push({
                    id,
                    quantity: parseInt(quantity),
                    amount: parseInt(quantity) * currentTariff.cost,
                    rentTime: currentTariff.tariff.rentTime,
                    rentMonth: currentTariff.tariff.rentMonth,
                    overpaidTime: currentTariff.tariff.overpaidTime,
                    cost: currentTariff.cost
                })
            } else {
                _contractBillTariff.push({
                    id,
                    quantity: parseInt(quantity),
                    amount: parseInt(quantity) * currentTariff.cost,
                    cost: currentTariff.cost
                })
            }
        }
        if (currentTariff.relatedServices){
            for (const rs of currentTariff.relatedServices) {
                // skip
                if (rs.acceptanceOnceOnAllTerm === 1) {
                    if ([...rentContractBillItems].find((v)=>v.billItemServiceId === rs.id)) break;
                }
                let q = quantity;
                if (!rs.stackable) q = 1;
                if (rs.tariff){
                    _contractBillTariff.push({
                        id: rs.id,
                        parentId: id,
                        quantity: parseInt(q),
                        amount: parseInt(q) * rs.cost,
                        rentTime: rs.tariff.rentTime,
                        rentMonth: rs.tariff.rentMonth,
                        overpaidTime: rs.tariff.overpaidTime,
                        cost: rs.cost
                    })
                } else {
                    _contractBillTariff.push({
                        id: rs.id,
                        parentId: id,
                        quantity: parseInt(q),
                        amount: parseInt(q) * rs.cost,
                        cost: rs.cost
                    })
                }
            }
        }

        setContractBillTariff(_contractBillTariff)
        setSelectedBillTariffAdd(undefined);
        setSelectedBillQuantityAdd(undefined);
    }

    const secondsToHuman = (v) => {
        const d = Math.floor(v / (3600*24));
        const h = Math.floor(v % (3600*24) / 3600);
        const m = Math.floor(v % 3600 / 60);

        const res = [];
        d > 0 && res.push(`${d} дн.`)
        h > 0 && res.push(`${h} ч.`)
        m > 0 && res.push(`${m} м.`)
        return res.join(', ');
    }

    const autofillTariffs = (begin, duration, tariffs, rentTime) => {
        const sortedTariffsByDuration = tariffs.filter((v)=>!v.tariffs).sort((a, b) => {
            if (!b.tariff) return 0;
            if (!b.tariff.rentTime) return 1;
            if (!a.tariff.rentTime) return -1;
            return b.tariff.rentTime-a.tariff.rentTime
        });
        let diffDuration = duration;
        const arrangedTariffs = [];
        const addToArrangedList = (service, quantity, parentId) => {
            // skip
            if (service.acceptanceOnceOnAllTerm === 1) {
                if ([...rentContractBillItems].find((v)=>v.billItemServiceId === service.id)) return;
            }
            const existArranged = arrangedTariffs.find((v)=>v.id === service.id)
            if (existArranged) {
                if (service.stackable){
                    existArranged.quantity+=quantity;
                    existArranged.amount+=service.cost;
                }
            } else {
                if (service.tariff){
                    arrangedTariffs.push({
                        id: service.id,
                        quantity: quantity,
                        amount: service.cost,
                        rentTime: service.tariff.rentTime,
                        rentMonth: service.tariff.rentMonth,
                        overpaidTime: service.tariff.overpaidTime,
                        cost: service.cost,
                        parentId
                    })
                } else {
                    arrangedTariffs.push({
                        id: service.id,
                        quantity: quantity,
                        amount: service.cost,
                        cost: service.cost,
                        parentId
                    })
                }
            }
            if (service.relatedServices){
                for (const rs of service.relatedServices) {
                    // skip
                    if (rs.acceptanceOnceOnAllTerm === 1) {
                        if ([...rentContractBillItems].find((v)=>v.billItemServiceId === rs.id)) break;
                    }
                    addToArrangedList(rs, quantity, service.id)
                }
            }
        }
        let overpaidTime = 0;
        do {
            for (const [index, tariff] of sortedTariffsByDuration.entries()) {
                if (tariff.tariff) {
                    overpaidTime = tariff.tariff.overpaidTime;
                    let d = 0;
                    if (tariff.tariff.rentMonth){
                        const b = new Date(begin);
                        const e = addMonth(new Date(begin), parseInt(1))
                        d = (e.getTime() - b.getTime())/1000;
                    } else {
                        d = tariff.tariff.rentTime;
                    }
                    if (d <= diffDuration && (overpaidTime !== Infinity ? overpaidTime: 0) < diffDuration) {
                        diffDuration -= d;
                        addToArrangedList(tariff, 1)
                        break;
                    }
                    if (index === sortedTariffsByDuration.length - 1){
                        diffDuration -= d + tariff.overpaidTime;
                        addToArrangedList(tariff, 1)
                        break;
                    }
                }
            }
        } while (diffDuration > 0 && sortedTariffsByDuration.length > 0)
        return arrangedTariffs;
    }

    const changeSelectedAddTariff = (e) => {
        setSelectedBillTariffAdd(e.target.value)
    }

    return (
        <React.Fragment>
            <PopupDialog maxWidth="sm" open={isOpen} onClose={closeDialog} title={"Работа с арендной платой"}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="rentTariffObjectSelect">Тип размещаемого объекта</InputLabel>
                            <Select
                                fullWidth
                                labelId="rentTariffObjectSelect"
                                value={selectedRentTariffObject || -1}
                                onChange={(e)=> setSelectedRentTariffObject(e.target.value)}
                                label="Тип размещаемого объекта"
                                disabled={isRenew}
                            >
                                <MenuItem value={-1}>
                                    <em>Не выбран</em>
                                </MenuItem>
                                {
                                    rentTariffObjectList.map((v, i) => (
                                        <MenuItem value={v.id}>{`${v.name}`}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                            <DateTimePicker
                                fullWidth
                                // className={classes.filterBoxField}
                                label="Начало периода аренды"
                                inputVariant="outlined"
                                ampm={false}
                                value={contractBeginDatetime ? contractBeginDatetime : null }
                                onChange={(e)=> setContractBeginDatetime(e ? e.toDate() : undefined)}
                                disabled={!isContractBeginDatetimeEditable || isRenew}
                            />
                        </MuiPickersUtilsProvider>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!isContractBeginDatetimeEditable}
                                    onChange={(e)=>{
                                        setContractBeginDatetimeEditable(!isContractBeginDatetimeEditable)
                                    }}
                                    color="primary"
                                    disabled={!licencePlateLastEntryTime || isRenew}
                                />
                            }
                            label="Использовать время из события въезда на парковку"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                            <DateTimePicker
                                fullWidth
                                // className={classes.filterBoxField}
                                label="Конец периода аренды"
                                inputVariant="outlined"
                                ampm={false}
                                value={contractEndDatetime ? contractEndDatetime : null }
                                onChange={(e)=> setContractEndDatetime(e ? e.toDate() : undefined)}
                                disabled={!isContractEndDatetimeEditable || isContractEndDatetimeFromTariff}
                            />
                        </MuiPickersUtilsProvider>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={isContractEndDatetimeFromTariff}
                                    checked={!isContractEndDatetimeEditable}
                                    onChange={(e)=>{
                                        setContractEndDatetimeEditable(!isContractEndDatetimeEditable)
                                    }}
                                    color="primary"
                                />
                            }
                            label="Использовать текущее время"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={contractBillTariff.length === 0 || !isAutofillDisable}
                                    checked={isContractEndDatetimeFromTariff}
                                    onChange={(e)=>{
                                        setContractEndDatetimeFromTariff(!isContractEndDatetimeFromTariff)
                                    }}
                                    color="primary"
                                />
                            }
                            label="Использовать время по сумме тарифов"
                        />
                    </Grid>
                    {
                        contractEndDatetime !== undefined && contractBeginDatetime !== undefined ?
                            contractEndDatetime > contractBeginDatetime ?
                        <React.Fragment>
                            {
                                contractDuration > 0 &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Alert severity="info">Рассчетное время действия договора: <b>{secondsToHuman(contractDuration)}</b></Alert>
                                </Grid>
                            }
                            {
                                contractBillTariff.length > 0 && actualTariffList.length > 0 &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {
                                        contractBillTariff.map((billTariff, billTariffIndex) => (
                                            <ServiceListItem service={billTariff} services={actualTariffList} key={`ct-${billTariffIndex}`} deleteCurrent={removeItemFromContractBillTariff}/>
                                        ))
                                    }
                                </Grid>
                            }

                            {
                                (isAutofillDisable || (contractDuration > contractBillTariffDuration)) &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="tariffAddSelect">Тариф</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="tariffAddSelect"
                                                    value={selectedBillTariffAdd || -1}
                                                    onChange={changeSelectedAddTariff}
                                                    label="Тариф"
                                                    // disabled={isFetching}
                                                >
                                                    <MenuItem value={-1}>
                                                        <em>Не выбран</em>
                                                    </MenuItem>
                                                    {
                                                        actualTariffList.map((v, i) => (
                                                            <MenuItem value={v.id}>{`${v.description} ${penniesToRubles(v.cost)}`}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <div style={{display: "flex"}}>
                                                <TextField
                                                    type={"number"}
                                                    onChange={(e)=>setSelectedBillQuantityAdd(e.target.value)}
                                                    value={selectedBillQuantityAdd || ''}
                                                    label="Кол-во" variant="outlined" />
                                                <Button variant={"contained"} color={"primary"} className={styledClass.ml1} disabled={selectedBillQuantityAdd === undefined || selectedBillQuantityAdd <= 0 || selectedBillTariffAdd === undefined}
                                                        onClick={addItemFromContractBillTariff.bind(null, selectedBillTariffAdd, selectedBillQuantityAdd, actualTariffList)
                                                        }>Добавить</Button>
                                            </div>

                                        </Grid>
                                    </Grid>
                                    {
                                        (contractDuration > contractBillTariffDuration && !isAutofillDisable) &&
                                        <Alert severity="error" className={styledClass.my1}>Список тарифов не покрывает время действия договора. Неоходимо добавить тариф длительностью не менее: <b>{secondsToHuman(contractDuration - contractBillTariffDuration)}</b></Alert>
                                    }

                                </Grid>
                            }

                            {
                                contractDuration <= contractBillTariffDuration && contractCost > 0 &&
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            Итог: {penniesToRubles(contractCost)}
                                        </Typography>
                                    </Grid>
                                    { rentContractBillItemsUponRequest.length > 0 &&
                                        <React.Fragment>
                                            <Typography variant="body2">
                                                Услуги доступны для зачета аванса
                                            </Typography>
                                            {
                                                rentContractBillItemsUponRequest.map((v, i) => (
                                                    // чтоб не морочить голову со сдачей и вычетом, все равно никому не нужно пока
                                                    v.billItemAmount === contractCost &&
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <Card variant="elevation" className={styledClass.my1} key={i} >
                                                                <CardContent>
                                                                    <Typography color="textPrimary" gutterBottom>
                                                                        {v.billItemServiceName}
                                                                    </Typography>
                                                                    <Typography variant="body2">
                                                                        Доступно средств: {penniesToRubles(v.billItemAmount)}
                                                                    </Typography>
                                                                </CardContent>
                                                                {
                                                                    contractAcceptedAdvance.find((v1)=>v1.billItemId === v.billItemId) ?
                                                                        <Button size="small" color={"secondary"} fullWidth onClick={()=>{
                                                                            setContractAcceptedAdvance([])
                                                                        }}>Отменить</Button>
                                                                        :
                                                                        <Button size="small" color={"primary"} fullWidth onClick={()=>{
                                                                            setContractAcceptedAdvance([v])
                                                                        }}>Использовать средства для оплаты</Button>
                                                                }

                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                        contractAcceptedAdvance.reduce((a, v)=> a+v.billItemAmount, 0) === contractCost ?
                                            <Button variant={"contained"} color={"primary"} fullWidth disabled={false} onClick={()=>{
                                                setOpenAcceptConfirmationDialog(true)
                                            }}>Продолжить</Button>
                                            :
                                            <AcceptancePaymentMethod amount={contractCost} accept={(payments)=>{
                                                setContractAcceptedCash(payments.amountCash)
                                                setContractAcceptedCashless(payments.amountCashless)
                                                setOpenAcceptConfirmationDialog(true)
                                            }}/>
                                    }
                                </>

                            }
                        </React.Fragment>
                            :
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Alert severity="error">Время окончания действия договора меньше времени начала</Alert>
                            </Grid>
                            : null
                    }

                </Grid>
            </PopupDialog>

            {
                isOpenAcceptConfirmationDialog &&
                <PopupDialog maxWidth="xs" open={isOpenAcceptConfirmationDialog} onClose={()=>{!isSending && setOpenAcceptConfirmationDialog(false)}} title={`Подтверждение данных`} actionBoard={
                    <React.Fragment>
                        <Button color="default" onClick={()=>{setOpenAcceptConfirmationDialog(false)}} disabled={isSending}>
                            Отменить
                        </Button>
                        <Button color="primary" onClick={submitCurrentContract.bind(this, contractLicencePlate, selectedRentTariffObject, contractBeginDatetime, contractEndDatetime, contractLeaveToDatetimeTariff, contractBillTariff, contractAcceptedCash, contractAcceptedCashless, contractAcceptedAdvance, contractId)} disabled={isSending}>
                            Подтвердить
                        </Button>
                    </React.Fragment>
                }>
                    <Alert severity="warning">
                        Перед продолжением проверьте введенные данные!
                    </Alert>

                    <Card variant="elevation" className={styledClass.my1}>
                        <CardContent>
                            <Typography variant="body1">
                                Номерной знак: <b>{contractLicencePlate}</b>
                            </Typography>
                            <Typography variant="body1">
                                Размещаемый объект: <b>{rentTariffObjectList.find((v)=>v.id===selectedRentTariffObject).name}</b>
                            </Typography>
                            <Typography variant="body1">
                                Начало: <b>{new Date(contractBeginDatetime).toLocaleString()}</b>
                            </Typography>
                            <Typography variant="body1">
                                Конец: <b>{new Date(contractEndDatetime).toLocaleString()}</b>
                            </Typography>
                            <Typography variant="body1">
                                Выезд до: <b>{new Date(contractLeaveToDatetimeTariff).toLocaleString()}</b>
                            </Typography>
                            <Typography variant="body1">
                                Тарифы:
                            </Typography>
                            {   actualTariffList.length > 0 &&
                                contractBillTariff.map((billTariff, billTariffIndex) => (
                                    <Typography key={`tr-${billTariffIndex}`} variant="body1" className={styledClass.ml1}><b>{findService(actualTariffList, billTariff.id).description} - {billTariff.quantity} шт;</b></Typography>
                                ))
                            }
                            <Typography variant="body1">
                                Оплата: <b>{penniesToRubles(contractCost)}</b>
                            </Typography>
                            {
                                contractAcceptedCash > 0 &&
                                <Typography variant="body1" className={styledClass.ml1}>Наличные: <b>{penniesToRubles(contractAcceptedCash)}</b></Typography>
                            }
                            {
                                contractAcceptedCashless > 0 &&
                                <Typography variant="body1" className={styledClass.ml1}>Безналичные: <b>{penniesToRubles(contractAcceptedCashless)}</b></Typography>
                            }
                            {
                                contractAcceptedAdvance.length > 0 &&
                                    contractAcceptedAdvance.map((v, i) => (
                                        <Typography variant="body1" className={styledClass.ml1}>Аванс: <b>{penniesToRubles(v.billItemAmount)}</b></Typography>
                                    ))
                            }
                        </CardContent>
                    </Card>
                </PopupDialog>
            }

        </React.Fragment>

    )
}
