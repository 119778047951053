import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    container: {
        padding: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    dialogBody: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '100%',
        }
    },
}));

export default function PopupDialog(props){
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(props.open);
    }, [props.open])

    function close() {
        props.onClose();
    }

    return (
        <React.Fragment>
            <Dialog maxWidth={props.maxWidth || "md"} fullWidth open={open} PaperProps={{ classes: {root: classes.dialogBody}}}>
                <DialogTitle disableTypography className={classes.root} >
                    <Typography variant="subtitle1" >{props.title}</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                            <CloseIcon />
                        </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    {props.actionBoard}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
