import React, { Component } from 'react'

import { Rect, Symb, syStr, genStr, merger_and, selectIJ, subStr, fit2, dist, merger_or, merger_middle, findeS } from './RouteClasses'
import { tanimoto, tanimotoD, fit } from './RouteClasses'
import { regr, log } from './RouteClasses'

export function routeAll(j) {
    global.it = 0
    route(j.visualizer, 1, j.number, j.id)
}

export const route = (j, type, number = '', id = 0) => {
    let cluster = new Cluster()
    let cluster2 = new Cluster()
    let clId = 0
    if (j.length == 0) return
    let dT0 = performance.now()
    j.forEach((it) => {
        if (clId < it.cluster) {
            clId = it.cluster
            it.cluster = -100 // это заголовок кластера
        }
    })

    j.sort((a, b) => {
        return (a.time - b.time)
    })
    var t0 = j[0].time
    var tl = 0
    let sLi = []
    j.forEach((it) => {
            if (it.cluster < 0) return
                // if (it.time == tl) return
            tl = it.time
            it.t = it.time - t0

            function mapEtalon(s) {
                return s.map((it) => {
                    return new Symb({ S: it.S, R: it.etalon, P: it.confidence })
                })
            }
            let S = mapEtalon(it.symbols)
            S.t = it.t
            S.lpR = it.etalon
            sLi.push(new syStr(S))
        })
        // поворот 
    sLi.forEach((a) => {
        let points = a.map((b) => {
            return b.R.center()
        })
        let k = -regr(points)
        let cos = Math.sqrt(1 / (1 + k * k))
        let sin = Math.sign(k) * Math.sqrt(1 - cos * cos)
        let xC = a[0].R.x
        let yC = a[0].R.y

        function calcSR(it) {
            let x = it.x1
            let y = it.y1
            it.x1 = xC + (x - xC) * cos + (y - yC) * sin + (x - xC) / cos
            it.y1 = yC + (x - xC) * sin + (y - yC) * cos
            it.h /= cos
            it.w /= cos
        }
        a.forEach((b) => calcSR(b.R))
        let y0 = a[0].R.y + a[0].R.h / 2
        a.forEach((b) => {
            b.R.y = y0 - b.R.h / 2
        })
        let lpr1 = new Rect({ x: a.lpR.x1, y: a.lpR.y1, w: 0, h: 0 })
        let lpr2 = new Rect({ x: a.lpR.x2, y: a.lpR.y2, w: 0, h: 0 })
        calcSR(lpr1)
        calcSR(lpr2)
        a.lpR = new Rect({ x1: lpr1.x1, y1: lpr1.y1, x2: lpr2.x1, y2: lpr2.y1 })

    })

    sLi.forEach((a) => {
        if (type == 1) {
            cluster.pushNumS(sC(a), a.t)
        } else {
            cluster.pushNumS(sC(a), a.t)
        }

    })

    // конвертация в формат Web приложения

    cluster.fitAll()

    let res = new genStr()
    let maxCmp = cluster.reduce((a, it) => (a.cmp > it.cmp ? a : it)).cmp
    cluster.forEach((a) => {
        res.addS(a, maxCmp)
    })
    let resN = res.num
    let num = res.map((a) => a)
    if (number == '' || (resN != number && tanimoto(resN, number) > 0.8)) {
        let url = new URL('http://' + window.location.hostname + ':3000/event/' + id)
        console.log(cluster.toLog1, 'Result->', resN, ' instead ', number, num, url.href)
    }

    if (type == 1) {
        let s = res.map((a) => {
            let res = a.S
            res.n = a.li.length
            return res
        })
        s.lpR = cluster[0].lpR
        cluster.length = 0
        s.t = 0
        cluster[0] = new syStr(s)
    }
    let len = 0
    cluster.forEach((it, i) => {
        len++
        j[i].cluster = it.cluster
        j[i].num = it.numT
        j[i].symbols = it.map((x, i) => {
            if (type == 1) {
                return { S: x.S, confidence: x.P.toFixed(2) + ' n=' + x.n, rect: x.R, etalon: x.etalon }
            } else {
                return { S: x.S, confidence: x.P.toFixed(2), rect: x.R, etalon: x.etalon }
            }
        })
    })
    j.splice(len, j.length - len)
        // console.log('performance:', performance.now() - dT0)

    return j
}



function sC(s) {
    return new syStr(s)
}

function cP(a) {
    return JSON.parse(JSON.stringify(a))
}

class Cluster extends Array {
    static get[Symbol.species]() { return Array; }
    constructor() {
        super()
    }

    pushNumS(S, t) {
        let num = ''
        S.forEach((it) => num += it.S)

        if (this.length == 0) {
            S.cluster = 1
            this[0] = S;
            return
        }
        let i = this.length - 1
        var S0 = this[i]
        S.cluster = S0.cluster
        this.push(sC(S))
        var S1 = S

    }
    fitAll() {



        //сотрировка по ширине 
        this.sort((a, b) => b.length - a.length)
        let str = new genStr()
        str.addS(this[0], 0)
        for (let i = 1; i < this.length; i++) {
            let s1 = this[i]
            let T = tanimotoD(new syStr(str.S), s1)
            if (i == 1) this[0].cmp = T[0]
            s1.cmp = T[0]
            str.addS(s1, 0)

        }
        //сотрировка по cmp 
        this.sort((a, b) => b.cmp - a.cmp)
        this.toLog1 = this.map((it) => {
            return {
                S: it,
                num: it.numT,
                cmp: it.cmp
            }
        })
    }

}