import React, { useEffect, useState } from 'react';
import {
    makeStyles, Paper, TableCell,
} from "@material-ui/core";
import {loadRentAccountantReportCashBoxRange} from "../../httpUtils";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "react-bootstrap/Table";
import TableBody from "@material-ui/core/TableBody";
import {getTypeOfPayment, penniesToRubles} from "../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh",
    },
    container: {
        display: "flex",
        flexDirection: 'column',
        margin: theme.spacing(2),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    switchBody: {
        backgroundColor: theme.palette.background.paper,
    }
}));

export default function ReportCashBox(props) {
    const classes = useStyles();
    const [report, setReport] = useState(undefined);

    const loadReport = (begin, end) => {
        loadRentAccountantReportCashBoxRange(begin, end).then((r)=>{
            setReport(r)
        }).catch((e)=>{

        })
    };

    useEffect(()=>{
        if (props.begin && props.end){
            loadReport(new Date(props.begin).toUTCString(), new Date(props.end).toUTCString());
        }
    }, [props.begin, props.end])

    return (
        <React.Fragment>
            {
                report &&
                <TableContainer component={Paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Тип оплаты</TableCell>
                                <TableCell align="right">Итого</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {report.rows.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell align="center">{getTypeOfPayment(row.type)}</TableCell>
                                    <TableCell align="right">{penniesToRubles(row.amount)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={1} align="right"><b>Итого</b></TableCell>
                                <TableCell align="right"><b>{penniesToRubles(report.totalAmount)}</b></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </React.Fragment>
    )
}
