import React, {useCallback, useContext, useEffect, useState} from 'react';
import Menu from '../Header/Menu';
import * as Cookies from "js-cookie";
import socketio from 'socket.io-client';
import {
    CssBaseline,
    ListSubheader,
    makeStyles,
    Paper,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {loadBarrierList} from "../httpUtils";
import List from "@material-ui/core/List";
import BarrierListItem from "./BarrierListItem";
import {Alert, AlertTitle} from "@material-ui/lab";
import {checkSettings, getTypeText} from "../utils";
import {ReactReduxContext} from "react-redux";
import Button from "@material-ui/core/Button";
import LicencePlateCreateDialog from "../LicencePlates/LicencePlateCreateDialog";
import RentAcceptanceDialog from "../Rent/RentAcceptanceDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    paperContainer:{
        width: '100%',
    },
    realtimeEvents: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    actionBar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

export default function Dashboard(){
    const {store} = useContext(ReactReduxContext)
    const classes = useStyles();
    const [barrierList, setBarrierList] = useState([]);
    const [realtimeEventList, setRealtimeEventList] = useState([]);
    const [realtimeEvent, setRealtimeEvent] = useState();
    const [isKeyOpenAllow, setKeyOpenAllow] = useState(false);
    const [isConcierge, setConcierge] = useState(false);
    const [isRentContractFastAvailability, setRentContractFastAvailability] = useState(false);

    const [isOpenRentAcceptanceDialog, setOpenRentAcceptanceDialog] = useState(false);

    store.subscribe(() => {

        if (checkSettings(store.getState().settings, 13)){
            !isKeyOpenAllow && setKeyOpenAllow(true)
        }

        if (checkSettings(store.getState().settings, 21)){
            !isConcierge && setConcierge(true)
        }

        if (checkSettings(store.getState().settings, 22)){
            !isRentContractFastAvailability && setRentContractFastAvailability(true)
        }
    })

    const listener = useCallback(message => {
        setRealtimeEvent(JSON.parse(message));
    }, [])

    useEffect(()=>{
        if (realtimeEvent){
            if (realtimeEventList.length >= 7){
                let prev = [...realtimeEventList];
                prev.pop();
                setRealtimeEventList([realtimeEvent, ...prev]);
            } else {
                setRealtimeEventList([realtimeEvent, ...realtimeEventList]);
            }
            setRealtimeEvent(undefined)
        }
    }, [realtimeEvent]);

    function initPage() {
        loadBarrierList().then((r)=>{
            setBarrierList(r.barrier);
        })
        let opts = {
            query: 'token=' + Cookies.get('token'),
        }
        const webSocket = socketio("wss://api.parking.vipcrm.ru/", opts);
        webSocket.on('notify', listener);
        return () => {
            webSocket.off('notify', listener)
        }
    }

    useEffect(()=>{
        initPage();
    }, []);

    function getSeverityColor(type){
        const types = {
            default: 'info',
            1: 'success',
            2: 'warning',
            3: 'error',
            4: 'warning',
            6: 'warning',
        }
        return types[type] || types.default
    }

    return (
      <React.Fragment>
        <Menu title='Панель управления' reloadData={()=>{
            initPage();
        }}/>
        <CssBaseline />
        <div className={classes.root}>
            {
                isRentContractFastAvailability &&
                <div className={classes.actionBar}>
                    <Button variant="contained" color="primary" onClick={()=>{
                        setOpenRentAcceptanceDialog(true);
                    }}>
                        Договоры аренды
                    </Button>
                </div>
            }

            <Grid container spacing={1}>
                <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper className={classes.paperContainer}>
                        <List
                            component="nav"
                            subheader={
                                <ListSubheader component="div" disableSticky>
                                    Шлагбаумы
                                </ListSubheader>
                            }
                            className={classes.root}
                        >
                            {
                                barrierList.map((b,i)=>(
                                    <BarrierListItem info={b} key={`bli-${i}`} isKeyOpenAllow={isKeyOpenAllow} currentKey={b.bindKey} isConcierge={isConcierge}/>
                                ))
                            }
                        </List>
                    </Paper>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper className={classes.paperContainer}>
                        <List
                            component="nav"
                            subheader={
                                <ListSubheader component="div" disableSticky>
                                    События на парковке
                                </ListSubheader>
                            }
                            className={classes.root}
                        >
                            <div className={classes.realtimeEvents}>
                                {
                                    realtimeEventList.map((l,i)=>(
                                        <Alert key={`alert-${i}`} severity={getSeverityColor(l.status)} action={<strong>{new Date(l.time*1000).toLocaleTimeString()}</strong>}>
                                            <AlertTitle><strong>{l.number}</strong></AlertTitle>
                                            {l.barrier} - <strong>{getTypeText(l.status)}</strong> {l.status === 3 && l.blacklistReason && <>Причина блокировки: <strong>{l.blacklistReason}.</strong></>}
                                        </Alert>
                                    ))
                                }
                            </div>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </div>
          <RentAcceptanceDialog open={isOpenRentAcceptanceDialog} needClose={()=>{setOpenRentAcceptanceDialog(false)}}/>
      </React.Fragment>
    )
}
