
export function ApartmentCreateError(code, apartmentId) {
    this.name = "ApartmentCreateError";
    this.code = code;
    this.apartmentId = apartmentId;
}

export function UnlivedCreateError(code, unlivedId) {
    this.name = "UnlivedCreateError";
    this.code = code;
    this.unlivedId = unlivedId;
}
