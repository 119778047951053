import React, {useContext, useEffect, useState} from 'react';
import {checkSettings, getTypeEvents, getTypeText, showNumber} from "../utils";
import {Accordion, AccordionDetails, Card, CardContent, CardMedia, Link, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import EventVisualizer from "../Parking/EventVisualizer";
import {route} from "../Parking/Route";
import Grid from "@material-ui/core/Grid";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ReactReduxContext} from "react-redux";
import {Alert} from "@material-ui/lab";
import UnlivedInfo from "../Unlived/UnlivedInfo";
import UnlivedLicencePlates from "../Unlived/UnlivedLicencePlates";
import FullScreenDialog from "../component/FullScreenDialog";
import ApartmentLicencePlates from "../Apartment/ApartmentLicencePlates";
import ApartmentOwnersSettings from "../Apartment/ApartmentOwners";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.light
    },
    reactTransformComponent: {
        width: 'auto',
    },
    image: {
        height: '100%',
        width: '100%',
        display: 'block',
        flexShrink: 0,
        objectFit: 'contain',
    },
    infoBlock: {
        // display: 'block',
        padding: theme.spacing(2)
    },
    infoContainer: {
        width: '100%',
    },
    mb2: {
        marginBottom: theme.spacing(2),
    }
}));

export default function EventCard(props){
    const {store} = useContext(ReactReduxContext)

    store.subscribe(() => {
        if (checkSettings(store.getState().settings, 11)){
            setVisualizerAvailable(true)
        }
    })

    const [selectedUnlived, setSelectedUnlived] = useState(undefined);
    const [selectedApartment, setSelectedApartment] = useState(undefined);

    const [isVisualizerAvailable, setVisualizerAvailable] = useState(false);

    const [currentEvent, setCurrentEvent] = useState({})

    useEffect(() => {
        setCurrentEvent(props.current);
        console.log(props.current)
    }, [props.current]);

    const classes = useStyles();
    return(
        <Card className={classes.card}>
            <Grid container>
                <Grid container item xs={12} sm={9} md={9} lg={9} xl={10}>
                    <div className={classes.reactTransformComponent}>
                        <TransformWrapper>
                            <TransformComponent>
                                <CardMedia
                                    component="img"
                                    className={classes.image}
                                    // className={classes.media}
                                    image={"//photo2.parking.vipcrm.ru/"+currentEvent.id}
                                    // image={"/61734d0b7311d05242ecf265.jfif"}
                                    title={`event-${currentEvent.id}`}
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={3} md={3} lg={3} xl={2} className={classes.infoBlock}>
                    <div className={classes.infoContainer}>
                        <Typography gutterBottom variant="h6" component="h5">
                            {showNumber(currentEvent)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <span>{new Date(currentEvent.time).toLocaleString()}</span><br/>
                            {currentEvent.barrier_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.mb2}>
                            <span>{ getTypeEvents(currentEvent.status) }</span><br/>
                            {currentEvent.hasOwnProperty('detectedClass') && <span>Class: {currentEvent.detectedClass}</span> }
                        </Typography>
                        {
                            currentEvent.hasOwnProperty('visualizer') && isVisualizerAvailable && currentEvent.visualizer.length > 0 &&
                            <Accordion className={classes.accordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>Визуализатор</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{display: 'block'}}>
                                    <EventVisualizer id="visualizer-2" key="visualizer-2" visualizer={route(JSON.parse(JSON.stringify(currentEvent.visualizer)),2)} />
                                </AccordionDetails>
                            </Accordion>
                        }
                        {
                            currentEvent.hasOwnProperty('unlived') &&
                            <Grid container spacing={1}>
                                {
                                    currentEvent.unlived.map((v, i)=> (
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Alert variant="outlined" severity="info" className={classes.alert}>
                                                <Link onClick={()=>{
                                                    setSelectedUnlived(v)
                                                }} color="inherit">
                                                    Парковочное место <strong>{v.prefix}-{v.number}</strong>
                                                </Link>
                                            </Alert>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        }
                        {
                            currentEvent.hasOwnProperty('apartment') &&
                            <Grid container spacing={1}>
                                {
                                    currentEvent.apartment.map((v, i)=> (
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Alert variant="outlined" severity="info" className={classes.alert}>
                                                <Link onClick={()=>{
                                                    setSelectedApartment(v)
                                                }} color="inherit">
                                                    Квартира <strong>{v.number}</strong>
                                                </Link>
                                            </Alert>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        }
                        {
                            selectedUnlived !== undefined &&
                            <FullScreenDialog open={true} onClose={()=>{
                                setSelectedUnlived(undefined);
                            }} title={`${selectedUnlived.number}${selectedUnlived.char ? `/${selectedUnlived.char}` : ``} - ${selectedUnlived.prefix}`}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <UnlivedInfo unlivedId={selectedUnlived.id} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <UnlivedLicencePlates unlivedId={selectedUnlived.id} isUpdateLicencePlatesAvailable={()=>{}}/>
                                    </Grid>
                                </Grid>
                            </FullScreenDialog>
                        }
                        {
                            selectedApartment !== undefined &&
                            <FullScreenDialog open={true} onClose={()=>{
                                setSelectedApartment(undefined);
                            }} title={`Квартира: ${selectedApartment.number}`}>
                                <Grid container spacing={0}>
                                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                                        <ApartmentLicencePlates apartmentId={selectedApartment.id} isUpdateLicencePlatesAvailable={()=>{}}/>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                                        <ApartmentOwnersSettings apartmentId={selectedApartment.id} />
                                    </Grid>
                                </Grid>
                            </FullScreenDialog>
                        }
                    </div>
                </Grid>
            </Grid>


        </Card>
    )
}
