import React, {useEffect, useState} from 'react';
import Menu from '../Header/Menu';
import {
    Card, CardActionArea, CardHeader,
    CssBaseline, FormControl, InputLabel,
    makeStyles, MenuItem, Select, TextField
} from "@material-ui/core";
import 'moment/locale/ru';
import {
    bindLicencePlatesToApartment, bindLicencePlatesToUnlived,
    changeStatementStatus,
    findCurrentStatements,
    findStatement, loadAreasList
} from "../httpUtils";
import ScrollToTop from "../component/ScrollToTop";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FullScreenDialog from "../component/FullScreenDialog";
import LicencePlateStatement from "./LicencePlateStatement";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    apartmentAction: {
        margin: theme.spacing(2),
        width: '100%',
    },
}));

export default function Statements() {
    const classes = useStyles();

    const [statementsList, setStatementsList] = useState([]);
    const [openStatement, setOpenStatement] = useState('');

    const [statement, setStatement] = useState({});

    const [statementObjectNumber, setStatementObjectNumber] = useState('');

    const [statementApartmentNumber, setStatementApartmentNumber] = useState('');
    const [statementUnlivedNumber, setStatementUnlivedNumber] = useState('');
    const [statementParkingPlaceNumber, setStatementParkingPlaceNumber] = useState('');
    const [apartmentLicencePlates, setApartmentLicencePlates] = useState([]);

    const [statementComment, setStatementComment] = useState('');

    const [isApartmentLicencePlatesSaving, setApartmentLicencePlatesSaving] = useState(false);

    const [countLicencePlates, setCountLicencePlates] = useState(0);

    const [areaList, setAreaList] = useState([]);
    const [objectArea, setObjectArea] = useState(0);

    async function loadStatements() {
        findCurrentStatements().then(value => {
            setStatementsList(value);
        }).catch(reason => {
            console.warn(reason)
        })
    }

    const loadAreas = () => {
        loadAreasList().then((r)=>{
            setAreaList(r)
            if (r.length === 1) setObjectArea(r[0].id)
        })
    }

    function initPage() {
        loadStatements()
        loadAreas()
    }

    useEffect(()=>{
        findStatement(openStatement).then(value => {
            console.log(value)
            setStatement(value);
            setCountLicencePlates(value.licencePlates.map(v => v.status.id === 1 || v.status.id === 2 ? 1: 0).reduce((a,b)=> a+b));
        }).catch(reason => {
            console.warn(reason)
        })
    }, [openStatement])

    useEffect(()=>{
        initPage()
    }, [])

    function discardStatement() {
        changeStatementStatus(openStatement, 4).then(v=>{
            closeDialog()
            loadStatements()
        }).catch(e=>{

        })
    }

    function closeDialog(){
        setApartmentLicencePlates([]);
        setOpenStatement('');
    }

    const getObjectType = (data, withPrefix, onlyNumber) => {
      if (data.apartmentNumber) return `${withPrefix ? `Номер квартиры`: ''}${(withPrefix && onlyNumber) ? ': ': ''}${onlyNumber ? data.apartmentNumber: ''}`
      if (data.parkingPlaceNumber) return `${withPrefix ? `Номер парковочного места`: ''}${(withPrefix && onlyNumber) ? ': ': ''}${onlyNumber ? data.parkingPlaceNumber: ''}`
      if (data.unlivedNumber) return `${withPrefix ? `Номер нежилого помещения`: ''}${(withPrefix && onlyNumber) ? ': ': ''}${onlyNumber ? data.unlivedNumber: ''}`
    }

    const saveObjectButton = (_apartmentNumber, _parkingPlaceNumber, _unlivedNumber, _licencePlates, _objectArea, _apartmentLicencePlates, _countLicencePlates, _closeDialog, _loadStatements) => {

        const saveLicencePlate = () => {
            if (_apartmentNumber) {
                bindLicencePlatesToApartment(_apartmentNumber, _apartmentLicencePlates).then(v=>{
                    console.log(v)
                    changeStatementStatus(openStatement, 3).then(v=>{
                        _closeDialog()
                        _loadStatements()
                    }).catch(e=>{})
                }).catch(e=>{
                    console.warn(e)
                })
            }
            if (_parkingPlaceNumber || _unlivedNumber) {
                bindLicencePlatesToUnlived((_unlivedNumber && 1 || _parkingPlaceNumber && 2), _objectArea, _unlivedNumber || _parkingPlaceNumber, '', false, apartmentLicencePlates).then(v=>{
                    console.log(v)
                    changeStatementStatus(openStatement, 3).then(v=>{
                        _closeDialog()
                        _loadStatements()
                    }).catch(e=>{})
                }).catch(e=>{
                    console.warn(e)
                })
            }
        }

        let text = undefined;
        if (_apartmentNumber) text = 'Принять заявление и привязать номера к квартире'
        if (_parkingPlaceNumber) text = 'Принять заявление и привязать номера к парковочному месту'
        if (_unlivedNumber) text = 'Принять заявление и привязать номера к нежилому помещению'

        console.log(_countLicencePlates, _apartmentLicencePlates, _objectArea)
        return (
            <Button size="medium" variant='outlined' color="primary" fullWidth
                    disabled={_apartmentLicencePlates.length === 0 || _objectArea === 0}
                    onClick={saveLicencePlate}
                    className={classes.saveButton}>
                {text}
            </Button>
        )
    }

    return (
        <React.Fragment>
            <Menu title='Заявления' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Grid container spacing={1}>
                    { statementsList.map((data, i) => (
                        <Grid container item xs={12} sm={6} md={4} lg={4} xl={4} key={`stm-${i}`}>
                            <Card className={classes.card}>
                                <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                    setOpenStatement(data.uuid);
                                    setStatementObjectNumber(getObjectType(data, false, true))
                                    setStatementApartmentNumber(data.apartmentNumber);
                                    setStatementUnlivedNumber(data.unlivedNumber);
                                    setStatementParkingPlaceNumber(data.parkingPlaceNumber);
                                    setStatementComment(data.comment);
                                }}>
                                    <CardHeader
                                        subheader={
                                            <React.Fragment>
                                                <React.Fragment>
                                                    <Typography>Заявление: {data.id}</Typography>
                                                    <Typography>Ссылка: {data.uuid}</Typography>
                                                    <Typography>{getObjectType(data, true, true)}</Typography>
                                                    <Typography>Статус: {data.lastStatus.status.description}</Typography>
                                                    <Typography>Время изменения статуса: {new Date(data.lastStatus.created_at).toLocaleString()}</Typography>
                                                </React.Fragment>
                                            </React.Fragment>
                                        }
                                    />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            <ScrollToTop/>
            <FullScreenDialog open={openStatement} onClose={closeDialog} title={`Заявление`} subtitle={openStatement}>
                <Grid container spacing={0}>
                    {
                        statementComment &&
                        <Grid container spacing={1} className={classes.apartmentAction}>
                            <TextField
                                disabled={true}
                                fullWidth
                                label={'Комментарий'}
                                variant="outlined"
                                type={"text"}
                                multiline
                                rows={2}
                                value={statementComment}
                            />
                        </Grid>
                    }
                    {
                        statement.hasOwnProperty('licencePlates') && statement.licencePlates.map((data, i) => (
                            <LicencePlateStatement initData={data} statementUUID={openStatement} onSaveAvailable={(licencePlate)=>{
                                setApartmentLicencePlates([...apartmentLicencePlates, licencePlate])
                                console.log(licencePlate)
                            }}/>
                        ))
                    }
                    <Grid container spacing={1} className={classes.apartmentAction}>
                        <Grid item lg={4} md={6} sm={12} xl={6} xs={12}>
                            <TextField
                                disabled={false}
                                fullWidth
                                label={getObjectType({apartmentNumber: statementApartmentNumber, parkingPlaceNumber: statementParkingPlaceNumber, unlivedNumber: statementUnlivedNumber}, true, false)}
                                variant="outlined"
                                type={"number"}
                                value={statementObjectNumber}
                                onChange={(e)=>{setStatementObjectNumber(e.target.value.trim())}}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xl={6} xs={12}>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <InputLabel id="createObjectAreaSelect">Местоположение объекта</InputLabel>
                                <Select
                                    disabled={false}
                                    fullWidth
                                    labelId="createObjectAreaSelect"
                                    value={objectArea}
                                    onChange={(e)=> setObjectArea(e.target.value)}
                                    label="Местоположение объекта"
                                >
                                    <MenuItem value={0}>
                                        <em>Не выбран</em>
                                    </MenuItem>
                                    {
                                        areaList.map((data, i) => (
                                            <MenuItem value={data.id}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xl={6} xs={12}>
                            {saveObjectButton(statementApartmentNumber, statementParkingPlaceNumber, statementUnlivedNumber, apartmentLicencePlates, objectArea, apartmentLicencePlates, countLicencePlates, closeDialog, loadStatements)}
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xl={6} xs={12}>
                            <Button size="medium" variant='outlined' color="secondary" fullWidth disabled={isApartmentLicencePlatesSaving} onClick={discardStatement} className={classes.saveButton}>
                                Отклонить заявление
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </FullScreenDialog>
        </React.Fragment>
    );
}
