import React, {useEffect, useState} from 'react';
import {
    Fab, makeStyles
} from "@material-ui/core";
import NavigationIcon from '@material-ui/icons/Navigation';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

export default function ScrollToTop() {
    const classes = useStyles();
    const [isScrollToTop, setScrollToTop] = useState(false);

    function handleScroll() {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > window.innerHeight){
            setScrollToTop(true)
        }
        else if (scrolled <= window.innerHeight){
            setScrollToTop(false)
        }
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll, false);
        document.addEventListener('touchmove', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
            document.removeEventListener('touchmove', handleScroll, true);
        }
    }, []);

    return (
        <React.Fragment>
            {
                isScrollToTop &&
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    className={classes.fab}
                    onClick={scrollToTop}
                >
                    <NavigationIcon />
                    К началу списка
                </Fab>
            }
        </React.Fragment>
    );
}
