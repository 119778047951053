import React, {useEffect, useState} from 'react';
import Menu from "./Header/Menu";
import {CssBaseline, makeStyles, TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ScrollToTop from "./component/ScrollToTop";
import Table from "@material-ui/core/Table";
import {getReportLpwob} from "./httpUtils";
import json2csv from "./json2csv";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    table: {
        marginTop: theme.spacing(2),
    }

}));

export default function LicencePlatesWithoutBinding() {
    const classes = useStyles();
    const [list, setList] = useState([]);

    function initPage() {
        getReportLpwob().then((r)=>{
            setList(r.sort(function(a, b) {
                const textA = a.licencePlateInfo ? a.licencePlateInfo.toUpperCase(): '';
                const textB = b.licencePlateInfo ? b.licencePlateInfo.toUpperCase(): '';
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }))
        })
    }

    useEffect(()=>{
        initPage();
    }, [])

    return (
        <React.Fragment>
            <Menu title='Номера без привязок к квартирам' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={()=>{
                    json2csv(list, "Номерные знаки без привязки к квартирам.csv", {licencePlate: 'Номерной знак', licencePlateInfo: 'Комментарий'})
                }}>
                    Загрузить
                </Button>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Номерной знак</TableCell>
                                <TableCell>Комментарий</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row, i) => (
                                <TableRow key={row.licencePlate}>
                                    <TableCell component="th" scope="row">
                                        {row.licencePlate}
                                    </TableCell>
                                    <TableCell>{row.licencePlateInfo}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ScrollToTop/>
            </div>
        </React.Fragment>
    )
}

