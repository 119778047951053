import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Menu from '../../Header/Menu';

export default class AreaSettings extends Component {
 constructor() {
   super();
   this.state = {
     list: [],
     showEditDialcog: false,
     showEditDialogModeEdit: false,
     editId: 0,
     editName: '',
     editGuest: false,
     editError: ''
   }
   this.handleLoad = this.handleLoad.bind(this);
   this.handleSubmitArea = this.handleSubmitArea.bind(this);
 }

 handleSubmitArea(action) {
   console.log(action);
   let reqMethod;
   let reqBody;
   let reqUrl;
   switch(action) {
    case 'delete':
      reqMethod='DELETE';
      reqUrl=process.env.REACT_APP_API_URL+'/parking/area/'+this.state.editId;
      reqBody=[];
      break;
    case 'create':
      reqMethod='POST';
      reqUrl=process.env.REACT_APP_API_URL+'/parking/area/';
      reqBody=JSON.stringify({"name" :this.state.editName, "guest" :this.state.editGuest});
      break;
    case 'update':
      reqMethod='PUT';
      reqUrl=process.env.REACT_APP_API_URL+'/parking/area/'+this.state.editId;
      reqBody=JSON.stringify({"name" :this.state.editName, "guest" :this.state.editGuest});
      break;
  }
   fetch(reqUrl, {
     method: reqMethod,
     credentials: 'include',
     body: reqBody,
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => {
     console.log(res.status);
     switch (res.status) {
        case 401:

          break;
        case 403:
          this.setState({editError: "Запрещено!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
        case 409:
          this.setState({editError: "Такая территория существует!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
       default:
       this.setState({showEditDialog: false});
       this.handleLoad();
     }
   })
   .catch(err => {
     console.log(err);
   });
 }

 handleLoad(){
   fetch(process.env.REACT_APP_API_URL+'/parking/area',{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => this.setState({list: res.area}));
 }

 componentDidMount() {
   this.handleLoad()
 }

 render() {
   return (
    <>
      <Container>
        <h3>Территории</h3>
        <ButtonToolbar className="mb-3">
          <Button variant="outline-primary" onClick={ (e) => {
            this.setState({
              showEditDialog: true,
              showEditDialogModeEdit: false,
              editId: 0,
              editName: '',
              editGuest: false
            });
            e.target.blur();
          }}>Новая территория</Button>
        </ButtonToolbar>
        <div>
          <ListGroup className="mb-3">
          {
          this.state.list.map((data, i) => (
            <ListGroup.Item
            key={i}
            action onClick={ (e) => {
              this.setState({
                showEditDialog: true,
                showEditDialogModeEdit: true,
                editId: data.id,
                editName: data.name,
                editGuest: data.guest
              });
              e.target.blur();
            }}>
            <>
              {data.name + "  "}
              {data.guest ? <Badge variant="danger">Гостевая</Badge>: null}
              </>
            </ListGroup.Item>
          ))
          }
          </ListGroup>

          <Modal show={this.state.showEditDialog} onHide={ () => this.setState({showEditDialog : false, showEditDialogModeEdit: false}) } size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{!this.state.showEditDialogModeEdit ? <>Новая территория</> : <>Редактировать территорию</> }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formAreaName">
                  <Form.Label>Название</Form.Label>
                  <Form.Control required type="text" placeholder="Парковка у дома" value={this.state.editName} onChange={(event)=>{ this.setState({editName: event.target.value}) }}/>
                </Form.Group>
                <Form.Group controlId="formAreaGuest">
                  <Form.Check type="checkbox" label="Гостевая парковка" checked={this.state.editGuest} onChange={(event)=>{ this.setState({editGuest: event.target.checked}) }}/>
                </Form.Group>
              </Form>
              {this.state.editError ?
                <Alert variant="danger">
                  {this.state.editError}
                </Alert>
              :null }
            </Modal.Body>
            <Modal.Footer>
              
              <Button variant="primary" onClick={this.handleSubmitArea.bind(this, this.state.showEditDialogModeEdit ? 'update' : 'create')}>
                {!this.state.showEditDialogModeEdit ? <>Добавить</> : <>Сохранить</> }
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
   );
 }
}
