import React, { useEffect, useState } from 'react';
import Menu from "../Header/Menu";
import {
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
import {getGuestJournal} from "../httpUtils";
import SaveIcon from "@material-ui/icons/Save";
import json2csv from "../json2csv";
import Button from "@material-ui/core/Button";

const columns = [
    {
        field: 'date',
        headerName: 'Время',
        type: 'date',
        width: 200,
    },
    {
        field: 'barrier',
        headerName: 'Шлагбаум',
        width: 200,
    },
    {
        field: 'comment',
        headerName: 'Комментарий',
        flex: 1,
        minWidth: 500,
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh"
    },
    container: {
        display: "flex",
        flex: 1,
        margin: theme.spacing(2),
        flexDirection: 'column'
    },
    buttonContainer: {
        marginBottom: theme.spacing(2),
    }
}));

export default function Guest() {
    const classes = useStyles();
    const [list, setList] = useState([]);

    function initPage() {
        getGuestJournal().then((r)=>{
            setList(r.map((v)=>{
                v.date = new Date(`${v.date}Z`).toLocaleString().replace(',', ' ')
                return v
            }))

        })
    }

    useEffect(()=>{
        initPage();
    }, [])


    return (
        <React.Fragment>
            <div className={classes.root}>
                <Menu title='Гостевой журнал' reloadData={()=>{
                    initPage();
                }}/>
                <CssBaseline />
                <div className={classes.container}>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={()=>{
                            json2csv(list, `Гостевой журнал ${new Date().toLocaleString()}.csv`, {date: 'Дата и время', barrier: 'Шлагбаум', comment: 'Комментарий'})
                        }}>
                            Загрузить
                        </Button>
                    </div>

                    <DataGrid
                        rows={list}
                        columns={columns}
                        autoPageSize
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </React.Fragment>
    )
}
