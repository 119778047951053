import React, {useEffect, useState} from 'react';
import {CssBaseline, makeStyles, TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import Menu from "../Header/Menu";
import {getReportQuotas} from "../httpUtils";
import json2csv from "../json2csv";
import ScrollToTop from "../component/ScrollToTop";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    table: {
        marginTop: theme.spacing(2),
    }

}));

export default function Quotas() {
    const classes = useStyles();
    const [list, setList] = useState([]);

    function drawLicencePlates(licencePlates){
        return licencePlates.map((n)=>{
            return `${n.licencePlate}${ !n.confirmedOwner ? ` (БЕЗ КОНТАКТНЫХ ДАННЫХ)` : `` }`
        }).join(', ')
    }

    function initPage() {
        getReportQuotas().then((r)=>{
            setList(r.map((v)=>{
                return {
                    apartmentNumber: v.apartmentNumber,
                    licencePlatesCount: v.numbers.length,
                    licencePlates: drawLicencePlates(v.numbers)
                }
            }))
        })
    }

    useEffect(()=>{
        initPage();
    }, [])

    return (
        <React.Fragment>
            <Menu title='Квоты по квартирам' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={()=>{
                    json2csv(list, "Квоты по квартирам.csv", {apartmentNumber: 'Номер квартиры', licencePlatesCount: 'Кол-во авто', licencePlates: 'Номерные знаки'})
                }}>
                    Загрузить
                </Button>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Квартира</TableCell>
                                <TableCell>Кол-во авто</TableCell>
                                <TableCell>Номерные знаки</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row, i) => (
                                <TableRow key={row.apartmentNumber}>
                                    <TableCell component="th" scope="row">
                                        {row.apartmentNumber}
                                    </TableCell>
                                    <TableCell>{row.licencePlatesCount}</TableCell>
                                    <TableCell>{row.licencePlates}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ScrollToTop/>
            </div>
        </React.Fragment>
    )
}
