import React, {Component, useContext, useEffect, useState} from 'react';
import {
  AppBar,
  Collapse,
  Drawer, FormControl,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles, MenuItem, Select,
  Tooltip
} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AppsIcon from '@material-ui/icons/Apps';
import MenuIcon from '@material-ui/icons/Menu';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BallotIcon from '@material-ui/icons/Ballot';
import VideocamIcon from '@material-ui/icons/Videocam';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import BugReportIcon from '@material-ui/icons/BugReport';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MoneyIcon from '@material-ui/icons/Money';
import DescriptionIcon from '@material-ui/icons/Description';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PeopleIcon from '@material-ui/icons/People';

import {ReactReduxContext, useDispatch} from "react-redux";
import {changeTheme, changeUserSettings} from "../store/actionCreators";
import {CropFree, ExpandLess, ExpandMore} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {checkSettings, getTypeEvents} from "../utils";
import {getFeatures, getMenuParkings, sendLogout, setMenuParking} from "../httpUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested2: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Menu(props) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false)

  const {store} = useContext(ReactReduxContext)
  const [settings, setSettings] = useState(store.getState().settings)
  const [theme, setTheme] = useState(store.getState().theme)
  const dispatch = useDispatch()

  const [availableParkings, setAvailableParkings] = useState([]);
  const [currentParkingId, setCurrentParkingId] = useState(0);

  function getCurrentFeatures() {
    getFeatures()
        .then(async res => {
          console.log(res.functions)
          dispatch(changeUserSettings(res.functions))
          setSettings(res.functions)
        })
        .catch(err => {
          window.location.href = "/login";
        });
  }

  useEffect(()=>{
    getCurrentFeatures();
    getMenuParkings().then((res)=>{
      setAvailableParkings(res.availableParkings);
      setCurrentParkingId(res.currentParkingId);
    }).catch((err) => {
      window.location.href = "/login";
    });
  }, [])

  function handleChangeCurrentParking(parking){
    setMenuParking(parking).then((r)=>{
      props.hasOwnProperty('reloadData') && props.reloadData();
      getCurrentFeatures();
    }).catch((e)=>{
      window.location.href = "/login";
    })
  }

  function handleLogout(){
    sendLogout()
        .then(res => {window.location.reload()})
  }

  const [openSettings, setOpenSettings] = React.useState(false);
  const [openNumbers, setOpenNumbers] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const [openRent, setOpenRent] = React.useState(false);
  const [openRentReports, setOpenRentReports] = React.useState(false);

   return (
     <>
       <AppBar position="static">
         {
           availableParkings.length > 1 &&
           <div style={{padding: '10px'}}>
             <FormControl fullWidth variant="outlined" className={classes.formControl}>
               <InputLabel id="currentParkingSelections">Паркинг</InputLabel>
               <Select
                   fullWidth
                   labelId="currentParkingSelections"
                   value={currentParkingId}
                   onChange={(e)=> {
                     setCurrentParkingId(e.target.value);
                     handleChangeCurrentParking(e.target.value);
                   }}
                   label="Паркинг"
               >
                 {
                   availableParkings.map((it, i)=> (
                       <MenuItem value={it.parkingId}>{it.parkingName}</MenuItem>
                   ))
                 }
               </Select>
             </FormControl>
           </div>
         }

         <Toolbar>
           {
             theme == 'light' ?
                 <Tooltip title="Переключить на темную тему" placement={"left"} interactive leaveDelay={1500} className={classes.menuButton}>
                   <Brightness4Icon onClick={()=>{
                     dispatch(changeTheme('dark'))
                     setTheme('dark')
                   }}/>
                 </Tooltip>
                 : theme == 'dark' ?
                     <Tooltip title="Переключить на светлую тему" placement={"left"} interactive leaveDelay={1500} className={classes.menuButton}>
                       <Brightness5Icon onClick={()=>{
                         dispatch(changeTheme('light'))
                         setTheme('light')
                       }}/>
                     </Tooltip>
                     : null
           }
           <Typography variant="h6" className={classes.title}>
             {props.title}
             {props.subtitle &&
               <Typography variant="body2">
                 {props.subtitle}
               </Typography>
             }
           </Typography>
           <IconButton edge="start" color="inherit" aria-label="menu" onClick={()=>setOpenMenu(true)}>
             <MenuIcon />
           </IconButton>

         </Toolbar>
       </AppBar>
       <Drawer anchor={"right"} open={openMenu} onClose={()=>setOpenMenu(false)}>
         <List
             component="nav"
             aria-labelledby="nested-list-subheader"
             className={classes.root}
         >
           {
             checkSettings(settings,9) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/'}>
               <ListItemIcon>
                 <AppsIcon/>
               </ListItemIcon>
               <ListItemText primary="События"/>
             </ListItem>
           }
           {
             checkSettings(settings,2) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/dashboard'}>
               <ListItemIcon>
                 <EditAttributesIcon />
               </ListItemIcon>
               <ListItemText primary="Панель управления" />
             </ListItem>
           }
           {
             (checkSettings(settings,6) || checkSettings(settings,18)) &&
                 <React.Fragment>
                   <ListItem button onClick={(e)=>setOpenSettings(!openSettings)}>
                     <ListItemIcon>
                       <SettingsIcon />
                     </ListItemIcon>
                     <ListItemText primary="Настройки" />
                     {openSettings ? <ExpandLess /> : <ExpandMore />}
                   </ListItem>
                   <Collapse in={openSettings} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                       {
                         (checkSettings(settings,6) || checkSettings(settings,18)) &&
                         <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/settings/area'}>
                           <ListItemIcon>
                             <CropFree />
                           </ListItemIcon>
                           <ListItemText primary="Территории" />
                         </ListItem>
                       }
                       {
                         checkSettings(settings,6) &&
                         <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/settings/portal'}>
                           <ListItemIcon>
                             <SettingsEthernetIcon />
                           </ListItemIcon>
                           <ListItemText primary="Порталы" />
                         </ListItem>
                       }
                       {
                         checkSettings(settings,6) &&
                         <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/settings/barrier'}>
                           <ListItemIcon>
                             <VerticalAlignCenterIcon />
                           </ListItemIcon>
                           <ListItemText primary="Барьеры" />
                         </ListItem>
                       }
                       {/*{*/}
                       {/*  checkSettings(settings,6) &&*/}
                       {/*  <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/settings/camera'}>*/}
                       {/*    <ListItemIcon>*/}
                       {/*      <VideocamIcon />*/}
                       {/*    </ListItemIcon>*/}
                       {/*    <ListItemText primary="Камеры" />*/}
                       {/*  </ListItem>*/}
                       {/*}*/}
                       {
                           checkSettings(settings,6) &&
                           <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/settings/cameras'}>
                             <ListItemIcon>
                               <VideocamIcon />
                             </ListItemIcon>
                             <ListItemText primary="Камеры" />
                           </ListItem>
                       }
                       {
                         checkSettings(settings,6) &&
                         <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/settings/endpoints'}>
                           <ListItemIcon>
                             <BugReportIcon />
                           </ListItemIcon>
                           <ListItemText primary="Устройства обработки видео" />
                         </ListItem>
                       }

                     </List>
                   </Collapse>
                 </React.Fragment>
           }
           {
             checkSettings(settings,5) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/licence-plates'}>
               <ListItemIcon>
                 <BallotIcon />
               </ListItemIcon>
               <ListItemText primary="Номерные знаки" />
             </ListItem>
           }
           {
             checkSettings(settings,4) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/apartment'}>
               <ListItemIcon>
                 <HomeWorkIcon />
               </ListItemIcon>
               <ListItemText primary="Квартиры" />
             </ListItem>
           }
           {
             checkSettings(settings,19) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/unlived'}>
               <ListItemIcon>
                 <LocalParkingIcon />
               </ListItemIcon>
               <ListItemText primary="Нежилые объекты" />
             </ListItem>
           }
           {
             checkSettings(settings,3) &&
             <React.Fragment>
               <ListItem button onClick={(e)=>setOpenReports(!openReports)} >
                 <ListItemIcon>
                   <AssessmentIcon />
                 </ListItemIcon>
                 <ListItemText primary="Отчеты" />
                 {openReports ? <ExpandLess /> : <ExpandMore />}
               </ListItem>
               <Collapse in={openReports} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>
                   {
                     checkSettings(settings,3) &&
                     <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/reports/quotas'}>
                       <ListItemIcon>
                         <AssessmentIcon />
                       </ListItemIcon>
                       <ListItemText primary="Квоты по квартирам" />
                     </ListItem>
                   }
                   {
                     checkSettings(settings,3) &&
                     <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/reports/guests'}>
                       <ListItemIcon>
                         <AssessmentIcon />
                       </ListItemIcon>
                       <ListItemText primary="Чужие номера на парковке" />
                     </ListItem>
                   }
                   {
                     checkSettings(settings,3) &&
                     <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/reports/lpwow'}>
                       <ListItemIcon>
                         <AssessmentIcon />
                       </ListItemIcon>
                       <ListItemText primary="Номера без контактных данных" />
                     </ListItem>
                   }
                   {
                     checkSettings(settings,3) &&
                     <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/reports/lpwob'}>
                       <ListItemIcon>
                         <AssessmentIcon />
                       </ListItemIcon>
                       <ListItemText primary="Номера без привязок к квартирам" />
                     </ListItem>
                   }
                   {
                       checkSettings(settings,3) &&
                       <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/reports/lpwkc'}>
                         <ListItemIcon>
                           <AssessmentIcon />
                         </ListItemIcon>
                         <ListItemText primary="Номера с привязкой к пультам / меткам" />
                       </ListItem>
                   }

                 </List>
               </Collapse>
             </React.Fragment>
           }
           {
               checkSettings(settings,23) &&
               <React.Fragment>
                 <ListItem button onClick={(e)=>setOpenRent(!openRent)} >
                   <ListItemIcon>
                     <MoneyIcon />
                   </ListItemIcon>
                   <ListItemText primary="Аренда" />
                   {openRent ? <ExpandLess /> : <ExpandMore />}
                 </ListItem>
                 <Collapse in={openRent} timeout="auto" unmountOnExit>
                   <List component="div" disablePadding>
                     {
                         checkSettings(settings,23) &&
                         <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/rent/contract'}>
                           <ListItemIcon>
                             <DescriptionIcon />
                           </ListItemIcon>
                           <ListItemText primary="Договоры" />
                         </ListItem>
                     }
                     {
                         checkSettings(settings,26) &&
                         <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/rent/services'}>
                           <ListItemIcon>
                             <FormatListNumberedIcon />
                           </ListItemIcon>
                           <ListItemText primary="Услуги" />
                         </ListItem>
                     }
                   </List>
                   <List component="div" disablePadding>
                     {
                         checkSettings(settings,23) &&
                         <>
                           <ListItem button onClick={(e)=>setOpenRentReports(!openRentReports)} className={classes.nested}>
                             <ListItemIcon>
                               <AssessmentIcon />
                             </ListItemIcon>
                             <ListItemText primary="Отчеты по аренде" />
                             {openRentReports ? <ExpandLess /> : <ExpandMore />}
                           </ListItem>
                           <Collapse in={openRentReports} timeout="auto" unmountOnExit>
                             <List component="div" disablePadding className={classes.nested2}>
                               {
                                   checkSettings(settings,24) &&
                                   <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/rent/report/cashier'}>
                                     <ListItemIcon>
                                       <AssessmentIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Кассовые отчеты" />
                                   </ListItem>
                               }
                             </List>
                             <List component="div" disablePadding className={classes.nested2}>
                               {
                                   checkSettings(settings,25) &&
                                   <ListItem button className={classes.nested} style={{ textDecoration: 'none' }} component={Link} to={'/rent/report/accountant'}>
                                     <ListItemIcon>
                                       <AssessmentIcon />
                                     </ListItemIcon>
                                     <ListItemText primary="Бухгалтерские отчеты" />
                                   </ListItem>
                               }
                             </List>
                           </Collapse>
                         </>
                     }
                   </List>
                 </Collapse>
               </React.Fragment>
           }
           {
               checkSettings(settings,20) &&
               <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/guest'}>
                 <ListItemIcon>
                   <LibraryBooksIcon />
                 </ListItemIcon>
                 <ListItemText primary="Гостевой журнал" />
               </ListItem>
           }
           {
             checkSettings(settings,10) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/statements'}>
               <ListItemIcon>
                 <ContactMailIcon />
               </ListItemIcon>
               <ListItemText primary="Заявления" />
             </ListItem>
           }
           {
             checkSettings(settings,14) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/crmJournal'}>
               <ListItemIcon>
                 <MenuBookIcon />
               </ListItemIcon>
               <ListItemText primary="Журнал действий CRM" />
             </ListItem>
           }
           {
             checkSettings(settings,17) &&
             <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/callJournal'}>
               <ListItemIcon>
                 <DialerSipIcon />
               </ListItemIcon>
               <ListItemText primary="Журнал звонков" />
             </ListItem>
           }
           {
               checkSettings(settings,1000000) &&
               <ListItem button style={{ textDecoration: 'none' }} component={Link} to={'/admin/customers'}>
                 <ListItemIcon>
                   <PeopleIcon />
                 </ListItemIcon>
                 <ListItemText primary="Пользователи" />
               </ListItem>
           }
           <ListItem button onClick={()=>{
             handleLogout();
           }}>
             <ListItemIcon>
               <ExitToAppIcon />
             </ListItemIcon>
             <ListItemText primary="Выход" />
           </ListItem>

         </List>
       </Drawer>

     </>
   );
}
