import React, { useEffect, useState } from 'react';
import Menu from "../Header/Menu";
import {
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
import {getCalls, getLogs} from "../httpUtils";

const columns = [
    {
        field: 'when',
        headerName: 'Время',
        type: 'date',
        width: 200,
        valueFormatter: (params) => {
            return new Date(params.value).toLocaleString()
        },
    },
    {
        field: 'dst',
        headerName: 'Куда звонил',
        width: 170,
    },
    {
        field: 'src',
        headerName: 'Кто звонил',
        width: 170,
    },
    {
        field: 'onCall',
        headerName: 'Статус',
        width: 150,
        valueGetter: (params) => {
            return `${params.data.onCall.status}`
        }
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100vh"
    },
    container: {
        display: "flex",
        flex: 1,
        margin: theme.spacing(2),
    }
}));

export default function Calls() {
    const classes = useStyles();
    const [list, setList] = useState([]);

    function initPage() {
        getCalls().then((r)=>{
            setList(r)
        })
    }

    useEffect(()=>{
        initPage();
    }, [])


    return (
        <React.Fragment>
            <div className={classes.root}>
                <Menu title='Журнал звонков' reloadData={()=>{
                    initPage();
                }}/>
                <CssBaseline />
                <div className={classes.container}>
                    <DataGrid
                        rows={list}
                        columns={columns}
                        // autoHeight
                        // pageSize={10}
                        autoPageSize
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </React.Fragment>
    )
}
