import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Menu from '../../Header/Menu';

import CameraAreaSettings from './CameraArea';

export default class CameraSettings extends Component {
 constructor() {
   super();
   this.state = {
     cameraList: [],
     barrier: [],
     portal: [],
     showEditDialog: false,
     showEditDialogModeEdit: false,
     editId: 0,
     editBarrier: 0,
     editArea: [],
     editLokAt: 0,
     editStream: '',
     editFPS: 5,
     editError: '',
     editAreaDetect: []
   }
   this.handleLoad = this.handleLoad.bind(this);
   this.loadBarrier = this.loadBarrier.bind(this);
 }

 handleSubmitArea(action) {
   console.log(this.state.editArea);
   let reqMethod;
   let reqBody;
   let reqUrl;
   switch(action) {
    case 'delete':
      reqMethod='DELETE';
      reqUrl = process.env.REACT_APP_API_URL+'/parking/camera';
      reqBody=JSON.stringify({"id" :this.state.editId});
      break;
    case 'create':
      reqMethod='POST';
      reqUrl = process.env.REACT_APP_API_URL+'/parking/camera';
      reqBody=JSON.stringify({ "barrier" :this.state.editBarrier, "area" :this.state.editLokAt });
      break;
    case 'update':
      reqMethod='PUT';
      reqUrl = process.env.REACT_APP_API_URL+'/parking/camera/'+this.state.editId;
      reqBody=JSON.stringify({"area" :this.state.editLokAt, "barrier" :this.state.editBarrier, "stream" :this.state.editStream, "detect": this.state.editAreaDetect, fps: this.state.editFPS});
      break;
  }
   fetch(reqUrl, {
     method: reqMethod,
     credentials: 'include',
     body: reqBody,
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => {
     console.log(res.status);
     switch (res.status) {
        case 401:

          break;
        case 403:
          this.setState({editError: "Запрещено!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
        case 409:
          this.setState({editError: "Такое уже есть!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
       default:
       this.setState({showEditDialog: false});
       this.handleLoad();
     }
   })
   .catch(err => {
     console.log(err);
   });
 }

 handleLoad(){
   fetch(process.env.REACT_APP_API_URL+'/parking/camera',{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => this.setState({cameraList: res.camera}));
 }

loadBarrier(){
  fetch(process.env.REACT_APP_API_URL+'/parking/barrier/extended',{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .then(res => this.setState({
    barrier: res.barrier,
    editBarrier: this.state.editBarrier == 0 ? res.barrier[0].id: this.state.editBarrier,
    editArea: res.barrier.find(s => (s.id == this.state.editBarrier == 0 ? res.barrier[0].id: this.state.editBarrier))}));
}

 componentDidMount() {
   this.handleLoad()
 }

 render() {
   // console.log(this.state.barrier.filter((s) => s.id == 1));
   // console.log(this.state.editArea);
   return (
    <>
      <Container>
        <h3>Камеры</h3>
        <ButtonToolbar className="mb-3">
          <Button variant="outline-primary" onClick={ (e) => {
            this.setState({
              showEditDialog: true,
              showEditDialogModeEdit: false,
              editId: 0,
              editBarrier: 0,
              editAreaDetect: [],
              editLokAt:0,
            });
            this.loadBarrier();

            e.target.blur();
          }}>Новая камера</Button>
        </ButtonToolbar>
        <div>
          <ListGroup className="mb-3">
          {
          this.state.cameraList.map((data, i) => (
            <ListGroup.Item
            key={i}
            action onClick={ (e) => {
              this.setState({
                showEditDialog: true,
                showEditDialogModeEdit: true,
                editId: data.camera_id,
                editBarrier: data.barrier_id,
                editAreaDetect: [],
                editLokAt: data.area,
                editStream: data.stream,
                editFPS: data.fps
              });
              this.loadBarrier();

              e.target.blur();
            }}>
            <>
              {data.name}
              {"  -  "}
              {data.areaT ? data.areaT : "Улица"}
              </>
            </ListGroup.Item>
          ))
          }
          </ListGroup>

          <Modal show={this.state.showEditDialog} onHide={ () => this.setState({showEditDialog : false, showEditDialogModeEdit: false}) } size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{!this.state.showEditDialogModeEdit ? <>Новая камера</> : <>Настройка камеры</> }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="barrierName">
                  <Form.Label>Управляет барьером</Form.Label>
                  <Form.Control as="select" onChange={(e) => { this.setState({editBarrier: e.target.value, editArea : this.state.barrier.find((s) => (s.id == e.target.value), this) }) }} value={this.state.editBarrier}>
                     {
                       this.state.barrier.map((data, j) => (
                         <option key={j} name={data.name} value={data.id}>{data.name}</option>
                       ))
                     }
                 </Form.Control>
                </Form.Group>
                <Form.Group controlId="barrierPortal">
                 <Form.Label>Наблюдает за</Form.Label>
                  <Form.Control as="select" onChange={(e) => { this.setState({editLokAt : e.target.value }) }} value={this.state.editLokAt}>
                    <option value={this.state.editArea.from_area }>{this.state.editArea.from_area != 0 ? this.state.editArea.from_areaT: "Улица"}</option>
                    <option value={this.state.editArea.to_area }>{this.state.editArea.to_area != 0 ? this.state.editArea.to_areaT: "Улица"}</option>
                 </Form.Control>
                </Form.Group>
                {this.state.showEditDialogModeEdit ?
                  <>
                    <Form.Group controlId="formCameraURL">
                      <Form.Label>Поток</Form.Label>
                      <Form.Control required type="text" placeholder="rtsp://" value={this.state.editStream} onChange={(event)=>{ this.setState({editStream: event.target.value}) }}/>
                    </Form.Group>

                    <Form.Group controlId="formCameraFPS">
                      <Form.Label>FPS</Form.Label>
                      <Form.Control required type="number" value={this.state.editFPS} onChange={(event)=>{ this.setState({editFPS: event.target.value}) }}/>
                    </Form.Group>

                    <CameraAreaSettings camera={this.state.editId} areaEdited={ (e) => {this.setState({editAreaDetect: e})} }/>
                  </>
                  : null
                }
              </Form>
              {this.state.editError ?
                <Alert variant="danger">
                  {this.state.editError}
                </Alert>
              :null }
            </Modal.Body>
            <Modal.Footer>

              <Button variant="primary" onClick={this.handleSubmitArea.bind(this, this.state.showEditDialogModeEdit ? 'update' : 'create')}>
                {!this.state.showEditDialogModeEdit ? <>Добавить</> : <>Сохранить</> }
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
   );
 }
}
