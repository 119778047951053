import React from 'react';


import './StateItem.css';

class StateItem extends React.Component {
  constructor(props) {
      super(props);
      this.color = this.color.bind(this);
      this.overlay = this.overlay.bind(this);
  }

  color(){
      if(this.props.data.status == 0){
        return '#9E9E9E';
      } else if(this.props.data.status == 1){
        return '#4CAF50';
      } else if(this.props.data.status == 2){
        return '#FFC107';
      }
      return '#9E9E9E';
  }

  overlay(){
      if(this.props.data.status == 0){
        return 'Офлайн с '+this.props.data.last_online;
      } else if(this.props.data.status == 1){
        return 'Онлайн';
      } else if(this.props.data.status == 2){
        return 'На устройстве ведутся работы!';
      }
      return 'Офлайн с '+this.props.data.last_online;
  }

  render() {
    return (
        <div class="status" style={{ backgroundColor: this.color(), margin: "auto", marginRight: "0" }}></div>
    );
  }
}

export default StateItem;
