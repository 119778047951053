import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LicencePlateInput from "../component/LicencePlate/LicencePlateInput";
import {Accordion, AccordionDetails, Link, makeStyles, TextField} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import LicencePlateAllowedArea from "./LicencePlateAllowedArea";
import {Alert} from "@material-ui/lab";
import PopupDialog from "../component/PopupDialog";
import {createLicencePlateWithInfo, getUICreateLicencePlateDisallowArea, isLicencePlateKnown} from "../httpUtils";
import PhoneNumberInput from "../component/PhoneNumber/PhoneNumberInput";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        expanded: {
            margin: theme.spacing(1)
        }
    },
    gridBox: {
        marginBottom: theme.spacing(1),
    },
    alert: {
        marginTop: theme.spacing(1),
    }
}));

export default function LicencePlateCreateDialog(props) {
    const classes = useStyles();
    const [isNeedLicencePlateCreate, setNeedLicencePlateCreate] = useState(false);
    const [createdLicencePlateAlertType, setCreatedLicencePlateAlertType] = useState(-1);
    const [isLicencePlateCreating, setLicencePlateCreating] = useState(false);
    const [createdLicencePlateError, setCreatedLicencePlateError] = useState('');
    const [isLicencePlateExist, setLicencePlateExist] = useState(false);

    const [createdLicencePlate, setCreatedLicencePlate] = useState('');
    const [createdLicencePlateInfo, setCreatedLicencePlateInfo] = useState('');
    const [createdLicencePlateOwnerName, setCreatedLicencePlateOwnerName] = useState('');
    const [createdLicencePlateOwnerPhone, setCreatedLicencePlateOwnerPhone] = useState('');
    const [createdLicencePlateOversizeVehicle, setCreatedLicencePlateOversizeVehicle] = useState(false);

    const [successLicencePlateCreated, setSuccessLicencePlateCreated] = useState('');
    const [isLockAfterAdding, setLockAfterAdding] = useState(false);

    const [isLicencePlateNeedAreaSave, setLicencePlateNeedAreaSave] = useState(false);

    const [isOneCreateOnly, setOneCreateOnly] = useState(false);

    const [uiDisallowAreaDefault, setUiDisallowAreaDefault] = useState([]);

    useEffect(()=>{
        getUICreateLicencePlateDisallowArea().then(r => {
            setUiDisallowAreaDefault(r)
        })
    }, []);

    useEffect(()=>{
        setNeedLicencePlateCreate(props.isNeedLicencePlateCreate);
    }, [props.isNeedLicencePlateCreate]);

    useEffect(()=>{
        setCreatedLicencePlate(props.initLicencePlate);
    }, [props.initLicencePlate]);

    useEffect(()=>{
        setOneCreateOnly(props.oneCreateOnly);
    }, [props.oneCreateOnly]);

    useEffect(()=>{
        if(isLicencePlateNeedAreaSave) setLicencePlateNeedAreaSave(false)
    }, [isLicencePlateNeedAreaSave]);

    useEffect(()=>{
        if (successLicencePlateCreated && createdLicencePlateAlertType !== 2) {
            setCreatedLicencePlateAlertType(-1);
            setCreatedLicencePlateInfo('');
            setSuccessLicencePlateCreated('');
            setLockAfterAdding(false);
        }
    }, [createdLicencePlateAlertType, createdLicencePlate, createdLicencePlateInfo])

    useEffect(()=>{
        setCreatedLicencePlateAlertType(-1);
        setLicencePlateExist(false);
        if (createdLicencePlate){
            isLicencePlateKnown(createdLicencePlate).then((r)=>{
                if (r) {
                    setCreatedLicencePlateAlertType(1);
                    setLicencePlateExist(true);
                } else {
                    setLicencePlateExist(false);
                }
            }).catch((e)=>{
                // console.log(e)
            })
        }
    }, [createdLicencePlate])

    function closeNewLicencePlateDialog() {
        props.hasOwnProperty('isNeedClose') && props.isNeedClose(successLicencePlateCreated);
        setNeedLicencePlateCreate(false);
    }

    function openEditLicencePlate() {
        props.hasOwnProperty('isNeedOpenEditLicencePlate') && props.isNeedOpenEditLicencePlate(createdLicencePlateAlertType === 1 ? createdLicencePlate: successLicencePlateCreated);
    }

    function needReload(){
        props.hasOwnProperty('isNeedReload') && props.isNeedReload();
    }

    function returnCreatedLicencePlate(licencePlate) {
        props.hasOwnProperty('createdLicencePlate') && props.createdLicencePlate(licencePlate)
    }

    function createLicencePlate() {
        setLicencePlateCreating(true);
        // createLicencePlateWithInfo(createdLicencePlate, createdLicencePlateInfo, createdLicencePlateOwnerName, createdLicencePlateOwnerPhone, createdLicencePlateOversizeVehicle).then((r)=>{
        createLicencePlateWithInfo(createdLicencePlate, createdLicencePlateInfo, createdLicencePlateOwnerName, createdLicencePlateOwnerPhone, '', false).then((r)=>{
            setCreatedLicencePlateAlertType(0);
            setSuccessLicencePlateCreated(r);
            setLicencePlateNeedAreaSave(true);
            needReload();
            setLockAfterAdding(true);
            setLicencePlateCreating(false);
            if (isOneCreateOnly){
                returnCreatedLicencePlate(r);
                closeNewLicencePlateDialog();
            }
        }).catch((e)=>{
            if (e.code === 409){
                setCreatedLicencePlateAlertType(1);
                setSuccessLicencePlateCreated(e.licencePlate);
            } else {
                setCreatedLicencePlateAlertType(2);
                setCreatedLicencePlateError(e.code || e.message);
            }
            setLicencePlateCreating(false);
        })
    }

    return (
        <PopupDialog open={isNeedLicencePlateCreate} onClose={closeNewLicencePlateDialog} title={"Добавление номерного знака"} actionBoard={
            <React.Fragment>
                {
                    createdLicencePlateAlertType === -1 ?
                        <>
                            <Button onClick={closeNewLicencePlateDialog} color="default">
                                Отмена
                            </Button>
                            <Button onClick={createLicencePlate} color="primary" disabled={isLicencePlateCreating || !createdLicencePlate}>
                                Ок
                            </Button>
                        </>
                        :
                        <Button onClick={closeNewLicencePlateDialog} color="default">
                            Закрыть окно
                        </Button>
                }
            </React.Fragment>
        }>
            {
                !isLicencePlateExist && createdLicencePlate ?
                    <Grid container spacing={1} className={classes.gridBox}>
                        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                            <LicencePlateInput
                                disabled={isLicencePlateCreating}
                                autofocus
                                onChange={(e, m)=>{
                                    setCreatedLicencePlate(e)
                                }}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                            <TextField
                                disabled={isLicencePlateCreating || isLockAfterAdding}
                                fullWidth
                                label="Комментарий"
                                variant="outlined"
                                value={createdLicencePlateInfo}
                                onChange={(e)=>{setCreatedLicencePlateInfo(e.target.value)}}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                            <TextField
                                disabled={isLicencePlateCreating || isLockAfterAdding}
                                fullWidth
                                label="Имя владельца"
                                variant="outlined"
                                value={createdLicencePlateOwnerName}
                                onChange={(e)=>{setCreatedLicencePlateOwnerName(e.target.value)}}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                            <PhoneNumberInput
                                disabled={isLicencePlateCreating || isLockAfterAdding}
                                label="Телефон владельца"
                                value={createdLicencePlateOwnerPhone}
                                onChange={(e, m)=>{
                                    setCreatedLicencePlateOwnerPhone(e)
                                }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={1} className={classes.gridBox}>
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <LicencePlateInput
                                disabled={isLicencePlateCreating}
                                autofocus
                                onChange={(e, m)=>{
                                    console.log(m);
                                    setCreatedLicencePlate(e)
                                }}
                            />
                        </Grid>
                    </Grid>
            }
            {
                !isLicencePlateExist && createdLicencePlate &&
                <Accordion className={classes.areaAccessAccordion} disabled={isLicencePlateCreating}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>Зоны доступа</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <LicencePlateAllowedArea number={createdLicencePlate} onNeedRelaod={() => {
                        }} onSave={isLicencePlateNeedAreaSave} saveWithLocking disabled={isLicencePlateCreating || isLockAfterAdding} disallow={uiDisallowAreaDefault} disallowReason={'Нет заявления на доступ на территорию'}/>
                    </AccordionDetails>
                </Accordion>
            }
            {
                createdLicencePlateAlertType !== -1 &&
                <Alert variant="outlined" severity={createdLicencePlateAlertType ? "error": "success"} className={classes.alert}>
                    {
                        createdLicencePlateAlertType == 1 ?
                            <>Номерной знак <strong>{createdLicencePlate}</strong> сушествует в базе.</>
                            : createdLicencePlateAlertType == 2 && <>Внутрення ошибка сервера {createdLicencePlateError}</>
                    }
                    {
                        createdLicencePlateAlertType !== 2 &&
                        <Link onClick={()=>{
                            closeNewLicencePlateDialog();
                            openEditLicencePlate();
                        }} color="inherit">
                            <strong> Нажмите здесь для перехода к редактированию номерного знака {createdLicencePlateAlertType == 1 ? createdLicencePlate: successLicencePlateCreated}.</strong>
                        </Link>
                    }
                </Alert>
            }
        </PopupDialog>
    )
}
