import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PopupDialog from "../component/PopupDialog";
import {loadRentContractsWithBills} from "../httpUtils";
import LicencePlateInput from "../component/LicencePlate/LicencePlateInput";
import {Alert} from "@material-ui/lab";
import RentContractAcceptance from "./RentContractAcceptance";

export default function RentAcceptanceDialog(props) {
    const [ isContractAcceptanceDialogOpened, setContractAcceptanceDialogOpened ] = useState(false);

    const [ isOpen, setOpen ] = useState(false);

    const [searchedLicencePlate, setSearchedLicencePlate] = useState('');

    const [searchedLicencePlateContractsWithBill, setSearchedLicencePlateContractsWithBill] = useState([]);

    const [ isCurrentActiveContract, setCurrentActiveContract ] = useState(false);
    const [ activeContract, setActiveContract ] = useState({});

    const [ isHaveOldContract, setHaveOldContract ] = useState(false);
    const [ oldContract, setOldContract ] = useState({});

    const [ isHaveFutureContract, setHaveFutureContract ] = useState(false);
    const [ futureContract, setFutureContract ] = useState({});

    const [selectedContractId, setSelectedContractId] = useState(0);

    const _loadRentContracts = (licencePlate) => {
        console.log('ernew')
        setCurrentActiveContract(false);
        setSearchedLicencePlateContractsWithBill([]);

        loadRentContractsWithBills(licencePlate).then((r)=>{
            const activeContract = _isCurrentActiveContracts(r);
            setActiveContract(activeContract);
            setCurrentActiveContract(activeContract !== undefined);

            const oldContract = _isHaveOldContracts(r);
            setOldContract(oldContract);
            setHaveOldContract(oldContract !== undefined);

            const futureContract = _isHaveFutureContracts(r);
            setFutureContract(futureContract);
            setHaveFutureContract(futureContract !== undefined);

            setSearchedLicencePlateContractsWithBill(r)
        })
    }

    useEffect(()=>{
        if (searchedLicencePlate) {
            _loadRentContracts(searchedLicencePlate)
        }
    }, [searchedLicencePlate])

    const _isCurrentActiveContracts = (contracts) => {
        const currentDatetime = new Date();
        for (const contract of contracts) {
            if (new Date(contract.begin) < currentDatetime && new Date(contract.end) > currentDatetime) return contract;
        }
        return undefined;
    }

    const _isHaveOldContracts = (contracts) => {
        const currentDatetime = new Date();
        for (const contract of contracts) {
            if (new Date(contract.end) < currentDatetime) return contract;
        }
        return undefined;
    }

    const _isHaveFutureContracts = (contracts) => {
        const currentDatetime = new Date();
        for (const contract of contracts) {
            if (new Date(contract.begin) > currentDatetime) return contract;
        }
        return undefined;
    }

    useEffect(()=>{
        if (props.open){
            setOpen(true)
        } else {
            setOpen(false);
            setSearchedLicencePlate('')
        }
    }, [props.open])

    const closeDialog = () => {
      props.hasOwnProperty('needClose') && props.needClose();
    }

    return (
        <PopupDialog maxWidth="sm" open={isOpen} onClose={closeDialog} title={"Работа с договорами"} actionBoard={
            <React.Fragment>
                <Button onClick={closeDialog} color="default" disabled={false}>
                    Закрыть окно
                </Button>
            </React.Fragment>
        }>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <LicencePlateInput
                        disabled={false}
                        autofocus
                        value={searchedLicencePlate}
                        onChange={(e, m)=>{
                            setSearchedLicencePlate(e)
                        }}
                    />
                </Grid>
                { searchedLicencePlate && isCurrentActiveContract ?
                    <>
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <Alert severity="success">По данному номеру есть активный договор от <b>{new Date(activeContract.begin).toLocaleString()}</b> до <b>{new Date(activeContract.end).toLocaleString()}</b></Alert>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <Button onClick={()=>{setContractAcceptanceDialogOpened(true); setSelectedContractId(activeContract.id);}}fullWidth variant="contained" color="primary" disabled={false}>
                                Продлить договор аренды
                            </Button>
                        </Grid>
                        {/*<Grid item lg={12} md={12} sm={12} xl={12} xs={12}>*/}
                        {/*    <Button onClick={()=>{setContractAcceptanceDialogOpened(true); }} fullWidth variant="contained" color="default" disabled={false}>*/}
                        {/*        Создать договор аренды*/}
                        {/*    </  Button>*/}
                        {/*</Grid>*/}
                    </>
                    :
                    searchedLicencePlate && isHaveOldContract ?
                        <>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                <Alert severity="warning">По данному номеру ранее был заключен договор, окончивший действие <b>{new Date(oldContract.end).toLocaleString()}</b></Alert>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                <Button onClick={()=>{setContractAcceptanceDialogOpened(true); }} fullWidth variant="contained" color="primary" disabled={false}>
                                    Создать договор аренды
                                </Button>
                            </Grid>
                        </>
                        :
                        searchedLicencePlate && isHaveFutureContract ?
                            <>
                                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                    <Alert severity="error">По данному номеру ранее был заключен договор на предстоящий период c <b>{new Date(futureContract.end).toLocaleString()}</b></Alert>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                    <Button onClick={()=>{setContractAcceptanceDialogOpened(true);}} fullWidth variant="contained" color="primary" disabled={false}>
                                        Создать договор аренды
                                    </Button>
                                </Grid>
                            </>
                            :
                            searchedLicencePlate &&
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                <Button onClick={()=>{setContractAcceptanceDialogOpened(true); }} fullWidth variant="contained" color="primary" disabled={false}>
                                    Создать договор аренды
                                </Button>
                            </Grid>
                }


            </Grid>
            { isContractAcceptanceDialogOpened && <RentContractAcceptance contractId={selectedContractId} open={isContractAcceptanceDialogOpened} needClose={()=>{setContractAcceptanceDialogOpened(false); setSelectedContractId(0);}} licencePlate={searchedLicencePlate} renewParent={()=>{_loadRentContracts(searchedLicencePlate);}}/> }


        </PopupDialog>
    )
}
