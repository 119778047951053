import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Link, makeStyles, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import PopupDialog from "../component/PopupDialog";
import {
    createKeycode,
    isExistKeycode,
} from "../httpUtils";
const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        expanded: {
            margin: theme.spacing(1)
        }
    },
    gridBox: {
        marginBottom: theme.spacing(1),
    },
    alert: {
        marginTop: theme.spacing(1),
    }
}));

export default function KeycodeCreateDialog(props) {
    const classes = useStyles();
    const [isNeedLicencePlateCreate, setNeedLicencePlateCreate] = useState(false);
    const [createdKeycodeAlertType, setCreatedKeycodeAlertType] = useState(-1);
    const [isLicencePlateCreating, setLicencePlateCreating] = useState(false);
    const [createdLicencePlateError, setCreatedLicencePlateError] = useState('');

    const [isKeycodeExist, setKeycodeExist] = useState(false);

    const [createdKeycodeId, setCreatedKeycodeId] = useState(-1);

    const [keycode, setKeycode] = useState('');
    const [changedKeycode, setChangedKeycode] = useState('');


    const [successLicencePlateCreated, setSuccessLicencePlateCreated] = useState('');
    const [isLockAfterAdding, setLockAfterAdding] = useState(false);

    const [isLicencePlateNeedAreaSave, setLicencePlateNeedAreaSave] = useState(false);

    const [isOneCreateOnly, setOneCreateOnly] = useState(false);

    const [uiDisallowAreaDefault, setUiDisallowAreaDefault] = useState([]);

    useEffect(()=>{
        // getUICreateLicencePlateDisallowArea().then(r => {
        //     setUiDisallowAreaDefault(r)
        // })
    }, []);

    useEffect(()=>{
        setNeedLicencePlateCreate(props.isNeedLicencePlateCreate);
    }, [props.isNeedLicencePlateCreate]);

    // useEffect(()=>{
    //     setCreatedLicencePlate(props.initLicencePlate);
    // }, [props.initLicencePlate]);

    useEffect(()=>{
        setOneCreateOnly(props.oneCreateOnly);
    }, [props.oneCreateOnly]);

    useEffect(()=>{
        if(isLicencePlateNeedAreaSave) setLicencePlateNeedAreaSave(false)
    }, [isLicencePlateNeedAreaSave]);
    //
    // useEffect(()=>{
    //     if (successLicencePlateCreated && createdLicencePlateAlertType !== 2) {
    //         setCreatedLicencePlateAlertType(-1);
    //         setCreatedLicencePlateInfo('');
    //         setSuccessLicencePlateCreated('');
    //         setLockAfterAdding(false);
    //     }
    // }, [createdLicencePlateAlertType, createdLicencePlate, createdLicencePlateInfo])

    useEffect(()=>{
        setCreatedKeycodeAlertType(-1);
        setKeycodeExist(false);
        setCreatedKeycodeId(-1);
        if (changedKeycode){
            isExistKeycode(changedKeycode).then((r)=>{
                if (r.exist) {
                    setCreatedKeycodeId(r.keycodeId);
                    setCreatedKeycodeAlertType(1);
                    setKeycodeExist(true);
                } else {
                    setKeycodeExist(false);
                }
            }).catch((e)=>{
                // console.log(e)
            })
        }
    }, [changedKeycode])

    function closeDialog() {
        props.hasOwnProperty('isNeedClose') && props.isNeedClose();
        setNeedLicencePlateCreate(false);
    }

    function needReload(){
        props.hasOwnProperty('isNeedReload') && props.isNeedReload();
    }

    function returnCreatedKeycodeId(keycodeId) {
        props.hasOwnProperty('createdKeycodeId') && props.createdKeycodeId(keycodeId)
    }

    function _createKeycode() {
        // setLicencePlateCreating(true);
        createKeycode(changedKeycode).then((v)=>{
            console.log(v)
            returnCreatedKeycodeId(v.keycodeId);
            needReload();
            closeDialog();
        }).catch((e)=>{
            console.log(e)
        })
        // createLicencePlateWithInfo(createdLicencePlate, createdLicencePlateInfo, createdLicencePlateOwnerName, createdLicencePlateOwnerPhone, createdLicencePlateOversizeVehicle).then((r)=>{
        // createLicencePlateWithInfo(createdLicencePlate, createdLicencePlateInfo, createdLicencePlateOwnerName, createdLicencePlateOwnerPhone, false).then((r)=>{
        //     setCreatedLicencePlateAlertType(0);
        //     setSuccessLicencePlateCreated(r);
        //     setLicencePlateNeedAreaSave(true);
        //     needReload();
        //     setLockAfterAdding(true);
        //     setLicencePlateCreating(false);
        //     if (isOneCreateOnly){
        //         returnCreatedLicencePlate(r);
        //         closeNewLicencePlateDialog();
        //     }
        // }).catch((e)=>{
        //     if (e.code === 409){
        //         setCreatedLicencePlateAlertType(1);
        //         setSuccessLicencePlateCreated(e.licencePlate);
        //     } else {
        //         setCreatedLicencePlateAlertType(2);
        //         setCreatedLicencePlateError(e.code || e.message);
        //     }
        //     setLicencePlateCreating(false);
        // })
    }

    const searchKeycode = async () => {
        navigator.serial.addEventListener('connect', (e) => {
            console.log(e)
            // Connect to `e.target` or add it to a list of available ports.
        });

        navigator.serial.addEventListener('disconnect', (e) => {
            console.log(e)
            // Remove `e.target` from the list of available ports.
        });

        let port = undefined;
        const ports = await navigator.serial.getPorts();

        if (ports.length >= 1){
            port = ports[0]
        } else {
            port = await navigator.serial.requestPort();
        }

        class LineBreakTransformer {
            constructor() {
                this.chunks = "";
            }

            transform(chunk, controller) {
                this.chunks += chunk;
                const lines = this.chunks.split("\r\n");
                this.chunks = lines.pop();
                lines.forEach((line) => controller.enqueue(line));
            }

            flush(controller) {
                controller.enqueue(this.chunks);
            }
        }

        // const port = await navigator.serial.requestPort();

        await port.open({baudRate: 115200});

        // eslint-disable-next-line no-undef
        const textDecoder = new TextDecoderStream();
        const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);

        const reader = textDecoder.readable
            // eslint-disable-next-line no-undef
            .pipeThrough(new TransformStream(new LineBreakTransformer()))
            .getReader();

        while (true) {
            const {value, done} = await reader.read();
            if (done) {
                reader.releaseLock();
                break;
            }
            try {
                const p = JSON.parse(value);
                if (p.tags[0].tid && p.tags[0].epc) {
                    const id = `${p.tags[0].tid}${p.tags[0].epc}`
                    setChangedKeycode(id);

                    break;
                }
            } catch (e) {
            } finally {
                await reader.cancel();
                reader.releaseLock();
                // textDecoder.
                // await port.close();
            }
        }
        // readableStreamClosed();
        // reader.cancel();
        // await port.close();
    }

    return (
        <PopupDialog open={isNeedLicencePlateCreate} onClose={closeDialog} title={"Добавление ключа / метки"} actionBoard={
            <React.Fragment>
                {
                    createdKeycodeAlertType === -1 ?
                        <>
                            <Button onClick={closeDialog} color="default">
                                Отмена
                            </Button>
                            <Button onClick={_createKeycode} color="primary" disabled={isLicencePlateCreating || !createdKeycodeId}>
                                Ок
                            </Button>
                        </>
                        :
                        <Button onClick={closeDialog} color="default">
                            Закрыть окно
                        </Button>
                }
            </React.Fragment>
        }>
            {
                !isKeycodeExist && changedKeycode ?
                    <Grid container spacing={1} className={classes.gridBox}>
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <TextField
                                disabled={isLicencePlateCreating || isLockAfterAdding}
                                fullWidth
                                label="ID"
                                variant="outlined"
                                value={changedKeycode}
                                onChange={(e)=>{setChangedKeycode(e.target.value)}}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={1} className={classes.gridBox}>
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <TextField
                                disabled={isLicencePlateCreating || isLockAfterAdding}
                                fullWidth
                                label="ID"
                                variant="outlined"
                                value={changedKeycode}
                                onChange={(e)=>{setChangedKeycode(e.target.value)}}
                            />
                            <Button variant="outlined" color="default" fullWidth style={{marginTop: 8}} onClick={() => {
                                searchKeycode()
                            }} >
                                Найти ключ / метку
                            </Button>
                        </Grid>
                    </Grid>
            }
            {
                // !isLicencePlateExist && createdLicencePlate &&
                // <Accordion className={classes.areaAccessAccordion} disabled={isLicencePlateCreating}>
                //     <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                //         <Typography className={classes.heading}>Зоны доступа</Typography>
                //     </AccordionSummary>
                //     <AccordionDetails className={classes.details}>
                //         {/*<LicencePlateAllowedArea number={createdLicencePlate} onNeedRelaod={() => {*/}
                //         {/*}} onSave={isLicencePlateNeedAreaSave} saveWithLocking disabled={isLicencePlateCreating || isLockAfterAdding} disallow={uiDisallowAreaDefault} disallowReason={'Нет заявления на доступ на территорию'}/>*/}
                //     </AccordionDetails>
                // </Accordion>
            }
            {
                createdKeycodeAlertType !== -1 &&
                <Alert variant="outlined" severity={createdKeycodeAlertType ? "error": "success"} className={classes.alert}>
                    {
                        createdKeycodeAlertType == 1 ?
                            <>Номерной знак <strong>{changedKeycode}</strong> сушествует в базе.</>
                            : createdKeycodeAlertType == 2 && <>Внутрення ошибка сервера {createdLicencePlateError}</>
                    }
                    {
                        createdKeycodeAlertType !== 2 &&
                        <Link onClick={()=>{
                            closeDialog();
                            // openEditLicencePlate();
                        }} color="inherit">
                            <strong> Нажмите здесь для перехода к редактированию номерного знака {changedKeycode}.</strong>
                        </Link>
                    }
                </Alert>
            }
        </PopupDialog>
    )
}
