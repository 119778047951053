import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PopupDialog from "../component/PopupDialog";
import {loadRentService, loadRentTariffObjects, updateService} from "../httpUtils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FormControl, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField} from "@material-ui/core";

export default function EditServiceDialog(props) {
    const [ isOpen, setOpen ] = useState(false);
    const [ id, setId ] = useState(undefined);

    const [ description, setDescription ] = useState('');
    const [ cost, setCost ] = useState(undefined);
    const [ adoptionOnEndOfPeriod, setAdoptionOnEndOfPeriod ] = useState(false);
    const [ adoptionUponRequest, setAdoptionUponRequest ] = useState(false);
    const [ acceptanceOnceOnAllTerm, setAcceptanceOnceOnAllTerm ] = useState(false);
    const [ stackable, setStackable ] = useState(false);
    const [ tariff, setTariff ] = useState(false);
    const [ tariffRentTime, setTariffRentTime ] = useState(1);
    const [ tariffRentMonth, setTariffRentMonth ] = useState(0);
    const [ tariffOverpaidTime, setTariffOverpaidTime ] = useState(0);
    const [ tariffObjects, setTariffObjects ] = useState([]);


    const [ changedDescription, setChangedDescription ] = useState('');
    const [ changedCost, setChangedCost ] = useState(undefined);
    const [ changedAdoptionOnEndOfPeriod, setChangedAdoptionOnEndOfPeriod ] = useState(false);
    const [ changedAdoptionUponRequest, setChangedAdoptionUponRequest ] = useState(false);
    const [ changedAcceptanceOnceOnAllTerm, setChangedAcceptanceOnceOnAllTerm ] = useState(false);
    const [ changedStackable, setChangedStackable ] = useState(false);
    const [ changedTariff, setChangedTariff ] = useState(false);
    const [ changedTariffRentTime, setChangedTariffRentTime ] = useState(1);
    const [ changedTariffRentMonth, setChangedTariffRentMonth ] = useState(0);
    const [ changedTariffOverpaidTime, setChangedTariffOverpaidTime ] = useState(0);
    const [ changedTariffObjects, setChangedTariffObjects ] = useState([]);

    const [ isChanged, setChanged ] = useState(false);

    const [tariffObjectList, setTariffObjectList] = useState([]);

    useEffect(()=>{
        if (props.open !== undefined && props.id !== undefined){
            setOpen(true)
            setId(props.id)
        } else {
            setOpen(false);
            setId(undefined)
        }
    }, [props.open, props.id])

    const loadData = (currentId) => {
        loadRentService(currentId).then((r)=>{
            setDescription(r.description);
            setCost(r.cost);
            setAdoptionOnEndOfPeriod(!!r.adoptionOnEndOfPeriod);
            setAdoptionUponRequest(!!r.adoptionUponRequest);
            setAcceptanceOnceOnAllTerm(!!r.acceptanceOnceOnAllTerm);
            setStackable(!!r.stackable);
            setTariff(!!r.tariff);

            setChangedDescription(r.description);
            setChangedCost(r.cost);
            setChangedAdoptionOnEndOfPeriod(!!r.adoptionOnEndOfPeriod);
            setChangedAdoptionUponRequest(!!r.adoptionUponRequest);
            setChangedAcceptanceOnceOnAllTerm(!!r.acceptanceOnceOnAllTerm);
            setChangedStackable(!!r.stackable);
            setChangedTariff(!!r.tariff);

            if (!!r.tariff) {
                setTariffRentTime(r.tariff.rentTime || 0);
                setTariffRentMonth(r.tariff.rentMonth || 0);
                setTariffOverpaidTime(r.tariff.overpaidTime || 0);
                setTariffObjects(r.tariff.objects.map((v)=>v.id) || []);

                setChangedTariffRentTime(r.tariff.rentTime || 0);
                setChangedTariffRentMonth(r.tariff.rentMonth || 0);
                setChangedTariffOverpaidTime(r.tariff.overpaidTime || 0);
                setChangedTariffObjects(r.tariff.objects.map((v)=>v.id) || []);
            }
        }).catch((e)=>{
            console.warn(e)
        })
    }

    useEffect(()=>{
        if (id > 0){
            loadData(id)
        }
        loadRentTariffObjects().then((r1)=>{
            setTariffObjectList(r1);
        })
    }, [id])

    useEffect(()=>{
        console.log(changedTariffOverpaidTime,tariffOverpaidTime)
        if (
            changedDescription !== description ||
            changedCost !== cost ||
            changedAdoptionOnEndOfPeriod !== adoptionOnEndOfPeriod ||
            changedAdoptionUponRequest !== adoptionUponRequest ||
            changedAcceptanceOnceOnAllTerm !== acceptanceOnceOnAllTerm ||
            changedStackable !== stackable ||
            changedTariff !== tariff ||
            changedTariffRentTime !== tariffRentTime ||
            changedTariffRentMonth !== tariffRentMonth ||
            changedTariffOverpaidTime !== tariffOverpaidTime ||
            JSON.stringify(changedTariffObjects) !== JSON.stringify(tariffObjects)
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [
        changedDescription, changedCost, changedAdoptionOnEndOfPeriod, changedAdoptionUponRequest, changedAcceptanceOnceOnAllTerm, changedStackable, changedTariff, changedTariffRentTime, changedTariffRentMonth, changedTariffOverpaidTime, changedTariffObjects,
        description, cost, adoptionOnEndOfPeriod, adoptionUponRequest, acceptanceOnceOnAllTerm, stackable, tariff, tariffRentTime, tariffRentMonth, tariffOverpaidTime, tariffObjects
        ])

    const closeDialog = () => {
      props.hasOwnProperty('needClose') && props.needClose();
    }

    const parentUpdate = () => {
        props.hasOwnProperty('needReload') && props.needReload();
    }

    const saveService = (id, serviceDescription, serviceCost, serviceAdoptionOnEndOfPeriod, serviceAdoptionUponRequest, serviceAcceptanceOnceOnAllTerm, serviceStackable, serviceTariff, serviceTariffRentTime, serviceTariffRentMonth, serviceTariffOverpaidTime, serviceTariffObjects) => {
        const t = {
            serviceTariffRentTime,
            serviceTariffRentMonth,
            serviceTariffOverpaidTime,
            serviceTariffObjects
        }
        const s = {
            serviceDescription,
            serviceCost,
            serviceAdoptionOnEndOfPeriod,
            serviceAdoptionUponRequest,
            serviceAcceptanceOnceOnAllTerm,
            serviceStackable,
            serviceTariff: serviceTariff? t : undefined
        }
        updateService(id, s).then((r)=>{
            setId(r.newServiceId)
            parentUpdate();
        }).catch((e)=>{
            console.warn(e)
        })
    }

    return (
        <PopupDialog maxWidth="xs" open={isOpen} onClose={closeDialog} title={"Настройка услуги"} actionBoard={
            <React.Fragment>
                <Button onClick={closeDialog} color="default">
                    Закрыть окно
                </Button>
                <Button onClick={()=>{
                    saveService(id, changedDescription, changedCost, changedAdoptionOnEndOfPeriod, changedAdoptionUponRequest, changedAcceptanceOnceOnAllTerm, changedStackable, changedTariff, changedTariffRentTime, changedTariffRentMonth, changedTariffOverpaidTime, changedTariffObjects)
                }} color="primary" disabled={!isChanged}>
                    Сохранить
                </Button>
            </React.Fragment>
        }>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <TextField
                        disabled={false}
                        fullWidth
                        label="Наименование"
                        variant="outlined"
                        value={changedDescription}
                        onChange={(e)=>{setChangedDescription(e.target.value)}}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <TextField
                        disabled={false}
                        fullWidth
                        label="Стоимость за единицу"
                        variant="outlined"
                        type={"number"}
                        value={parseFloat(changedCost/100).toFixed(2)}
                        onChange={(e)=>{setChangedCost(parseFloat(e.target.value) * 100)}}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={changedAdoptionOnEndOfPeriod}
                                onChange={(e)=>{
                                    setChangedAdoptionOnEndOfPeriod(!changedAdoptionOnEndOfPeriod)
                                }}
                                color="primary"
                            />
                        }
                        label="Зачет средств в конце оплаченного периода"
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={changedAdoptionUponRequest}
                                onChange={(e)=>{
                                    setChangedAdoptionUponRequest(!changedAdoptionUponRequest)
                                }}
                                color="primary"
                            />
                        }
                        label="Зачет средств по требованию"
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={changedStackable}
                                onChange={(e)=>{
                                    setChangedStackable(!changedStackable)
                                }}
                                color="primary"
                            />
                        }
                        label="Разрешить использовать множество позиций в одном чеке"
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={changedAcceptanceOnceOnAllTerm}
                                onChange={(e)=>{
                                    setChangedAcceptanceOnceOnAllTerm(!changedAcceptanceOnceOnAllTerm)
                                }}
                                color="primary"
                            />
                        }
                        label="Принимать один раз за весь период"
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={changedTariff}
                                onChange={(e)=>{
                                    setChangedTariff(!changedTariff)
                                }}
                                color="primary"
                            />
                        }
                        label="Это тариф"
                    />
                </Grid>
                { changedTariff &&
                <React.Fragment>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Длительность тарифа</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={changedTariffRentTime ? 1 : changedTariffRentMonth ? 2 : 0} onChange={(e)=>{
                                switch (e.target.value) {
                                    case '1':
                                        setChangedTariffRentMonth(undefined);
                                        setChangedTariffRentTime(1);
                                        break;
                                    case '2':
                                        setChangedTariffRentMonth(1);
                                        setChangedTariffRentTime(undefined);
                                        break;
                                }
                            }}>
                                <FormControlLabel value={1} control={<Radio />} label="В секундах" />
                                <FormControlLabel value={2} control={<Radio />} label="В календарных месяцах" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        {changedTariffRentTime ?
                            <TextField
                                type={'number'}
                                disabled={false}
                                fullWidth
                                label="Время действия тарифа (в секундах)"
                                variant="outlined"
                                value={changedTariffRentTime}
                                onChange={(e)=>{setChangedTariffRentTime(parseInt(e.target.value))}}
                            />
                            : changedTariffRentMonth ?
                                <TextField
                                    type={'number'}
                                    disabled={false}
                                    fullWidth
                                    label="Время действия тарифа (в календарных месяцах)"
                                    variant="outlined"
                                    value={changedTariffRentMonth}
                                    onChange={(e)=>{setChangedTariffRentMonth(parseInt(e.target.value))}}
                                />
                                : null}

                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <TextField
                            type={'number'}
                            disabled={false}
                            fullWidth
                            label="Время разрешенное для выезда (в секундах)"
                            variant="outlined"
                            value={changedTariffOverpaidTime}
                            onChange={(e)=>{setChangedTariffOverpaidTime(parseInt(e.target.value))}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="rentTariffObjectSelect">Тип размещаемого объекта</InputLabel>
                            <Select
                                fullWidth
                                labelId="rentTariffObjectSelect"
                                value={changedTariffObjects}
                                onChange={(e)=> {
                                    setChangedTariffObjects(e.target.value)
                                }}
                                label="Тип размещаемого объекта"
                                disabled={false}
                                multiple
                            >
                                {
                                    tariffObjectList.map((v, i) => (
                                        <MenuItem value={v.id}>{`${v.name}`}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
        </PopupDialog>
    )
}
