import React, {useEffect, useState} from 'react';
import {getLicencePlateLastHistory, loadArea, loadBlacklistHistory} from "../httpUtils";
import {
    TableCell
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

export default function LicencePlateEventsHistory(props) {
    const [number, setNumber] = useState();
    const [licencePlateLastHistory, setLicencePlateLastHistory] = useState([]);
    const [areaList, setAreaList] = useState([]);

    useEffect(()=>{
        setNumber(props.number);
    }, [props.number])

    function load(){
        getLicencePlateLastHistory(number).then((r)=>{
            setLicencePlateLastHistory(r);
            loadArea().then((r)=>{
                setAreaList([{id: 0, name: "Улица"},...r]);
            }).catch((e)=>{

            })
        }).catch((e)=>{

        })
    }

    useEffect(()=>{
        if (number){
            load()
        }
    }, [number])

    useEffect(()=>{
        if (props.onReload) load()
    }, [props.onReload])

    useEffect(()=>{
        props.hasOwnProperty('isEmpty') && props.isEmpty(licencePlateLastHistory.length === 0);
    }, [licencePlateLastHistory])

    return(
        <React.Fragment>
            <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography>Дата и время</Typography></TableCell>
                            <TableCell><Typography>Проехал на</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licencePlateLastHistory.map((row, i) => (
                            <TableRow key={`tblre-${i}`}>
                                <TableCell align="left"><Typography>{ new Date(row.time).toLocaleString()}</Typography></TableCell>
                                <TableCell align="left"><Typography>{ areaList.find((a)=>a.id === row.area)?.name}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
