import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Menu from '../../Header/Menu';

export default class BarrierSettings extends Component {
 constructor() {
   super();
   this.state = {
     list: [],
     portal: [],
     showEditDialog: false,
     showEditDialogModeEdit: false,
     editId: 0,
     editName: '',
     editPortal: 0,
     editError: ''
   }
   this.handleLoad = this.handleLoad.bind(this);
   this.loadPortal = this.loadPortal.bind(this);
 }

 handleSubmitArea(action) {
   console.log(action);
   let reqMethod;
   let reqBody;
   let reqUrl;
   switch(action) {
    case 'delete':
      reqMethod='DELETE';
      reqBody=[];
      reqUrl = process.env.REACT_APP_API_URL+'/parking/barrier/'+this.state.editId;
      break;
    case 'create':
      reqMethod='POST';
      reqBody=JSON.stringify({ "portal" :this.state.editPortal, "name" :this.state.editName });
      reqUrl = process.env.REACT_APP_API_URL+'/parking/barrier';
      break;
    case 'update':
      reqMethod='PUT';
      reqBody=JSON.stringify({"portal" :this.state.editPortal, "name" :this.state.editName});
      reqUrl = process.env.REACT_APP_API_URL+'/parking/barrier/'+this.state.editId;
      break;
  }
   fetch(reqUrl, {
     method: reqMethod,
     credentials: 'include',
     body: reqBody,
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => {
     console.log(res.status);
     switch (res.status) {
        case 401:

          break;
        case 403:
          this.setState({editError: "Запрещено!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
        case 409:
          this.setState({editError: "Такое уже есть!"},()=>{
            window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
          });
          break;
       default:
       this.setState({showEditDialog: false});
       this.handleLoad();
     }
   })
   .catch(err => {
     console.log(err);
   });
 }

 handleLoad(){
   fetch(process.env.REACT_APP_API_URL+'/parking/barrier',{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => this.setState({list: res.barrier}));
 }

loadPortal(){
  fetch(process.env.REACT_APP_API_URL+'/parking/portal',{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .then(res => this.setState({portal: res.portal, editPortal: res.portal[0].id}));
}

 componentDidMount() {
   this.handleLoad()
 }

 render() {
   return (
    <>
      <Container>
        <h3>Барьеры</h3>
        <ButtonToolbar className="mb-3">
          <Button variant="outline-primary" onClick={ (e) => {
            this.loadPortal();
            this.setState({
              showEditDialog: true,
              showEditDialogModeEdit: false,
              editId: 0,
              editName: '',
              editPortal: 0
            });
            e.target.blur();
          }}>Новый барьер</Button>
        </ButtonToolbar>
        <div>
          <ListGroup className="mb-3">
          {
          this.state.list.map((data, i) => (
            <ListGroup.Item
            key={i}
            action onClick={ (e) => {
              this.loadPortal();
              this.setState({
                showEditDialog: true,
                showEditDialogModeEdit: true,
                editId: data.id,
                editName: data.name,
                editPortal: data.portal
              });
              e.target.blur();
            }}>
            <>
              {data.name}
              </>
            </ListGroup.Item>
          ))
          }
          </ListGroup>

          <Modal show={this.state.showEditDialog} onHide={ () => this.setState({showEditDialog : false, showEditDialogModeEdit: false}) } size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{!this.state.showEditDialogModeEdit ? <>Новый барьер</> : <>Редактировать барьер</> }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="barrierName">
                  <Form.Label>Название</Form.Label>
                  <Form.Control required type="text" placeholder="Зезд на парковку" value={this.state.editName} onChange={(event)=>{ this.setState({editName: event.target.value}) }}/>
                </Form.Group>
                <Form.Group controlId="barrierPortal">
                 <Form.Label>Портал</Form.Label>
                  <Form.Control as="select" onChange={(e) => { this.setState({editPortal : e.target.value}) }} value={this.state.editPortal}>
                     {
                       this.state.portal.map((data, j) => (
                         <option key={j} name={data.name} value={data.id}>{(data.from_areaT ? data.from_areaT: "Улица") +" - "+ (data.to_areaT ? data.to_areaT: "Улица")}</option>
                       ))
                     }
                 </Form.Control>
                </Form.Group>
              </Form>
              {this.state.editError ?
                <Alert variant="danger">
                  {this.state.editError}
                </Alert>
              :null }
            </Modal.Body>
            <Modal.Footer>
              
              <Button variant="primary" onClick={this.handleSubmitArea.bind(this, this.state.showEditDialogModeEdit ? 'update' : 'create')}>
                {!this.state.showEditDialogModeEdit ? <>Добавить</> : <>Сохранить</> }
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
   );
 }
}
