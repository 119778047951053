import * as actionTypes from "./actionTypes"

export function changeTheme(theme) {
    const action = {
        type: actionTypes.CHANGE_THEME,
        theme,
    }
    return action
}

export function changeUserSettings(settings) {
    const action = {
        type: actionTypes.CHANGE_USER_SETTINGS,
        settings,
    }
    return action
}