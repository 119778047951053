import React, {useContext, useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Accordion,
    AccordionActions,
    AccordionDetails, Avatar, Card, CardActionArea, CardHeader,
    CssBaseline, FormControl, InputAdornment, InputLabel, Link,
    makeStyles, MenuItem, Paper, Select,
    TextField, Tooltip
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {loadLicencePlates} from "../httpUtils";
import {appendFilter, checkSettings, getSortParam, getTypeLicencePlates} from "../utils";
import LicencePlateInfo from "./LicencePlateInfo";
import ScrollToTop from "../component/ScrollToTop";
import LicencePlateCreateDialog from "./LicencePlateCreateDialog";
import {ReactReduxContext} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {cyrillicToLatinAutoConvert} from "../component/LicencePlate/LicencePlateMask";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: 45,
    },
    alert: {
        marginTop: theme.spacing(2),
    },
    licencePlateAllowToAllAvatar: {
        backgroundColor: '#4CAF50',
        width: 45,
    },
    licencePlatePartialDisallowAvatar: {
        backgroundColor: '#FFC107',
        width: 45,
    },
    licencePlateFullDisallowAvatar: {
        backgroundColor: '#F44336',
        width: 45,
    },
    licencePlateTimeLimitAvatar: {
        backgroundColor: '#673AB7',
        width: 45,
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        '&$expanded': {
            margin: 0,
            transform: "rotate(0deg)",
        },
    }
}));

export default function LicencePlates(props) {
    const {store} = useContext(ReactReduxContext)
    const classes = useStyles();
    const [filterParam, setFilterParam] = useState({licencePlate: undefined, licencePlateType: undefined, showUnbindLicencePlates: false, licencePlateOwnerPhone: undefined, licencePlateOwnerName: undefined, licencePlateInfo: undefined});
    const [isFetching, setIsFetching] = useState(false);
    const [loadParam, setLoadParam] = useState();

    const [isFormChanged, setIsFormChanged] = useState(0);
    const [numberList, setNumberList] = useState([]);

    const [currentLicencePlate, setCurrentLicencePlate] = useState('');
    const [isNeedLicencePlateCreate, setNeedLicencePlateCreate] = useState(false);

    store.subscribe(() => {
        if (checkSettings(store.getState().settings, 12)){
            setShowUnbindLicencePlatesFilterAvailable(true)
        }
        if (checkSettings(store.getState().settings, 15)){
            setCreateLicencePlatesAvailable(true)
        }
        if (checkSettings(store.getState().settings, 16)){
            setUpdateLicencePlatesAvailable(true)
        }
    })

    const [isShowUnbindLicencePlatesFilterAvailable, setShowUnbindLicencePlatesFilterAvailable] = useState(false);
    const [isCreateLicencePlatesAvailable, setCreateLicencePlatesAvailable] = useState(false);
    const [isUpdateLicencePlatesAvailable, setUpdateLicencePlatesAvailable] = useState(false);

    function resetFilters(){
        setFilterParam(Object.fromEntries(Object.entries(filterParam).map((v)=> [v[0], undefined])))
        setIsFormChanged(Math.random());
    }

    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop <= (document.scrollingElement.scrollHeight-1)) return;
        setIsFetching(true);
    }

    useEffect(()=>{
        if (loadParam){
            loadLicencePlates(`${getSortParam(loadParam)}`, true).then((r)=>{
                if (isFetching){
                    setIsFetching(false);
                    setNumberList( [...numberList, ...r]);
                } else {
                    setNumberList(r);
                }
            })
        }
    }, [loadParam])

    useEffect(()=>{
        setLoadParam({...appendFilter(filterParam), limit: 200});
    }, [filterParam]);

    useEffect(()=>{
        if (isFetching){
            if (numberList.length !== 0){
                setLoadParam({...loadParam, offsetLicencePlate: numberList[numberList.length-1].licencePlate})
            } else {
                setLoadParam({...loadParam })
            }
        }
    }, [isFetching])

    function initPage() {
        resetFilters();
        window.addEventListener('scroll', handleScroll, false);
        document.addEventListener('touchmove', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
            document.removeEventListener('touchmove', handleScroll, true);
        }
    }

    useEffect(()=>{
        initPage();
    }, []);

    function getAvatarClassname(allowToAll, fullDisallow, partialDisallow, allowFrom, allowTo, allowLeaveTo){
        if (allowFrom || allowTo || allowLeaveTo) return classes.licencePlateTimeLimitAvatar
        if (allowToAll) return classes.licencePlateAllowToAllAvatar
        if (fullDisallow) return classes.licencePlateFullDisallowAvatar
        if (partialDisallow) return classes.licencePlatePartialDisallowAvatar
    }

    function getAvatarIcon(allowToAll, fullDisallow, partialDisallow, allowFrom, allowTo, allowLeaveTo){
        if (allowFrom || allowTo || allowLeaveTo) return <QueryBuilderIcon/>
        if (allowToAll) return <CheckCircleOutlineIcon/>
        if (fullDisallow) return <RemoveCircleOutlineIcon/>
        if (partialDisallow) return <ErrorOutlineIcon/>
    }

    function getAvatarTooltipText(allowToAll, fullDisallow, partialDisallow){
        if (allowToAll) return "Доступ на все территории парковки"
        if (fullDisallow) return "Полный запрет доступа на все территории парковки"
        if (partialDisallow) return "Частичный запрет на территории парковки"
    }

    return (
        <React.Fragment>
            <Menu title='Номерные знаки' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Фильтр</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} key={`filter-${isFormChanged}`}>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, licencePlate: cyrillicToLatinAutoConvert(e.target.value) })}
                                    value={filterParam.licencePlate || ''}
                                    fullWidth
                                    label="Номерной знак" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, licencePlateOwnerPhone: e.target.value })}
                                    value={filterParam.licencePlateOwnerPhone || ''}
                                    fullWidth
                                    label="Телефон владельца" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, licencePlateOwnerName: e.target.value })}
                                    value={filterParam.licencePlateOwnerName || ''}
                                    fullWidth
                                    label="Владелец" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TextField
                                    onChange={(e)=>setFilterParam({...filterParam, licencePlateInfo: e.target.value })}
                                    value={filterParam.licencePlateInfo || ''}
                                    fullWidth
                                    label="Комментарий" variant="outlined" />
                            </Grid>
                            <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                    <InputLabel id="filterLicencePlateType">Доступ номерного знака</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="filterLicencePlateType"
                                        value={filterParam.licencePlateType}
                                        onChange={(e)=> setFilterParam({...filterParam, licencePlateType: e.target.value})}
                                        label="Доступ номерного знака"
                                    >
                                        <MenuItem value={undefined}>
                                            <em>Не выбран</em>
                                        </MenuItem>
                                        {
                                            [1,2,3,4].map((data, i) => (
                                                <MenuItem value={data}>{getTypeLicencePlates(data)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                isShowUnbindLicencePlatesFilterAvailable &&
                                <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={filterParam.reverse}
                                                onChange={(e)=>{
                                                    setFilterParam({...filterParam, showUnbindLicencePlates: (e.target.checked === true) })
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Отобразить номерные знаки без привязок к кварирам"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button size="small" color="primary" onClick={resetFilters}>
                            Сбросить
                        </Button>
                    </AccordionActions>
                </Accordion>
                {
                    isCreateLicencePlatesAvailable &&
                    <Button variant="contained" color="primary" className={classes.accordion} onClick={()=>{
                        setNeedLicencePlateCreate(true);
                    }}>
                        Добавить номерной знак
                    </Button>
                }

                <Grid container spacing={1}>
                    { numberList.length > 0 && numberList.map((data, i) => (
                        <Grid container item xs={12} sm={6} md={4} lg={4} xl={2}>

                            <Card className={classes.card}>
                                <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                    setCurrentLicencePlate(data.licencePlate);
                                }}>
                                    <CardHeader
                                        avatar={
                                            <Tooltip title={getAvatarTooltipText(data.allowToAll, data.fullDisallow, data.partialDisallow)} interactive>
                                                <Avatar variant="rounded" aria-label="recipe" className={getAvatarClassname(data.allowToAll, data.fullDisallow, data.partialDisallow, data.allowFrom, data.allowTo, data.allowLeaveTo)}>
                                                    { getAvatarIcon(data.allowToAll, data.fullDisallow, data.partialDisallow, data.allowFrom, data.allowTo, data.allowLeaveTo) }
                                                </Avatar>
                                            </Tooltip>
                                        }
                                        subheader={
                                            <React.Fragment>
                                                <Typography variant="h5" component="h2">{data.licencePlate}</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </CardActionArea>
                            </Card>

                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            <ScrollToTop/>

            <LicencePlateCreateDialog isNeedLicencePlateCreate={isNeedLicencePlateCreate} isNeedClose={()=>{setNeedLicencePlateCreate(false)}} isNeedOpenEditLicencePlate={(lp)=>{setCurrentLicencePlate(lp)}} isNeedReload={()=>{resetFilters()}}/>
            <LicencePlateInfo isUpdateLicencePlateAvailable={isUpdateLicencePlatesAvailable} key={currentLicencePlate} open={!!currentLicencePlate} number={currentLicencePlate} onClose={()=>{ setCurrentLicencePlate('') }} onNeedParentReload={()=>{resetFilters()}}/>

        </React.Fragment>
    )
}
