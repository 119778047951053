import React, {useEffect, useState} from "react";
import {
    getCameraAreaMapping,
    getCameraRealtimeImage, requestCameraRealtimeImage,
} from "../httpUtils";
import CanvasAreaEditor from "./CanvasAreaEditor";
import {ButtonGroup, LinearProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function EditCameraArea(props) {
    const [cameraId, setCameraId] = useState(0);

    const [cameraRealtimeImage, setCameraRealtimeImage] = useState('');
    const [points, setPoints] = useState([]);

    const [newPointMode, setNewPointMode] = useState(false);
    const [delPointMode, setDelPointMode] = useState(false);

    const startPendingImage = () => {
        const getImage = () => {
            getCameraRealtimeImage(cameraId).then((r)=>{
                setCameraRealtimeImage(r)
            }).catch((e)=>{
                console.log(e)
                setTimeout(()=>{
                    getImage()
                }, 1000)
            })
        }
        requestCameraRealtimeImage(cameraId).then((r)=>{
            setTimeout(()=>{
                getImage()
            }, 1000)
        })
    }

    useEffect(()=>{
        if (cameraId > 0){
            startPendingImage();
        }
    }, [cameraId])

    useEffect(()=>{
        setCameraId(props.cameraId)
    }, [props.cameraId])

    useEffect(()=>{
        setPoints(props.areaCoordinates)
    }, [props.areaCoordinates])

    return (
        <>
            { cameraRealtimeImage.length > 0 && points.length > 0 ?
                <>
                    <CanvasAreaEditor newPointMode={newPointMode} delPointMode={delPointMode} image={cameraRealtimeImage} points={points} setPoints={(p)=>{setPoints(p); props.areaCoordinatesUpdate(p); setNewPointMode(false); setDelPointMode(false)}}/>
                    <ButtonGroup color="primary">
                        <Button color={'primary'} variant={newPointMode? "contained": "outlined"} onClick={()=>{setNewPointMode(true)}}>+</Button>
                        <Button color={'secondary'} variant={delPointMode? "contained": "outlined"} onClick={()=>{setDelPointMode(true)}}>-</Button>
                    </ButtonGroup>
                </>
                :
                <>
                    <LinearProgress />
                </>
            }
        </>
    )
}
