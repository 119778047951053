import React, {useEffect, useState} from 'react';
import {CssBaseline, makeStyles, TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {getLicencePlateActivity} from "../../httpUtils";
import Menu from "../../Header/Menu";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    table: {
        marginTop: theme.spacing(2),
    }

}));

export default function LicencePlatesActivity() {
    const classes = useStyles();
    const [list, setList] = useState([]);

    function initPage() {
        getLicencePlateActivity().then((r)=>{
            setList(r)
        })
    }

    useEffect(()=>{
        initPage();
    }, [])

    return (
        <React.Fragment>
            <Menu title='' reloadData={()=>{
                initPage();
            }}/>
            <CssBaseline />
            <div className={classes.root}>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Номерной знак</TableCell>
                                <TableCell>Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row, i) => (
                                <TableRow key={row.licencePlate}>
                                    <TableCell component="th" scope="row">{row.licencePlate}</TableCell>
                                    <TableCell>{row.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </React.Fragment>
    )
}
