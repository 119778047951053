export function getTypeOfPayment(type){
    const types = {
        default: '',
        0: 'Наличными',
        1: 'Безналичными',
        13: 'Аванс',
    }
    return types[type] || types.default
}

export function penniesToRubles(v) {
    return `${v/100} р.`
}

export function getTypeText(type){
    const types = {
        default: 'Неопознанное событие.',
        1: 'Белый список.',
        2: 'Гость.',
        3: 'Доступ запрещен.',
        4: 'Гость, запрещен.',
        5: 'Время пребывания истекло, запрещен.',
        6: 'Запрещен, нет свободных мест.',
        9: 'Открыто из панели управления.',
        91: 'Открыто внешним устройством управления.',
        92: 'Открыто командой ключа / метки',
    }
    return types[type] || types.default
}

export function showNumber(current, compact = false){
    if (!current.number && !current.detected_number){
        return "НЕТ ДАННЫХ"
    }
    if (compact){
        return current.status === 2 ? current.detected_number : current.number || current.detected_number
    }
    if (current.number && current.detected_number){
        return `${current.number} ( ${current.detected_number} )`
    }
    return current.number || current.detected_number
}

export function getCardStyle(type){
    const types = {
        default: '',
        9: 'border-primary',
    }
    return types[type] || types.default
}

export function getTypeEvents(type){
    const types = {
        default: '',
        1: 'Проезд белого списка',
        2: 'Проезд гостей',
        3: 'Проезд черного списка',
        4: 'Гость, запрещен',
        5: 'Запрещен временному',
        8: 'Проезд по телефонному звонку',
        9: 'Проезд по команде из панели управления',
        91: 'Проезд по команде внешнего устройства управления',
        92: 'Проезд по команде ключа / метки',
    }
    return types[type] || types.default
}

export function checkSettings(settings, find){
    return settings.findIndex(value => value === find) !== -1
}

export function getSortParam(loadParams, exclude = []){
    let ret = {...loadParams};
    Object.entries(ret).forEach(function (v) {
        if(!v[1] || exclude.findIndex((ev)=>ev===v[0]) !== -1) delete ret[v[0]];
        if(v[1] === true) ret[v[0]] = 1;
        if(v[1] instanceof Date) ret[v[0]] = new Date(v[1]).getTime()/1000;
    })
    return Object.entries(ret).length >= 1 ? `?${new URLSearchParams(ret).toString()}` : '';
}

export function appendFilter(filter) {
    return Object.fromEntries(Object.entries(filter).map((v)=> { if(v[1]) return [v[0], v[1]] }).filter((v)=> v))
}

export function getTypeLicencePlates(type){
    const types = {
        default: '',
        1: 'Проезд разрешен на все территории',
        2: 'Проезд запрещен на часть территорий',
        3: 'Проезд запрещен на все территории',
        4: 'Проезд ограничен по времени',
    }
    return types[type] || types.default
}
