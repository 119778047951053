import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    makeStyles, TextField
} from "@material-ui/core";
import 'moment/locale/ru';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LicencePlateInput from "../component/LicencePlate/LicencePlateInput";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LicencePlateAllowedArea from "../LicencePlates/LicencePlateAllowedArea";
import Button from "@material-ui/core/Button";
import {changeLicencePlateStatementStatus, createLicencePlateWithInfo, loadLicencePlateAreaInfo} from "../httpUtils";
import PhoneNumberInput from "../component/PhoneNumber/PhoneNumberInput";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        expanded: {
            margin: theme.spacing(1)
        }
    },
    saveButton: {
        marginTop: theme.spacing(1),
    }
}));

export default function LicencePlateStatement(props) {
    console.log(props);

    const classes = useStyles();

    const [isLicencePlateCreating, setLicencePlateCreating] = useState(false);

    const [createdLicencePlate, setCreatedLicencePlate] = useState('');
    const [createdLicencePlateInfo, setCreatedLicencePlateInfo] = useState('');
    const [createdLicencePlateOwnerName, setCreatedLicencePlateOwnerName] = useState('');
    const [createdLicencePlateOwnerPhone, setCreatedLicencePlateOwnerPhone] = useState('');
    const [createdLicencePlateOwnerEmail, setCreatedLicencePlateOwnerEmail] = useState('');

    const [isExist, setIsExist] = useState(false);
    const [saveAllowedArea, setSaveAllowedArea] = useState(false);
    const [disallowArea, setDisallowArea] = useState([]);

    useEffect(()=>{
        if (saveAllowedArea) {
            setSaveAllowedArea(false)
        }
    }, [saveAllowedArea])

    useEffect(()=>{
        setCreatedLicencePlate(props.initData.licencePlate);
        setCreatedLicencePlateOwnerName(props.initData.owner.ownerName);
        setCreatedLicencePlateOwnerPhone(props.initData.owner.ownerPhone);
        setCreatedLicencePlateOwnerEmail(props.initData.owner.ownerEmail);
        setIsExist(false);
        loadLicencePlateAreaInfo(props.initData.licencePlate).then((r1)=>{
            setIsExist(true);
            saveAvailable(r1.licencePlate)
        }).catch((e1)=>{
            setIsExist(false);
        }).finally(() => {
            console.log(props.initData)
            if (props.initData.status.id === 3 || props.initData.status.id === 4){
                setIsExist(true);
            }
        })
    }, [props.initData])

    function saveAvailable(licencePlate) {
        props.hasOwnProperty('onSaveAvailable') && props.onSaveAvailable(licencePlate)
    }

    function saveLicencePlate() {
        setLicencePlateCreating(true);
        createLicencePlateWithInfo(createdLicencePlate, createdLicencePlateInfo, createdLicencePlateOwnerName, createdLicencePlateOwnerPhone, createdLicencePlateOwnerEmail, false).then((r)=>{
            saveAvailable(createdLicencePlate);
            setSaveAllowedArea(true)
            setIsExist(true);
            changeLicencePlateStatementStatus(createdLicencePlate, props.statementUUID, 3, disallowArea).then(v=>{

            }).catch(e=>{

            })
        }).catch((e)=>{
            setLicencePlateCreating(false);
        })
    }

    function discardLicencePlate() {
        changeLicencePlateStatementStatus(createdLicencePlate, props.statementUUID, 4, undefined).then(v=>{
            setIsExist(true);
        }).catch(e=>{

        })
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={1} className={classes.gridBox}>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <LicencePlateInput
                            disabled={isExist || isLicencePlateCreating}
                            value={createdLicencePlate}
                            onChange={(e, m)=>{
                                setCreatedLicencePlate(e)
                                console.log(e)
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <TextField
                            disabled={isExist || isLicencePlateCreating}
                            fullWidth
                            label="Комментарий"
                            variant="outlined"
                            value={createdLicencePlateInfo}
                            onChange={(e)=>{setCreatedLicencePlateInfo(e.target.value)}}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <TextField
                            disabled={isExist || isLicencePlateCreating}
                            fullWidth
                            label="Имя владельца"
                            variant="outlined"
                            value={createdLicencePlateOwnerName}
                            onChange={(e)=>{setCreatedLicencePlateOwnerName(e.target.value)}}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <PhoneNumberInput
                            disabled={isExist || isLicencePlateCreating}
                            label="Телефон владельца"
                            value={createdLicencePlateOwnerPhone}
                            onChange={(e, m)=>{
                                setCreatedLicencePlateOwnerPhone(e)
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <TextField
                            disabled={isExist || isLicencePlateCreating}
                            fullWidth
                            label="Электронная почта владельца"
                            variant="outlined"
                            type={"email"}
                            value={createdLicencePlateOwnerEmail}
                            onChange={(e)=>{setCreatedLicencePlateOwnerEmail(e.target.value)}}
                        />
                    </Grid>
                </Grid>
                <Accordion expanded className={classes.areaAccessAccordion} disabled={isLicencePlateCreating}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>Зоны доступа</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <LicencePlateAllowedArea number={createdLicencePlate} allow={props.initData.hasOwnProperty('areaAccess') ? props.initData.areaAccess.map(v => v.crmArea): []} disallowReason={'Нет заявления на доступ на территорию'} saveWithLocking disabled={isExist} setDisallowAreas={(a)=>{setDisallowArea(a)}} onSave={saveAllowedArea}/>
                    </AccordionDetails>
                </Accordion>
                <Grid container spacing={1} className={classes.gridBox}>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <Button size="medium" variant='outlined' color="primary" fullWidth disabled={isExist || isLicencePlateCreating || createdLicencePlate.length === 0} onClick={saveLicencePlate} className={classes.saveButton}>
                            Сохранить номерной знак
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                        <Button size="medium" variant='outlined' color="secondary" fullWidth disabled={isExist || isLicencePlateCreating} onClick={discardLicencePlate} className={classes.saveButton}>
                            Отклонить
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </React.Fragment>
    );
}
