import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PopupDialog from "../component/PopupDialog";
import {getCustomerPermissionList, getSystemPermissionList} from "../httpUtils";
import {Accordion, AccordionDetails, Checkbox, FormControlLabel} from "@material-ui/core";
import EditCustomerDialogPermission from "./EditCustomerDialogPermission";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import EditCustomerDialogParking from "./EditCustomerDialogParking";

export default function EditCustomerDialog(props) {
    const [ isOpen, setOpen ] = useState(false);
    const [ currentId, setCurrentId ] = useState(undefined);

    useEffect(()=>{
        if (props.open && props.id){
            setOpen(true)
            setCurrentId(props.id)
        } else {
            setOpen(false);
            setCurrentId(undefined)
        }
    }, [props.open, props.id])

    const closeDialog = () => {
        props.hasOwnProperty('needClose') && props.needClose();
    }

    const parentUpdate = () => {
        props.hasOwnProperty('needReload') && props.needReload();
    }

    return (
        <PopupDialog maxWidth="xs" open={isOpen} onClose={closeDialog} title={"Настройка пользователя"} actionBoard={
            <React.Fragment>
            </React.Fragment>
        }>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Доступ на парковки</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EditCustomerDialogParking id={currentId}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Права доступа</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EditCustomerDialogPermission id={currentId}/>
                </AccordionDetails>
            </Accordion>
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        expandIcon={<ExpandMoreIcon />}*/}
            {/*    >*/}
            {/*        <Typography>Доступ к CRM</Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <EditCustomerDialogPermission id={currentId}/>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
        </PopupDialog>
    )
}
