import React, {useEffect, useState} from "react";
import PopupDialog from "../component/PopupDialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {generatePassword} from "../generator";
import {createCustomer, setCustomerParkingList} from "../httpUtils";

export default function CreateCustomerDialog(props) {
    const [ login, setLogin ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ isSaving, setSaving ] = useState(false);

    useEffect(()=>{
        setPassword(generatePassword(16))
    }, [])

    const closeDialog = () => {
        props.hasOwnProperty('needClose') && props.needClose();
    }

    const parentUpdate = (id) => {
        props.hasOwnProperty('needReload') && props.needReload(id);
    }

    const _saveUser = (login, password) =>{
        setSaving(true);
        createCustomer(login, password).then((v)=>{
            setSaving(false);
            parentUpdate(v.id);
            closeDialog();
        }).catch((e)=>{
            setSaving(false);
        })
    }

    return (
        <PopupDialog maxWidth="xs" open={true} onClose={closeDialog} title={"Создание пользователя"} actionBoard={
            <React.Fragment>
                <Button onClick={closeDialog} color="default">
                    Отмена
                </Button>
                <Button onClick={_saveUser.bind(null, login, password)} color="primary" disabled={isSaving || !password || !login}>
                    Сохранить
                </Button>
            </React.Fragment>
        }>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <TextField
                        disabled={isSaving}
                        fullWidth
                        label="Логин"
                        variant="outlined"
                        type='text'
                        value={login}
                        onChange={(e)=>{setLogin(e.target.value)}}
                    />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <TextField
                        disabled={true}
                        fullWidth
                        label="Пароль"
                        variant="outlined"
                        type='text'
                        value={password}
                        onChange={(e)=>{setPassword(e.target.value)}}
                    />
                </Grid>

            </Grid>
        </PopupDialog>
    )
}
