import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Menu from '../../Header/Menu';

import StateItem from './StateItem';
import EndpointEdit from "./EndpointEdit";

export default class EndpointList extends Component {
 constructor() {
   super();
   this.state = {
       endpointList: [],
       showEditDialog: false,
       editDialogMac: '',
       editDialogId: 0,
   }
   this.handleLoad = this.handleLoad.bind(this);
   this.handleGetState = this.handleGetState.bind(this);

 }

 handleLoad(){
   fetch(process.env.REACT_APP_API_URL+'/endpoint',{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => {
     this.setState({endpointList: res});
     this.handleGetState();
     setInterval(() => {
      this.handleGetState();
    }, 5000);

    });
 }

 handleGetState(){
  fetch(process.env.REACT_APP_API_URL+'/endpoint/status', {
    method: 'get',
    credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
     .then(res => res.json())
     .then((data) => {
       var st = this.state.endpointList;

       for (var i = 0; i < data.length; i++) {
         var re = st.find(function(e){
           return e.mac == data[i].mac.toUpperCase();
         })
         if (re != undefined){
           re.status = data[i].status;
           re.last = data[i].last_ip;

           console.log("set");
         }
       }
       console.log(st);
       
       this.setState({endpointList: st});

     })
     .catch(console.log)
 }

 componentDidMount() {
   this.handleLoad()
 }

 render() {
   return (
    <>
      <Container>
        <h3 className="mb-4">Устройства обработки видео</h3>
        
        <div>
          <ListGroup >
          {
          this.state.endpointList.map((data, i) => (
            <ListGroup.Item 
            key={i}
            className="align-middle"
            action onClick={ (e) => {
              this.setState({
                editDialogMac: data.mac,
                editDialogId: data.id,
                showEditDialog: true
              })
              e.target.blur();
            }}>
              <div style={{display: "flex"}}>
              {/* <span className="align-middle"><b> */}
                {data.mac}
                {" ( "+data.name+" ) "}
              {/* </b></span> */}
              
              <StateItem data={data} key={i+"s"}/>
              
              </div>
            </ListGroup.Item>
          ))
          }
          </ListGroup>

          <Modal show={this.state.showEditDialog} onHide={ () => this.setState({showEditDialog : false}) } size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{ this.state.editDialogMac }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EndpointEdit endpointId={this.state.editDialogId}/>
            </Modal.Body>
            <Modal.Footer>
              
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
   );
 }
}
