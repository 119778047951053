import React, { useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Avatar,
    Card, CardActionArea, CardContent, CardHeader,
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Typography from "@material-ui/core/Typography";
import {loadRentActualTariffs, loadRentContracts} from "../httpUtils";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import json2csv from "../json2csv";
import RentContractInfoDialog from "./RentContractInfoDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    buttonContainer: {
        marginBottom: theme.spacing(2),
    },
    card: {
        width: '100%'
    }
}));

export default function RentContractList(props) {
    const classes = useStyles();

    const [actualTariffList, setActualTariffList] = useState([]);

    const [selectedContractId, setSelectedContractId] = useState(0);

    const loadContracts = () => {
        loadRentContracts().then((r)=>{
            setActualTariffList(r)
        })
    }

    useEffect(()=>{
        loadContracts()
    }, [])

    function getAvatarClassname(guest){
        return guest ? classes.licencePlateAllowToAllAvatar : classes.licencePlatePartialDisallowAvatar
    }

    function getAvatarIcon(capacity){
        return capacity ? <LowPriorityIcon/> : <AllInclusiveIcon/>
    }


    return (
        <React.Fragment>
            <Menu title='Договоры'/>
            <CssBaseline />
            <div className={classes.root}>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" startIcon={<SaveIcon />} disabled>
                        Загрузить в Excel
                    </Button>
                </div>
                <Grid container spacing={1}>
                    { actualTariffList.length > 0 && actualTariffList.map((c, i) => (
                        <Grid container item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Card className={classes.card}>
                                <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                    setSelectedContractId(c.contractId);
                                }}>
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Договор № {c.contractId}
                                        </Typography>
                                        <Typography variant="body1">
                                            Тип: <b>{c.tariffObjectName}</b>
                                        </Typography>
                                        <Typography variant="body1">
                                            Номерной знак: <b>{c.contractLicencePlate}</b>
                                        </Typography>
                                        <Typography variant="subtitle2" >
                                            От: {new Date(c.contractBegin).toLocaleString()}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            По: {new Date(c.contractEnd).toLocaleString()}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>

                            </Card>

                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            <RentContractInfoDialog contractId={selectedContractId} open={selectedContractId > 0} needClose={()=>{setSelectedContractId(0)}} renewParent={()=>{loadContracts();}} />
        </React.Fragment>
    )
}
