import React, { useEffect, useState} from 'react';
import Menu from "../Header/Menu";
import {
    Avatar,
    Card, CardActionArea, CardHeader,
    CssBaseline,
    makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import {loadAreasList, loadCustomerList} from "../httpUtils";
import Typography from "@material-ui/core/Typography";
import EditCustomerDialog from "./EditCustomerDialog";
import Button from "@material-ui/core/Button";
import CreateCustomerDialog from "./CreateCustomerDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    card:{
        width: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: 45,
    },
    alert: {
        marginTop: theme.spacing(2),
    },
    licencePlateAllowToAllAvatar: {
        backgroundColor: '#4CAF50',
        width: 45,
    },
    licencePlatePartialDisallowAvatar: {
        backgroundColor: '#FFC107',
        width: 45,
    },
    licencePlateFullDisallowAvatar: {
        backgroundColor: '#F44336',
        width: 45,
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        '&$expanded': {
            margin: 0,
            transform: "rotate(0deg)",
        },
    }
}));

export default function CustomersList(props) {
    const classes = useStyles();

    const [customerList, setCustomerList] = useState([]);
    const [currentCustomer, setCurrentCustomer] = useState(0);

    const [isCreating, setCreating] = useState(false);

    const loadCustomers = () => {
        loadCustomerList().then((r)=>{
            setCustomerList(r)
        })
    }

    useEffect(()=>{
        loadCustomers()
    }, [])

    function getAvatarClassname(guest){
        return guest ? classes.licencePlateAllowToAllAvatar : classes.licencePlatePartialDisallowAvatar
    }

    function getAvatarIcon(capacity){
        return capacity ? <LowPriorityIcon/> : <AllInclusiveIcon/>
    }


    return (
        <React.Fragment>
            <Menu title='Пользовалели'/>
            <CssBaseline />
            <div className={classes.root}>
                <Button variant="contained" color="primary" className={classes.accordion} onClick={()=>{
                    setCreating(true);
                }}>
                    Добавить пользователя
                </Button>
                <Grid container spacing={1}>
                    { customerList.length > 0 && customerList.map((data, i) => (
                        <Grid container item xs={12} sm={6} md={4} lg={4} xl={2} key={`cli-${i}`}>
                            <Card className={classes.card}>
                                <CardActionArea style={{ textDecoration: 'none' }} onClick={()=>{
                                    setCurrentCustomer(data.id);
                                }}>
                                    <CardHeader
                                        subheader={
                                            <React.Fragment>
                                                <Typography variant="h5" component="h2">{data.login}</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </CardActionArea>
                            </Card>

                        </Grid>
                    ))
                    }
                </Grid>
            </div>
            { currentCustomer > 0 && <EditCustomerDialog id={currentCustomer} open={currentCustomer > 0} needClose={()=>{setCurrentCustomer(0)}} needReload={()=>{loadCustomers()}}/> }
            {
                isCreating && <CreateCustomerDialog needClose={()=>{setCreating(false)}} needReload={(id)=>{loadCustomers(); setCurrentCustomer(id)}}/>
            }
        </React.Fragment>
    )
}
