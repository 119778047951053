export default function json2csv(data, fileName, header){
    const filename = fileName || `export.csv`
    const keyHeader = Object.keys(header)
    let csv = [];
    csv.push(keyHeader.map((v)=> header[v]).join(','));
    for (const dataRow of data) {
        csv.push(keyHeader.map((v)=> dataRow[v]).join(','));
    }
    csv = csv.join('\r\n')

    const blob = new Blob([csv], {
        type: 'text/plain;charset=utf-8',
    })

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename)
        return
    }
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = filename
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
