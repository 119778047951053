import React from 'react';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '', info: '' };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true, error: error.message, info: info.componentStack });
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        // alert(error+' | '+info)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return(
                <>

                    <h1>Error</h1>
                    <h6>{this.state.error}</h6>
                    <h6>{this.state.info}</h6>

                </>);

        }
        return this.props.children;
    }
}