import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import StateItem from './StateItem';

export default class EndpointEdit extends Component {
 constructor() {
   super();
   this.state = {
       cameraList: [],
       allCameraList: [],
       cameraSelectedId: 0,
   }
   this.handleLoad = this.handleLoad.bind(this);
   this.handleLoadAllCameraList = this.handleLoadAllCameraList.bind(this);
   this.handleSubmitCamera = this.handleSubmitCamera.bind(this);

 }

 handleLoad(){
   fetch(process.env.REACT_APP_API_URL+'/endpoint/extended/'+this.props.endpointId,{
     method: 'GET',
     credentials: 'include',
     headers: {
       'Content-Type': 'application/json'
     }
   })
   .then(res => res.json())
   .then(res => {
     this.setState({cameraList: res.camera});
     
    });
 }

 handleLoadAllCameraList(){
  fetch(process.env.REACT_APP_API_URL+'/parking/camera',{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .then(res => {
    this.state.cameraList.forEach(function(row){
      let ind = res.camera.indexOf(res.camera.find(function(r){
        return row.cameraID == r.camera_id
      }));
      if (ind != -1) {
        res.camera.splice(ind, 1)
      }
      
    })
    
    this.setState({allCameraList: res.camera, cameraSelectedId: res.camera[0] != undefined ? res.camera[0].camera_id: -1})
  });
}

handleSubmitCamera(action, cameraId) {
  console.log(this.state.editArea);
  let reqMethod;
  let reqBody;
  let reqUrl;
  switch(action) {
   case 'delete':
     reqMethod='DELETE';
     reqUrl = process.env.REACT_APP_API_URL+'/endpoint/'+this.props.endpointId+'/'+cameraId;
     reqBody=JSON.stringify({});
     break;
   case 'add':
     reqMethod='POST';
     reqUrl = process.env.REACT_APP_API_URL+'/endpoint/'+this.props.endpointId;
     reqBody=JSON.stringify({ "camera" :cameraId });
     break;
  }
  fetch(reqUrl, {
    method: reqMethod,
    credentials: 'include',
    body: reqBody,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    console.log(res.status);
    switch (res.status) {
      //  case 401:

      //    break;
      //  case 403:
      //    this.setState({editError: "Запрещено!"},()=>{
      //      window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
      //    });
      //    break;
      //  case 409:
      //    this.setState({editError: "Такое уже есть!"},()=>{
      //      window.setTimeout(()=>{this.setState({editError: ""})}, 2000)
      //    });
      //    break;
      default:
      // this.setState({showEditDialog: false});
      this.handleLoad()
      this.handleLoadAllCameraList()
    }
  })
  .catch(err => {
    console.log(err);
  });
}

 componentDidMount() {
   this.handleLoad()
   this.handleLoadAllCameraList()
 }


 render() {
   return (

        <div>
          <ListGroup >
          {
          this.state.cameraList.map((data, i) => (
            <ListGroup.Item 
            key={i}>
              <>
                <span className="align-middle">{data.barrierName +" / "+ (data.areaName ? data.areaName : "Улица") }</span>
                <Button variant="outline-danger" size="sm" className="float-right mr-2" onClick={
                  this.handleSubmitCamera.bind(this, 'delete', data.cameraID)
                  
                }>
                  Удалить
                </Button>
              </>
            </ListGroup.Item>
          ))
          }
          </ListGroup>
          <Form className="mt-1">
          <Form.Row>
            <Col xs={10}>
              
                <Form.Control as="select" onChange={(e) => { this.setState({cameraSelectedId: e.target.value}) }}>
                    {
                      this.state.allCameraList.map((data, j) => (
                        <option key={j} name={data.name} value={data.camera_id}>{data.name +" / "+ (data.areaT ? data.areaT : "Улица")}</option>
                      ))
                    }
                </Form.Control>
              
            </Col>
            <Col>
              <Button variant="outline-success" block onClick={
                this.handleSubmitCamera.bind(this, 'add', this.state.cameraSelectedId)
                
              }>Добавить</Button>
            </Col>
          </Form.Row>
            
            
          </Form>
        </div>
      
   );
 }
}
