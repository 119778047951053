import React, {Component, useCallback, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Avatar, Box, Checkbox, createTheme, CssBaseline, FormControlLabel, Link, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from "@material-ui/core/Paper";
import {Alert} from "@material-ui/lab";
import {sendLogin} from "./httpUtils";

export default function Login(props){
    const theme = createTheme();
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        sendLogin(data.get('login'), data.get('password'))
            .then(value => {
                let ref = '/'
                if (props.location.state){
                    ref = props.location.state.referrer
                }
                props.history.push(ref);
            }).catch(reason => {
                setError(reason.message);
                setTimeout(()=>{
                    setError('')
                },10000)
            })
    };

    return(
        <Grid container component="main" style={{ height: '100vh' }}>
            <CssBaseline />

            <Grid item xs={12} sm={8} md={5} lg={4} xl={3} component={Paper} elevation={6} square>
                <Box
                    style={{
                        marginTop: theme.spacing(8),
                        marginBottom: theme.spacing(8),
                        marginLeft: theme.spacing(4),
                        marginRight: theme.spacing(4),
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar style={{ margin: 1, backgroundColor: theme.palette.secondary.main }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Авторизация
                    </Typography>
                    <Box component="form" validate onSubmit={handleSubmit} style={{ marginTop: 1 }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="formLogin"
                            label="Логин"
                            name="login"
                            autoComplete="login"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Пароль"
                            type="password"
                            id="formPassword"
                            autoComplete="current-password"
                        />
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary" />}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        {error && <Alert severity="error" fullWidth>{error}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }}
                        >
                            Войти
                        </Button>

                        {/*<Grid container>*/}
                        {/*    <Grid item xs>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            Forgot password?*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            {"Don't have an account? Sign Up"}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        {/*<Copyright style={{ marginTop: theme.spacing(5) }} />*/}
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                lg={8}
                xl={9}
                style={{
                    backgroundImage: 'url(/bg.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.type === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        </Grid>
    )
}