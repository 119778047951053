import React, {Component, useContext, useState} from 'react';
import { Redirect } from 'react-router-dom';
import Menu from "./Header/Menu";
import {ReactReduxContext, useDispatch} from "react-redux";
import {changeTheme, changeUserSettings} from "./store/actionCreators";

export default function WithAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        functions: [],
      };
      this.checkFunctions = this.checkFunctions.bind(this);
    }

    componentDidMount() {
      console.log(this.props.dispatch);

      fetch(process.env.REACT_APP_API_URL+'/admin/check',{
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Cache': 'no-cache'
        },
        credentials: 'include'
      })
        .then(async res => {
          if (res.status === 200) {
            const func = await res.json();
            changeUserSettings(func.functions)
            this.setState({loading: false, functions: func.functions});
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
    }

    checkFunctions(find){
      return this.state.functions.findIndex(value => value===find) !== -1 ? true : false
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to={{pathname: "/login", state: { referrer: window.location.pathname }}} />;
      }
      console.log('with auth')
      return (
          <>
            <ComponentToProtect {...this.props} checkFunctions={this.checkFunctions}/>
          </>
      );
    }
  }
}
