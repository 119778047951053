import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import {Skeleton} from "@material-ui/lab";
import Menu from "../Header/Menu";
import {CssBaseline, makeStyles, TableCell, withStyles} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ScrollToTop from "../component/ScrollToTop";

const useStyles = ((theme) => ({
    blocked: {
        backgroundColor: theme.palette.error.light
    },
    danger: {
        backgroundColor: theme.palette.warning.light
    },
}));


class Guests extends Component {
 constructor() {
   super();
   this.state = {
     list: [],
       loading: true
   }

   this.handleLoad = this.handleLoad.bind(this);
 }

 handleLoad(){
     fetch(process.env.REACT_APP_API_URL+'/parking/territory/numbers',{
         method: 'GET',
         credentials: 'include',
         headers: {
             'Content-Type': 'application/json'
         }
     })
         .then(res => res.json())
         .then(res => {
             // пол года ограничение
             res.numbers = res.numbers.filter(value => (value.last + 14515200 > (new Date().getTime() / 1000)))
             fetch(process.env.REACT_APP_API_URL+'/parking/numbers',{
                 method: 'GET',
                 credentials: 'include',
                 headers: {
                     'Content-Type': 'application/json'
                 }
             })
                 .then(res1 => res1.json())
                 .then(res1 => {
                     // console.log(res)
                     res1.numbers.forEach(value => {
                         let i = res.numbers.findIndex(value1 => (value1.number == value.number))
                         if (i != -1){
                             res.numbers[i]['blacklist'] = value.blacklist;
                             if (value.blacklist == 0){
                                 res.numbers.splice(i, 1)
                             }
                         }


                     })
                     // console.log(res)
                     res.numbers = res.numbers.sort((a, b) => a.area_name.localeCompare(b.area_name))
                     this.setState({list: res.numbers, loading: false})
                 });
         });
 }

 componentDidMount() {
   this.handleLoad()
 }

 drawNumbers(numbers){
    return numbers.join(', ')
 }

drawHistory(lastAreaName, lastActivity){
    if (lastAreaName) {
        return `${lastAreaName} C ${new Date(lastActivity*1000).toLocaleString()}`
    } else {
        return 'Улица'
    }
}

    calculateLong(lastActivity){
        if (lastActivity){
            return lastActivity + 604800 < (new Date().getTime() / 1000)
        }
        return false
    }

 render() {
    const { classes } = this.props;
    return (
       <>
           <Menu title='Номера на парковке' />
           <CssBaseline />
           {this.state.loading ?
               <Skeleton />
               :
               <React.Fragment>
                   <TableContainer component={Paper}>
                       <Table aria-label="a dense table">
                           <TableHead>
                               <TableRow>
                                   <TableCell>Номерной знак</TableCell>
                                   <TableCell>Локация</TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {this.state.list.map((row, i) => (
                                   <TableRow key={row.apartmentNumber} className={row.blacklist === 1 ? classes.blocked : this.calculateLong(row.last) && classes.danger}>
                                       <TableCell component="th" scope="row">
                                           { row.number }
                                       </TableCell>
                                       <TableCell>{ this.drawHistory(row.area_name, row.last) }</TableCell>
                                   </TableRow>
                               ))}
                           </TableBody>
                       </Table>
                   </TableContainer>
                   <ScrollToTop/>
               </React.Fragment>
           }
       </>
    );
 }
}

export default withStyles(useStyles)(Guests);
